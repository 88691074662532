import { Typography } from '@mui/material';
import styled from '@emotion/styled';

export const TypographyBase = styled(Typography)(() => ({
    // fontSize: props.fontSize,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

export const TypographyNomal = styled(TypographyBase)(() => ({
    fontSize: '0.9rem'
}));
export const TypographySmall = styled(TypographyBase)(() => ({
    fontSize: '0.8rem'
}));

export const TypographyBold = styled(TypographyBase)(() => ({
    margin: '0',
    fontWeight: 'bold',
}));

export const TypographyTitle = styled(TypographyBold)(() => ({
    fontSize: '1.4rem',
}));
export const TypographyTitleCenter = styled(TypographyTitle)(() => ({
    padding: '10px',
    textAlign: 'center'
}));
export const TypographyTitleLeft = styled(TypographyTitle)(() => ({
    padding: '10px 0',
    textAlign: 'left'
}));

export const TypographyGray = styled(TypographyBase)(() => ({
    color: '#6C6C6C',
}));
export const TypographyGrayNomal = styled(TypographyGray)(() => ({
    fontSize: '0.9rem'
}));
export const TypographyGrayNomalEllipsis = styled(TypographyGrayNomal)(() => ({
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
}));
export const TypographyGraySmall = styled(TypographyGray)(() => ({
    fontSize: '0.8rem'
}));
export const TypographyGraySmallLeft = styled(TypographyGraySmall)(() => ({
    textAlign: 'left'
}));

export const TypographyBreakWord = styled(Typography)(() => ({
    wordBreak: 'break-word',
}));

export const TypographyBreakWordSmall = styled(TypographyBreakWord)(() => ({
    fontSize: '0.8rem'
}));

export const TypographyBreakWordGraySmall = styled(TypographyBreakWordSmall)(() => ({
    color: '#6C6C6C',
}));
export const TypographyBreakWordGraySmallR = styled(TypographyBreakWordGraySmall)(() => ({
    paddingRight: '10px',
}));



