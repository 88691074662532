export default function errorHandler(
    errorStatus, movie, item, errorContents,setErrorContents
) {
    if (movie.id !== null && movie.id !== ''
    && !errorContents.some(content => content.movie.id === movie.id)) {

        setErrorContents((prevState) => [
        ...prevState,
        {
            movie: movie,
            item: item,
            status: errorStatus
        },
    ]);
}
return
}