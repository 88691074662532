import {
  BG_COLOR,
  BG_UNSELECT_COLOR,
  BG_HOVER_COLOR,
  BORDER_STYLE,
  FONT_COLOR,
  FONT_HOVER_COLOR,
  HAZARD_NAME,
  HAZARD_ICON_SVG,
  LEVEL_NAME,
  LEVEL_ICON_SVG,
  LEVEL_ICON_TAG,
  LEVEL_ICON_TAG_HOVER,
} from './risk_style';

export const LEVEL_ICONS = [
  {
    TYPE: 0,
    TEXT: LEVEL_NAME.EMERGENCY,
    ICON: LEVEL_ICON_SVG.EMERGENCY,
    ICON_TAG: LEVEL_ICON_TAG.EMERGENCY,
    ICON_TAG_HOVER: LEVEL_ICON_TAG_HOVER.EMERGENCY,
    BG_COLOR: BG_COLOR.HIGHT,
    BG_UNSELECT_COLOR: BG_UNSELECT_COLOR.HIGHT,
    BG_HOVER_COLOR: BG_HOVER_COLOR.HIGHT,
    FONT_COLOR: FONT_COLOR.HIGHT,
    FONT_HOVER_COLOR: FONT_HOVER_COLOR.HIGHT,
    BORDER_STYLE: BORDER_STYLE.HIGHT,
  },
  {
    TYPE: 1,
    TEXT: LEVEL_NAME.IMPORTANT,
    ICON: LEVEL_ICON_SVG.IMPORTANT,
    ICON_TAG: LEVEL_ICON_TAG.IMPORTANT,
    ICON_TAG_HOVER: LEVEL_ICON_TAG_HOVER.IMPORTANT,
    BG_COLOR: BG_COLOR.LOW,
    BG_UNSELECT_COLOR: BG_UNSELECT_COLOR.LOW,
    BG_HOVER_COLOR: BG_HOVER_COLOR.LOW,
    FONT_COLOR: FONT_COLOR.LOW,
    FONT_HOVER_COLOR: FONT_HOVER_COLOR.LOW,
    BORDER_STYLE: BORDER_STYLE.LOW,
  },
  {
    TYPE: 2,
    TEXT: LEVEL_NAME.NORMAL,
    ICON: LEVEL_ICON_SVG.NORMAL,
    ICON_TAG: LEVEL_ICON_TAG.NORMAL,
    ICON_TAG_HOVER: LEVEL_ICON_TAG_HOVER.NORMAL,
    BG_COLOR: BG_COLOR.NONE,
    BG_UNSELECT_COLOR: BG_UNSELECT_COLOR.NONE,
    BG_HOVER_COLOR: BG_HOVER_COLOR.NONE,
    FONT_COLOR: FONT_COLOR.NONE,
    FONT_HOVER_COLOR: FONT_HOVER_COLOR.NONE,
    BORDER_STYLE: BORDER_STYLE.NONE,
  },
  {
    TYPE: 3,
    TEXT: LEVEL_NAME.THREE_D,
    ICON: LEVEL_ICON_SVG.THREE_D,
    ICON_TAG: LEVEL_ICON_TAG.THREE_D,
    ICON_TAG_HOVER: LEVEL_ICON_TAG_HOVER.THREE_D,
    BG_COLOR: BG_COLOR.THREE_D,
    BG_UNSELECT_COLOR: BG_UNSELECT_COLOR.THREE_D,
    BG_HOVER_COLOR: BG_HOVER_COLOR.THREE_D,
    FONT_COLOR: FONT_COLOR.THREE_D,
    FONT_HOVER_COLOR: FONT_HOVER_COLOR.THREE_D,
    BORDER_STYLE: BORDER_STYLE.THREE_D,
  },

  // 土砂崩れリスク低
  {
    TYPE: 4,
    TEXT: HAZARD_NAME.LAND_SLIDE,
    ICON: HAZARD_ICON_SVG.LAND_SLIDE,
    BG_COLOR: BG_COLOR.LOW,
    ICON_COLOR: FONT_COLOR.LOW,
    FONT_COLOR: FONT_COLOR.LOW,
    BORDER_STYLE: BORDER_STYLE.LOW,
  },
  // 土砂崩れリスク中
  {
    TYPE: 5,
    TEXT: HAZARD_NAME.LAND_SLIDE,
    ICON: HAZARD_ICON_SVG.LAND_SLIDE,
    BG_COLOR: BG_COLOR.MIDDLE,
    ICON_COLOR: FONT_COLOR.MIDDLE,
    FONT_COLOR: FONT_COLOR.MIDDLE,
    BORDER_STYLE: BORDER_STYLE.MIDDLE,
  },
  // 土砂崩れリスク高
  {
    TYPE: 6,
    TEXT: HAZARD_NAME.LAND_SLIDE,
    ICON: HAZARD_ICON_SVG.LAND_SLIDE,
    BG_COLOR: BG_COLOR.HIGHT,
    ICON_COLOR: FONT_COLOR.HIGHT,
    FONT_COLOR: FONT_COLOR.HIGHT,
    BORDER_STYLE: BORDER_STYLE.HIGHT,
  },
  // 河川氾濫リスク低
  {
    TYPE: 7,
    TEXT: HAZARD_NAME.FLOOD,
    ICON: HAZARD_ICON_SVG.FLOOD,
    BG_COLOR: BG_COLOR.LOW,
    ICON_COLOR: FONT_COLOR.LOW,
    FONT_COLOR: FONT_COLOR.LOW,
    BORDER_STYLE: BORDER_STYLE.LOW,
  },
  // 河川氾濫リスク中
  {
    TYPE: 8,
    TEXT: HAZARD_NAME.FLOOD,
    ICON: HAZARD_ICON_SVG.FLOOD,
    BG_COLOR: BG_COLOR.MIDDLE,
    ICON_COLOR: FONT_COLOR.MIDDLE,
    FONT_COLOR: FONT_COLOR.MIDDLE,
    BORDER_STYLE: BORDER_STYLE.MIDDLE,
  },
  // 河川氾濫リスク高
  {
    TYPE: 9,
    TEXT: HAZARD_NAME.FLOOD,
    ICON: HAZARD_ICON_SVG.FLOOD,
    BG_COLOR: BG_COLOR.HIGHT,
    ICON_COLOR: FONT_COLOR.HIGHT,
    FONT_COLOR: FONT_COLOR.HIGHT,
    BORDER_STYLE: BORDER_STYLE.HIGHT,
  },
  // 家の浸水リスク低
  {
    TYPE: 10,
    TEXT: HAZARD_NAME.HOUSE_FLOOD,
    ICON: HAZARD_ICON_SVG.HOUSE_FLOOD,
    BG_COLOR: BG_COLOR.LOW,
    ICON_COLOR: FONT_COLOR.LOW,
    FONT_COLOR: FONT_COLOR.LOW,
    BORDER_STYLE: BORDER_STYLE.LOW,
  },
  // 家の浸水リスク中
  {
    TYPE: 11,
    TEXT: HAZARD_NAME.HOUSE_FLOOD,
    ICON: HAZARD_ICON_SVG.HOUSE_FLOOD,
    BG_COLOR: BG_COLOR.MIDDLE,
    ICON_COLOR: FONT_COLOR.MIDDLE,
    FONT_COLOR: FONT_COLOR.MIDDLE,
    BORDER_STYLE: BORDER_STYLE.MIDDLE,
  },
  // 家の浸水リスク高
  {
    TYPE: 12,
    TEXT: HAZARD_NAME.HOUSE_FLOOD,
    ICON: HAZARD_ICON_SVG.HOUSE_FLOOD,
    BG_COLOR: BG_COLOR.HIGHT,
    ICON_COLOR: FONT_COLOR.HIGHT,
    FONT_COLOR: FONT_COLOR.HIGHT,
    BORDER_STYLE: BORDER_STYLE.HIGHT,
  },
]
