import styles from 'styled-components'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import media from "styled-media-query";


export const DispFlex = styles.div`
display: flex;
${media.lessThan("small")`
display: block;
`} 
`
export const UploadAsideWrapper = styles.div`
padding:0px 20px;
  width:70%;
  ${media.lessThan("small")`
  padding:10px 10px;
  width:90%;
`} 
  `

export const ContainerSpaceBetween = styles.div`
display: flex;
justify-content: space-between;
`
export const LabelWrapper = styles.label`
display: block;
`

export const MainWrapper = styles.div`
  flex: 1;
  overflow: auto;
  ${media.greaterThan("medium")`
  border-right: 0.5px solid #666;
  height: calc(100vh - 89px);
  `}
  ${media.between("small", "medium")`
  height: calc(100vh - 119px);
  `}
  ${media.lessThan("small")`
  height: calc(100vh - 119px);
  `} 
  `

export const PcDispFlex = styles.div`
${media.greaterThan("large")`
display: flex;
`}
${media.lessThan("large")`
display: none;
`} 
`
export const MobileDispFlex = styles.div`
${media.greaterThan("large")`
display: none;
`}
${media.lessThan("large")`
display: flex;
`} 
`
export const DispBlockPc = styles.div`
${media.greaterThan("large")`
display: block;
`}
${media.lessThan("large")`
display: none;
`} 
`



export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const OverviewPadding = {
  width: '100%',
  marginTop: '10px',
  borderColor: 'rgba(0, 0, 0, 0.2)',
  padding: { xs: '0px', sm: '10px', md: '10px' },
}
