import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function ArrowRightIcon(props) {
  const handleNext = () => {
    props.setCurrentIndex((prevCurrentIndex) => prevCurrentIndex + 1);
  };

  return (
    <IconButton
      color="default"
      sx={{
        padding: 0,
        "&.MuiButtonBase-root:hover": {
          bgcolor: "transparent"
        }
      }}
      onClick={handleNext}
      disabled={props.disabled}>
      <KeyboardArrowRight />
    </IconButton>
  );
}
