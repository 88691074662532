import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function IconThreeD(props) {

  return (
    <SvgIcon
      sx={{ width: props.width, height: props.height }}
      viewBox='-1 -1 20 20'>
      <path d="M8.24414 0.132848C8.73281 -0.0442826 9.26719 -0.0442826 9.75938 0.132848L16.5094 2.56308C17.4023 2.88546 18 3.73922 18 4.69927V13.3007C18 14.2572 17.4023 15.1145 16.5059 15.4369L9.75586 17.8672C9.26719 18.0443 8.73281 18.0443 8.24062 17.8672L1.49063 15.4369C0.597656 15.1145 0 14.2608 0 13.3007V4.69927C0 3.74277 0.597656 2.88546 1.49414 2.56308L8.24414 0.132848ZM9 2.26904L2.89336 4.46546L9 6.66188L15.1066 4.46546L9 2.26904V2.26904ZM10.125 15.3271L15.75 13.3043V6.64416L10.125 8.66699V15.3271V15.3271Z" fill="white" />
    </SvgIcon>
  );
}
