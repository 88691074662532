export default async function getElevation(
    latitude,
    longitude
) {
    //国土地理院APIに現在地の緯度経度を渡して、標高を取得する
    const result = await fetch(`https://cyberjapandata2.gsi.go.jp/general/dem/scripts/getelevation.php?lon=
    ${longitude}&lat=${latitude}&outtype=JSON`
    )
        .then(response => response.text())
        .then(text => {
            var jsonAltitude = JSON.parse(text);
            console.log("標高：" + jsonAltitude.elevation + "m");
            return jsonAltitude.elevation;
        })
        .catch((error) => {
            console.log(error);
            return 0
        })
    return result
}
