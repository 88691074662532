import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

export default function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, movies } =
    props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" colSpan={6}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick(movies)}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />全選択
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  movies: PropTypes.array.isRequired,
};
