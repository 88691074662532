import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { TextField, Stack } from "@mui/material";
import Modal from "react-modal";

import InputField from '../../atoms/input_field';
import SwitchButton from '../../items/switch_button'
import MessageBar from '../../items/snackbar'
import FormDatePickerStartEnd from '../../items/date_picker_start_end'
import { BoxFlexStartTop } from '../../style/box';
import { TAG_NAME } from '../../const/roules';

import PostData from '../../functions/post_data';
import PatchData from '../../functions/patch_data';
import Common from '../../functions/common';
import SendForHana from '../../functions/send_for_hana';

Modal.setAppElement("#root");

function TagInfoForm(props) {

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  const { control, register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onBlur' });

  // タグ登録・更新成功時の処理
  const onSuccess = async (response, proc, setSuccessMessage) => {
    props.reload()
    setSuccessMessage(`タグ：${response.data.tag_names}を${proc}しました`)
    setStartDateError('')
    reset()
    setValue('tag_starting_date', null)
    setValue('tag_ending_date', null)
    // HANAにデータ連携
    SendForHana()
  }

  // タグ登録・更新失敗時の処理
  const onError = (err, proc, setErrorMessage) => {
    if (err.response === undefined) {
      setErrorMessage(`タグの${proc}に失敗しました。`)
    } else if (err.response.data.tag_names) {
      setErrorMessage('このタグ名は既に登録されています。')
    } else if (err.response.data.tag_starting_date) {
      setErrorMessage('開始日を正しく設定してください。')
    } else if (err.response.data.tag_ending_date) {
      setErrorMessage('終了日を正しく設定してください。')
    } else {
      setErrorMessage(`タグの${proc}に失敗しました。`)
    }
  }

  const onSubmit = data => {
    // 日付エラー判定
    if (startDateError !== '' || endDateError !== '') {
      return
    }
    let startDate = new Date(data.tag_starting_date)
    let endDate = new Date(data.tag_ending_date)
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)
    data.tag_starting_date = startDate
    data.tag_ending_date = endDate

    // タグ名の全角英数字を半角に変換
    data.tag_names = Common.strConvertHalfWidth(data.tag_names)

    if (props.proc_type === 'create') {
      console.log(data)
      PostData
        (
          data,
          'タグ',
          'tags/',
          props.setSnackBarMessage,
          props.setSnackBarStatus,
          props.setOpenSnackBar,
          setSnackBarMessage,
          setSnackBarStatus,
          setOpenSnackBar,
          onSuccess,
          onError
        )
    }
    if (props.proc_type === 'update') {
      PatchData
        (
          data,
          'タグ',
          `tags/${data.id}/`,
          props.setSnackBarMessage,
          props.setSnackBarStatus,
          props.setOpenSnackBar,
          setSnackBarMessage,
          setSnackBarStatus,
          setOpenSnackBar,
          onSuccess,
          onError
        )
    }
  }

  // キャンセル動作
  const onCancel = () => {
    props.setOpen(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>

          <TextField sx={{ display: 'none' }}
            {...register('id')}
            value={props.id}
          />
          <InputField
            register={register('tag_names', TAG_NAME)}
            defaultValue={props.tagNames}
            label="タグ名"
            error={errors.tag_names}
          />

          <BoxFlexStartTop>
            <FormDatePickerStartEnd
              startingDateName='tag_starting_date'
              endingDateName='tag_ending_date'
              control={control}
              startingDate={props.tagStartingDate}
              endingDate={props.tagEndingDate}
              startDateError={startDateError}
              endDateError={endDateError}
              setStartDateError={setStartDateError}
              setEndDateError={setEndDateError}
              setValue={setValue}
              getValues={getValues}
            />
          </BoxFlexStartTop>
        </Stack>
        <SwitchButton
          proc_type={props.proc_type}
          onCancel={onCancel}
          submitButtonName={props.submitButtonName}
        />
      </form>
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
    </>
  )
}

export default TagInfoForm;
