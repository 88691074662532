import React from 'react'
import {
  FormGroup,
  Checkbox,
} from "@mui/material";
import { BoxFlexStart } from '../../style/box';
import { CheckBoxLabel } from '../../style/check_radio';
import { TypographyBreakWordGraySmall } from '../../style/typography';


function RoleCheck(props) {

  return (
    <BoxFlexStart>
      <CheckBoxLabel
        sx={{ paddingRight: 2 }}
        control={
          <Checkbox
            {...props.register_admin}
            checked={props.isAdmin}
            onChange={() => props.setIsAdmin(!props.isAdmin)}
          />
        }
        label={<TypographyBreakWordGraySmall>管理者</TypographyBreakWordGraySmall>}
      />
      <CheckBoxLabel 
        sx={{ paddingRight: 2 }}
        control={
        <Checkbox
          {...props.register_postman}
          checked={props.isPostman}
          onChange={() => props.setIsPostman(!props.isPostman)}
        />
      }
        label={<TypographyBreakWordGraySmall>投稿者</TypographyBreakWordGraySmall>}
      />
      <CheckBoxLabel control={
        <Checkbox
          {...props.register_staff}
          checked={props.isStaff}
          onChange={() => props.setIsStaff(!props.isStaff)}
        />
      }
        label={<TypographyBreakWordGraySmall>閲覧者</TypographyBreakWordGraySmall>}
      />
    </BoxFlexStart>
  )
}

export default RoleCheck;
