import {
    CONTENTS_TYPE,
    DEFAULT_POSITION,
    UPLOAD_STATUS,
    S3_BUCKET_KEY,
    AWS_INFO,
} from '../const/general'
import errorHandler from './upload_error';
import generateMovieThumbnail from './generate_movie_thumbnail';
import saveContentsInfo from './save_contents_info';
import multipartUploadProc from './multipart_upload';
import AWS from 'aws-sdk';



export const chunkUpload = async (
    movie,
    item,
    info1,
    info2,
    setCounter,
    setSuccessContents,
    successContents,
    setErrorContents,
    errorContents
) => {

    // アップロード
    const upload = async (original_s3_prefix, thumbnail_s3_prefix) => {
        try {
            let multiUpload = false
            let thumbnailUpload = false
            let routeLogUpload = false
            let infoUpload = false

            console.log('start upload')
            AWS.config.update({
                accessKeyId: info1,
                secretAccessKey: info2,
                region: AWS_INFO.REGION,
            });
            const s3 = new AWS.S3();
            // 分割アップロード
            //multiUpload = await multipartUploadProc(original_s3_prefix)
            multiUpload = await multipartUploadProc(
                movie, original_s3_prefix, setCounter, info1, info2)
            console.log('end multiUpload')

            // ルートログアップロード
            if (item.route_log) {
                routeLogUpload = await routeLogUploadProc(s3)
            }

            // サムネイルアップロード
            console.log('start thumbnailUpload')
            if (multiUpload)
                thumbnailUpload = await thumbnailUploadProc(thumbnail_s3_prefix, s3)
                console.log('end thumbnailUpload')

            // 動画の付帯情報をDBに登録
            if (thumbnailUpload)
                infoUpload = await saveContentsInfo(movie, item, setSuccessContents, errorContents, setErrorContents)

        } catch (e) {
            console.log(e);
            // 分割アップロード処理で失敗した場合の処理
            errorHandler(UPLOAD_STATUS.CHUNCK_PUT, movie, item, errorContents, setErrorContents)
        }
    }

    // サムネイルアップロード
    const thumbnailUploadProc = async (thumbnail_s3_prefix, s3) => {
        try {
            // 画像群の場合はサムネイル作成はLambdaに任せる
            // サムネイル生成
            if (movie.contentType === CONTENTS_TYPE.MOVIE) {
                const thumbnail = await generateMovieThumbnail(movie.uploadFile);
                // サムネイルアップロード
                const params = {
                    Bucket: AWS_INFO.S3_BUCKET_NAME,
                    Key: thumbnail_s3_prefix + movie.id + '.png',
                    ContentType: "image/png",
                    CacheControl:'no-cache',
                    Body: thumbnail,
                };
                const res = s3.putObject(params).promise();
            }
            return true

        } catch (e) {
            console.log(e);
            // 分割アップロード最終処理で失敗した場合の処理
            errorHandler(UPLOAD_STATUS.INFO_SAVE, movie, item, errorContents, setErrorContents)
            return false
        }
    }

    // ルートログアップロード
    const routeLogUploadProc = async (s3) => {
        try {
            const key = S3_BUCKET_KEY.MOVIE_ROUTE_LOG + movie.id
                + '.' + item.route_log_file_name.split('.').pop()

            if (movie.contentType === CONTENTS_TYPE.MOVIE) {
                // ルートログアップロード
                const params = {
                    Bucket: AWS_INFO.S3_BUCKET_NAME,
                    Key: key,
                    ContentType: item.route_log.type,
                    Body: item.route_log,
                };
                const res = s3.putObject(params).promise();
            }
            return true

        } catch (e) {
            console.log(e);
            // 分割アップロード最終処理で失敗した場合の処理
            errorHandler(UPLOAD_STATUS.INFO_SAVE, movie, item, errorContents, setErrorContents)
            return false
        }
    }

    // アップロード成功リストにあるファイルは何もしない
    if (successContents.some(content => content.movieId === movie.id)) {
        return
    }

    // データ補完
    if (item.latitude == undefined) {
        item.latitude = DEFAULT_POSITION.LAT
    }
    if (item.longitude == undefined) {
        item.longitude = DEFAULT_POSITION.LNG
    }
    if (item.emergency_level == undefined) {
        item.emergency_level = 0
    }

    let original_s3_prefix = S3_BUCKET_KEY.MOVIE_ORIGINAL
    let thumbnail_s3_prefix = S3_BUCKET_KEY.MOVIE_THUMBNAIL
    switch (movie.contentType) {
        case CONTENTS_TYPE.MOVIE:
            original_s3_prefix = S3_BUCKET_KEY.MOVIE_ORIGINAL
            thumbnail_s3_prefix = S3_BUCKET_KEY.MOVIE_THUMBNAIL
            break
        case CONTENTS_TYPE.IMAGE:
            original_s3_prefix = S3_BUCKET_KEY.IMAGE_ORIGINAL
            thumbnail_s3_prefix = S3_BUCKET_KEY.IMAGE_THUMBNAIL
            break
        case CONTENTS_TYPE.ZIP:
            original_s3_prefix = S3_BUCKET_KEY.ZIP_ORIGINAL
            thumbnail_s3_prefix = S3_BUCKET_KEY.ZIP_THUMBNAIL
            break
        default:
            original_s3_prefix = S3_BUCKET_KEY.MOVIE_ORIGINAL
            thumbnail_s3_prefix = S3_BUCKET_KEY.MOVIE_THUMBNAIL
            break
    }

    upload(original_s3_prefix, thumbnail_s3_prefix)

}
