import React from 'react'
import Button from '@mui/material/Button';
import { SubmitButton, } from '../../style/button'

export default function ButtonSubmit(props) {
  return (
    <SubmitButton
      variant="contained"
      type="submit"
    >{props.text}</SubmitButton>
  )
}