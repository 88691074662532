import React, { useEffect, useRef, useState } from 'react'

import {
    Box,
    Divider,
    Stack,
    Grid,
} from "@mui/material";
import ContentInfo from '../../items/content_info'
import ContentsList from '../../items/contents_list'
import ViewMovie from '../../items/view_movie'
// import SearchConditionsSmall from '../../items/search_conditions_small'
import { MainWrapper } from '../../const/style'
import { CONTENTS_TYPE } from '../../const/general'
  import { getSearchStr } from '../../functions/get_search_conditions'
import { setSelectContentSources } from '../../functions/select_content_sources';
import { setSelectContentImgUrl } from '../../functions/select_content_img_url';
import { SelectedTagChipSmall } from '../../style/chip';
import { TypographyBreakWordGraySmallR } from '../../style/typography';
import { BoxFlexStartWrap } from '../../style/box';

function PlayerAndContentsList(props) {

    const [viewSearchStr, setViewSearchStr] = useState([]);

    let contentSources = []
    let contentImgUrl = ''
    if (props.selectContent.contents_type === CONTENTS_TYPE.MOVIE) {
        // 動画のsource
        contentSources = setSelectContentSources(props.selectContent)
    } else {
        // 動画のsource
        contentImgUrl = setSelectContentImgUrl(props.selectContent)
    }

    // topへ戻る要素への参照
    const scrollToTopRef = useRef();

    // ページのトップにスクロール
    const scrollToTopOfList = React.useCallback(() => {
        scrollToTopRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        })
    }, [scrollToTopRef])

    useEffect(() => {
        // コンテンツが選択されたらページのトップにスクロール
        scrollToTopOfList()
    }, [props.selectContent.contents_uuid]);

    useEffect(() => {
        // 現在の検索条件を文字列に起こす
        setViewSearchStr(getSearchStr(props.searchConditions))

    }, [props.searchConditions]);

    return (
        <MainWrapper>
            {/* <Stack sx={{ height: "100%" }}> */}
            <div id="top-of-list" ref={scrollToTopRef} />
            {props.selectContent.file_name !== undefined &&
                <>
                    <ViewMovie
                        sources={contentSources}
                        imageUrl={contentImgUrl}
                        contentsType={props.selectContent.contents_type}
                        selectContentUUID={props.selectContent.contents_uuid}
                        chapters={props.selectContent.chapters}
                        selectContent={props.selectContent}
                    />
                    <ContentInfo selectContent={props.selectContent} />
                </>
            }
            <Divider />
            <BoxFlexStartWrap sx={{ ml: 2, mt: 1 }}>
                <TypographyBreakWordGraySmallR>{props.contentsCount}件</TypographyBreakWordGraySmallR>
                {viewSearchStr.map((str) => {
                    return (
                        <TypographyBreakWordGraySmallR>{str}</TypographyBreakWordGraySmallR>
                    )
                })}
                {props.selectTag.tag_uuid !== '' &&
                    <SelectedTagChipSmall label={props.selectTag.tag_name} />
                }

            </BoxFlexStartWrap>
            <Box>
                <Grid container >
                    {props.contents.map((row, i) => (
                        <ContentsList
                            key={i}
                            row={row}
                            selectContentUUID={props.selectContent.contents_uuid}
                            handleClick={props.handleClick}
                        />
                    ))}
                </Grid>
            </Box>
            {/* </Stack> */}
        </MainWrapper>
    );
}
export default PlayerAndContentsList;
