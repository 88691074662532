import LOGO_PREIN from '../_img/logo_prein.png'

// PREIN愛媛テスト環境
export const URL_BASE='https://drone.prein.jp/'
export const AWS_S3_BUCKET_NAME='prod-prein-drone-contents'
export const COPYRIGHT_TEXT ='© Inspiration.Plus'
export const LOGO_IMG = LOGO_PREIN
export const SERVICE_NAME = 'PREIN'
export const DEFAULT_LAT = 33.506399
export const DEFAULT_LNG = 132.544520
export const MANUAL = 'prein_drone_manual_ver1.pdf'
export const SERVICE_TOP_URL = ''
export const RISK_URL = 'https://risk.prein.jp'
export const ARCHIVE_SITE_URL = ''
export const DASHBOARD_URL = 'https://prein.jp10.hcs.cloud.sap/link/prein-ehime'

