import React, { useState } from 'react'

import FailedToLoad from '../../failed_to_load'
import UserInfoForm from '../../../contents/settings_user_form'
import Proggress from '../../../items/progress'
import MessageBar from '../../../items/snackbar'

import { URL } from '../../../const/general'
import { GetUser, LoadMutate } from '../../../functions/get_data_swr';


function SettingsUserBody() {
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');

  const reload = () => {
    // LoadMutate(URL.USER + Cookies.get('id') + '/')
    LoadMutate(URL.USER_INFO)
  }

  const { user, isUserLoading, isUserError } = GetUser();
  if (isUserError) return <FailedToLoad />
  if (isUserLoading) return <Proggress />
  // console.log(user[0]);
  return (
    <>
      <UserInfoForm
        id={user[0].id}
        email={user[0].email}
        affiliation={user[0].affiliation}
        latitude={user[0].latitude}
        longitude={user[0].longitude}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar}
        reload={reload}
      />
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
    </>
  )
}

export default SettingsUserBody;
