import axios from 'axios';
import Cookies from 'js-cookie';
import axAuth from './axios_auth';
import { URL, COOKIES_KEY, AXIOS_TIMEOUT } from '../const/general';
import {URL_BASE } from '../const/config';

axios.defaults.withCredentials = true

// インスタンスを作る
const ax = axios.create({
    baseURL: URL.API_BASE,
    timeout: AXIOS_TIMEOUT.REGULAR,
    retries: 3,
    retryCount: 0,
})

ax.interceptors.request.use(config => {
    // console.log(config)
    return config
})

// Loginページに遷移
const locationLogin = () => {
    const nowLocation = window.location.href
    const nowUrl = nowLocation.replace(URL_BASE, '')
    console.log(nowUrl)
    const depth = (String(nowUrl).match(new RegExp(String('/'), 'g')) || []).length
    let path_depth = ''
    for (let i = 0; i < depth; i++) {
        path_depth += '../'
    }
    window.location = path_depth + URL.LOGIN + '?lastpage=/' + nowUrl;
}

let isRetry = false
ax.interceptors.response.use(
    response => {
        //console.log(response.data)
        return response
    }, async error => {
        // エラー時
        console.log(error)
        const originalRequest = error.config
        if (error.code === 'ECONNABORTED') {
            if ((error.config.retries ?? 0) > (error.config.retryCount ?? 0)) {
                error.config.retryCount = (error.config.retryCount ?? 0) + 1
                console.log(error.config.retryCount)
                return ax.request(error.config)
            }
        }
        if (error.response !== undefined
            && error.response !== null
            && error.response.status === 401) {
            if (isRetry) {
                locationLogin()
            }
            isRetry = true
            return axAuth
                .post('token/refresh/'
                    , { refresh: Cookies.get(COOKIES_KEY.REFRESH_TOKEN) }
                )
                .then(res => {
                    if (res.status === 200) {
                        console.log('refresh success')
                        // console.log(res.data.access)
                        Cookies.set(COOKIES_KEY.ACCESS_TOKEN, res.data.access_token)
                        Cookies.set(COOKIES_KEY.REFRESH_TOKEN, res.data.refresh_token)
                        return axios(originalRequest)
                    }
                })
                .catch((err) => {
                    console.log('refresh error')
                    locationLogin()
                    return Promise.reject(err)
                })


        } else {
            // 認証以外のエラーで失敗した場合
            //locationLogin()
            return Promise.reject(error)
        }

    })

export default ax;