import * as React from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  Typography
} from "@mui/material";

export default function DownloadDialog(props) {

  return (
    <>
      <Dialog
        open={props.confirmOpen}
        onClose={props.handleCancel} >
        <Alert severity="error">
          <AlertTitle>{props.dialogTitle}</AlertTitle>
          <Typography >{props.dialogMessage1}</Typography>
          <Typography >{props.dialogMessage2}</Typography>
          {props.downloadDisAbledList.map((title) => (
            <Typography >・{title}</Typography>
          ))}
          <DialogActions>
            <Button onClick={props.handleCancel}>キャンセル</Button>
            <Button onClick={props.handleAction} autoFocus>
              {props.buttonName}
            </Button>
          </ DialogActions>
        </Alert>
      </Dialog>
    </>
  );
}
