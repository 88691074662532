import React, { useState } from 'react'
import { TagChip, HoverTagChip } from '../../style/chip';
import getAddress from '../../functions/get_address';
import {
  BoxFlexStartWrap,
  StackLR10,
} from '../../style/box'

export default function SelectSurvey(props) {

  const [selectUUID, setSelectUUID] = useState('');

  const onClick = async (event, row) => {
    event.preventDefault()
    setSelectUUID(row.survey_uuid)
    console.log(row.latitude)
    console.log(row.emergency_level)
    props.setTitle(row.title);
    props.setLatitude(row.latitude);
    props.setLongitude(row.longitude);
    props.setOverview(row.overview);
    props.setSelectSurveyUUID(row.survey_uuid)
    const address = await getAddress(
      row.latitude, row.longitude)
    props.setAddress(address);

  }

  return (
    <BoxFlexStartWrap>
    {props.surveyList.map((row, index) =>
      selectUUID === row.survey_uuid ?
        <HoverTagChip
          key={index}
          label={row.title}
          type="button"
          onClick={(event) => onClick(event, row)}
        />
        :
        <TagChip
          key={index}
          label={row.title}
          type="button"
          onClick={(event) => onClick(event, row)}
        />
    )}
    </BoxFlexStartWrap>
  )
}