import React, { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Grid,
  Box,
  LinearProgress,
  Typography
} from '@mui/material';

import Movie from '../../../contents/upload_movies';
import MovieSpecifySurvey from '../../../contents/upload_specify_survey';
import UploadSuccess from '../../../contents/upload_success';
import UploadRetry from '../../../contents/upload_retry';
import UploadError from '../../../contents/upload_error';
import DropBox from '../../../items/file_drop_box';
import ProgressModal from '../../../items/progress_modal'
import Proggress from '../../../items/progress'
import MessageBar from '../../../items/snackbar'
import FailedToLoad from '../../failed_to_load'

import UUID from 'uuidjs'
import Common from '../../../functions/common';
import cliAuth from '../../../functions/axios_cli';
import SendForHana from '../../../functions/send_for_hana';
import { checkZip } from '../../../functions/check_zip';
import { CONTENTS_TYPE, UPLOAD_FILE } from '../../../const/general'
import { GetActiveTags, GetActiveSurveys } from '../../../functions/get_data_swr';
import generateMovieThumbnail from '../../../functions/generate_movie_thumbnail';

import Modal from "react-modal";

Modal.setAppElement("#root");

function UploadMovie(props) {
  const [searchParams] = useSearchParams();
  const [selectSurveyInfo, setSelectSurveyInfo] = useState(null);
  const [info1, setInfo1] = useState('');
  const [info2, setInfo2] = useState('');
  const [selectTagList, setSelectTagList] = useState([]);

  const [progress, setProgress] = useState(0);
  const [counter, setCounter] = useState(0);
  const [chunkSumCount, setChunkSumCount] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isFinal, setIsFinal] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [isError, setIsError] = useState(false);

  const [movies, setMovies] = useState([]);
  const [successContents, setSuccessContents] = useState([]);
  const [retryContents, setRetryContents] = useState([]);
  const [errorContents, setErrorContents] = useState([]);

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');

  const [fileSizeProgressCount, setFileSizeProgressCount] = useState(0);
  const [fileSizeSum, setFileSizeSum] = useState(0);

  const sendForHana = () => {
    if (successContents.length > 0) {
      // HANAにデータ連携
      SendForHana()
    }
  }
  useEffect(() => {
    const selectSurvey = searchParams.get("survey")
    const selectTags = searchParams.get("tag")
    console.log(selectSurvey)
    console.log(selectTags)
    if (selectSurvey !== null && selectSurvey !== '') {
      cliAuth.get(
        `survey_info/?survey_uuid=${selectSurvey}`
      ).then(response => {
        console.log(response.data)
        setSelectSurveyInfo(response.data[0]);
      }).catch(error => {
        console.log(error)
      })
    }
    if (selectTags !== null && selectTags !== '') {
      cliAuth.get(
        `tag_info/?tag_uuid=${selectTags}`
      ).then(response => {
        console.log(response.data)
        setSelectTagList(response.data);
      })
    }
    cliAuth.get(
      `info/`
    ).then(response => {
      setInfo1(response.data[0].key1)
      setInfo2(response.data[0].key2)
    }).catch(error => {
      console.log(error)
    })

  }, [searchParams.get("survey"), searchParams.get("tag")]);

  useEffect(() => {
    // ファイル合計が10GBを超える場合はエラー
    console.log(fileSizeSum)
    if (fileSizeSum > UPLOAD_FILE.MAX_FILES_SIZE_SUM) {
      deleteMovies(movies[movies.length - 1].id)
      setSnackBarMessage('一度にアップロードできるのは10GBまでです')
      setSnackBarStatus('error')
      setOpenSnackBar(true)
    }
    if (movies.length > UPLOAD_FILE.MAX_FILES_COUNT) {
      deleteMovies(movies[movies.length - 1].id)
      setSnackBarMessage('一度にアップロードできるのは10ファイルまでです')
      setSnackBarStatus('error')
      setOpenSnackBar(true)
    }
  }, [fileSizeSum])

  useEffect(() => {
    // プログレスカウントアップ
    if (counter < chunkSumCount) {
      const pro = Math.ceil(counter / chunkSumCount * 100)
      setProgress(Math.ceil(counter / chunkSumCount * 100))
    }
    // 分割アップロード終了後のサークルプログレス表示
    if ((counter !== 0 && counter === chunkSumCount)) {
      setProgress(100)
      setIsFinal(true)
    }
    // // 分割アップロード終了後のサークルプログレス表示
    // if ((progress !== 0 && progress === 100)) {
    //   setProgress(100)
    //   setIsFinal(true)
    // }
  }, [counter])


  useEffect(() => {
    // リトライありの場合の処理
    if ((movies.length !== 0
      && retryContents.length > 0
      && successContents.length + retryContents.length === movies.length)) {
      console.log(retryContents)
      console.log(movies)
      setIsSuccess(false)
      setIsRetry(true)
      setIsError(false)
      setIsFinal(false)
      setIsOpen(false)
      setProgress(0)
      setCounter(0)
      setChunkSumCount(0)
      setFileSizeProgressCount(0)
      setFileSizeSum(0)
      sendForHana()
      return
    }
  }, [successContents, retryContents])

  useEffect(() => {
    // エラーありの場合の処理
    if ((movies.length !== 0
      && errorContents.length > 0
      && successContents.length + errorContents.length === movies.length)) {
      setIsSuccess(false)
      setIsRetry(false)
      setIsError(true)
      setIsFinal(false)
      setIsOpen(false)
      setProgress(0)
      setCounter(0)
      setChunkSumCount(0)
      setFileSizeProgressCount(0)
      setFileSizeSum(0)
      sendForHana()
      return
    }
  }, [successContents, errorContents])

  useEffect(() => {
    // 全て成功した時の処理
    if ((movies.length !== 0
      && successContents.length === movies.length)) {
      setIsSuccess(true)
      setIsRetry(false)
      setIsError(false)
      setIsFinal(false)
      setIsOpen(false)
      setProgress(0)
      setCounter(0)
      setChunkSumCount(0)
      setFileSizeProgressCount(0)
      setFileSizeSum(0)
      sendForHana()
      return
    }
  }, [successContents])

  const deleteMovies = (id) => {
    console.log(id)
    movies.map((movie) => {
      if (movie.id === id) {
        if (fileSizeSum - movie.size > 0) {
          const dis_count = movie.size / UPLOAD_FILE.MAX_FILES_SIZE_SUM * 100
          setFileSizeSum((prevCount) => prevCount - movie.size)
          setFileSizeProgressCount((prevCount) => prevCount - dis_count)
        } else {
          setFileSizeSum(0)
          setFileSizeProgressCount(0)
        }
        return
      }
    })
    setMovies(
      movies.filter((movie) => (movie.id !== id))
    )
  }

  const allClear = () => {
    setIsSuccess(false)
    setIsRetry(false)
    setIsError(false)
    setMovies([])
    setSuccessContents([])
    setRetryContents([])
    setErrorContents([])
  }

  const fileInfo = (file, thumbnailUrl) => {

    const path = file.path
    const ext = path.substr(path.indexOf('.'));

    let url = ''
    let contentType = CONTENTS_TYPE.MOVIE
    const isVideo = new RegExp(/^video/);
    if (isVideo.test(file.type)) {
      contentType = CONTENTS_TYPE.MOVIE
      url = URL.createObjectURL(file)
    }
    const isImage = new RegExp(/^image/);
    if (isImage.test(file.type)) {
      contentType = CONTENTS_TYPE.IMAGE
      url = URL.createObjectURL(file)
    }
    const isZip = new RegExp(/^application/);
    if (isZip.test(file.type)) {
      contentType = CONTENTS_TYPE.ZIP
      url = ''
    }
    return {
      id: UUID.generate(),
      url: url,
      ext: ext,
      uploadFile: file,
      size: file.size,
      name: file.name,
      contentType: contentType,
      strSize: Common.getStrFileSize(file.size),
      lastModifiedDate: file.lastModifiedDate,
      thumbnailUrl: thumbnailUrl
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    // 新たにアップロードファイルがドロップされたら
    setIsSuccess(false)
    setIsRetry(false)
    setSuccessContents([])
    setRetryContents([])
    
    // ファイルを検証してOKならアップロードファイルに追加する
    acceptedFiles.map((file) => { validateFile(file) })
  }, []);

  const validateFile = async (file) => {
    const validTypes =
      [
        'image/png',
        'image/jpeg',
        'image/gif',
        // 'video/mpeg',
        'video/mp4',
        // 'video/webm',
        // 'video/ogg',
        'video/quicktime',
        'application/zip',
        'application/x-zip-compressed'
      ];
    // ファイルタイプが動画でも画像でもない場合はエラー
    console.log(file.type)

    // Zipの場合、中身が画像群であるか検証
    if (file.type === 'application/zip' || file.type === 'application/x-zip-compressed') {
      // const isImages = await checkZip(file)
      console.log(file)
      await checkZip(file).then(res => {
        if (res) {
          setMovies((prevState) => [
            ...prevState,
            fileInfo(file),
          ]);
          return;
        } else {
          console.log(res)
          setSnackBarMessage(`ZIPファイルに不正なファイルが見つかりました。${file.name}に画像以外のファイルが含まれていないかご確認ください。`)
          setSnackBarStatus('error')
          setOpenSnackBar(true)
          return null
        }

      }).catch(error => {
        console.log(error)
        return null;
      })
    } else {
      if (validTypes.indexOf(file.type) === -1) {
        setSnackBarMessage('申し訳ございません。指定されたファイルはアップロードできません。\n 画像・動画（.mp4 .mov）・ZIPファイルのいずれかをご指定ください。')
        setSnackBarStatus('error')
        setOpenSnackBar(true)
        return null;
      } else {
        let thumbnailUrl = ''
        const isVideo = new RegExp(/^video/);
        if (isVideo.test(file.type)) {
          const thumbnail = await generateMovieThumbnail(file);
          thumbnailUrl = URL.createObjectURL(thumbnail)
        }
        setMovies((prevState) => [
          ...prevState,
          fileInfo(file, thumbnailUrl),
        ]);
      }
    }

    setFileSizeSum((prev) => prev + file.size)
    const add_count = file.size / UPLOAD_FILE.MAX_FILES_SIZE_SUM * 100
    setFileSizeProgressCount((prev) => prev + add_count)
    return true;
  }

  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        margin: 1
      }}>
        <Box sx={{ width: { xs: '65%', sm: '80%', md: '80%' }, mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: { xs: '35%', sm: '20%', md: '20%' } }}>
          <Typography variant="body2" color="text.secondary">{
            `${props.str_top}/${props.str_bottom}`}</Typography>
        </Box>
      </Box>
    );
  }

  const { activeTags, isActiveTagsLoading, isActiveTagsError } = GetActiveTags();
  const { activeSurvey, isActiveSurveyLoading, isActiveSurveyError } = GetActiveSurveys();
  // if (isActiveTagsError) return <FailedToLoad />
  if (isActiveTagsLoading) return <Proggress />
  //if (isActiveSurveyError) return <FailedToLoad />
  if (isActiveSurveyLoading) return <Proggress />

  return (
    <>
      {isSuccess &&
        successContents.length > 0 &&
        <UploadSuccess
          successContents={successContents}
          allClear={allClear}
        />
      }
      {isError &&
        <>
          {successContents.length > 0 &&
            <UploadSuccess
              successContents={successContents}
              allClear={allClear}
            />
          }
          {errorContents.length > 0 &&

            <UploadError
              errorContents={errorContents}
              allClear={allClear}
            />
          }
        </>
      }
      {isRetry &&
        <>
          {retryContents.length > 0 &&
            <UploadRetry
              movies={movies}
              setSuccessContents={setSuccessContents}
              successContents={successContents}
              setRetryContents={setRetryContents}
              retryContents={retryContents}
              setErrorContents={setErrorContents}
              errorContents={errorContents}
              setProgress={setProgress}
              setCounter={setCounter}
              setChunkSumCount={setChunkSumCount}
              setIsOpen={setIsOpen}
            />
          }

          {successContents.length > 0 &&
            <UploadSuccess
              successContents={successContents}
              allClear={allClear}
            />
          }
        </>
      }
      {!isSuccess && !isRetry && !isError &&
        <>
          <DropBox onDrop={onDrop} />
          {movies.length > 0 &&
            <>
              <LinearProgressWithLabel
                value={movies.length / 10 * 100}
                str_top={movies.length}
                str_bottom={'10ファイル'}
              />
              <LinearProgressWithLabel
                value={fileSizeProgressCount}
                str_top={Common.getStrFileSize(fileSizeSum)}
                str_bottom={'10GB'} />
              {/* URLパラメータがある場合Formを分ける */}
              {/* {selectSurveyInfo !== null ?
                <MovieSpecifySurvey
                  movies={movies}
                  deleteMovies={deleteMovies}
                  tagList={selectTagList}
                  setProgress={setProgress}
                  setCounter={setCounter}
                  setChunkSumCount={setChunkSumCount}
                  setSuccessContents={setSuccessContents}
                  successContents={successContents}
                  setRetryContents={setRetryContents}
                  retryContents={retryContents}
                  setIsOpen={setIsOpen}
                  setIsFinal={setIsFinal}
                />
                : */}
              <Movie
                movies={movies}
                deleteMovies={deleteMovies}
                activeTags={activeTags}
                selectTagList={selectTagList}
                selectSurveyInfo={selectSurveyInfo}
                surveyList={activeSurvey}
                info1={info1}
                info2={info2}
                setProgress={setProgress}
                setCounter={setCounter}
                setChunkSumCount={setChunkSumCount}
                setSuccessContents={setSuccessContents}
                successContents={successContents}
                // TODO リトライさせないための一時的な処理
                // setRetryContents={setRetryContents}
                // retryContents={retryContents}
                setRetryContents={setErrorContents}
                retryContents={errorContents}
                setIsOpen={setIsOpen}
                setIsFinal={setIsFinal}
              />

              {/* } */}
            </>}
        </>
      }
      <ProgressModal
        modalIsOpen={modalIsOpen}
        isFinal={isFinal}
        progress={progress}
        text1={'アップロード最終処理中です...'}
        text2={'この処理は数分かかる場合があります...'}
      />
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
    </>
  );
}

export default UploadMovie;
