import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function IconLandSlide(props) {

  return (
    <SvgIcon
      sx={{ width: props.width, height: props.height }}
      viewBox='0 0 24 24'>
      <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" >
        <g><rect fill="none" height="24" width="24" /></g>
        <g><g><polygon points="15.47,13.79 12.89,12.76 6,15.05 2,13.51 2,15.61 6,16.95" />
          <polygon points="10.57,11.42 8,8 2,8 2,11.61 6,12.95" />
          <polygon points="6,19.05 2,17.72 2,22 22,22 17.03,15.38" />
          <polygon points="17,6 17,1 12,0 9,2 9,6 12,8" />
          <polygon points="18.5,7 16,9 16,12 18.5,14 23,12 23,8" />
        </g></g></svg>
    </SvgIcon>
  );
}
