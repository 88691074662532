import {
  SEARCH_PARAMS,
  DATE_TYPE,
  DATE_TYPE_STR,
  ORDER_TYPE,
  ORDER_TYPE_STR,
} from '../const/search_conditions'
import {
  LEVEL_NAME,
} from '../const/risk_style'

export const getSearchParam = (firstStr, searchConditions) => {
  let param_conditions = firstStr
  param_conditions += `${SEARCH_PARAMS.REGISTER_DATE_TYPE}=${searchConditions.registered_date_type}`
  if (searchConditions.registered_date_type === DATE_TYPE.PERIOD) {
    param_conditions += `&${SEARCH_PARAMS.REGISTER_START}=${searchConditions.registered_starting_date}`
    param_conditions += `&${SEARCH_PARAMS.REGISTER_END}=${searchConditions.registered_ending_date}`
  }
  param_conditions += `&${SEARCH_PARAMS.SHOOTE_DATE_TYPE}=${searchConditions.shoote_date_type}`
  if (searchConditions.shoote_date_type === DATE_TYPE.PERIOD) {
    param_conditions += `&${SEARCH_PARAMS.SHOOTE_START}=${searchConditions.shoote_starting_date}`
    param_conditions += `&${SEARCH_PARAMS.SHOOTE_END}=${searchConditions.shoote_ending_date}`
  }
  param_conditions += `&${SEARCH_PARAMS.EMERGENCY}=${searchConditions.emergency_level_emergency}`
  param_conditions += `&${SEARCH_PARAMS.IMPORTANT}=${searchConditions.emergency_level_important}`
  param_conditions += `&${SEARCH_PARAMS.NOMAL}=${searchConditions.emergency_level_nomal}`
  param_conditions += `&${SEARCH_PARAMS.ADDRESS}=${searchConditions.address}`
  param_conditions += `&${SEARCH_PARAMS.OVERVIEW}=${searchConditions.overview}`
  param_conditions += `&${SEARCH_PARAMS.ORDER}=${searchConditions.order}`
  return param_conditions
}
export const getSearchStr = (searchConditions) => {

  let array = []
  const registered = strDateType(
    '投稿日：',
    searchConditions.registered_date_type,
    searchConditions.registered_starting_date,
    searchConditions.registered_ending_date
  )
  if (registered !== '') {
    array.push(registered)
  }
  const shoote = strDateType(
    '撮影日：',
    searchConditions.shoote_date_type,
    searchConditions.shoote_starting_date,
    searchConditions.shoote_ending_date
  )
  if (shoote !== '') {
    array.push(shoote)
  }
  const level = strEmergencyLevel(
    '重要度：',
    searchConditions.emergency_level_emergency,
    searchConditions.emergency_level_important,
    searchConditions.emergency_level_nomal
  )
  if (level !== '') {
    array.push(level)
  }
  if (searchConditions.address !== '') {
    array.push('住所：' + searchConditions.address)
  }
  if (searchConditions.overview !== '') {
    array.push('概要：' + searchConditions.overview)
  }
  array= strOrder(
    '並び替え：',
    array,
    searchConditions.order
  )
  return array
}

const strDateType = (title, date_type, start, end) => {
  let str = title
  switch (date_type) {
    case DATE_TYPE.ALL:
      str = ''
      break
    case DATE_TYPE.DAY:
      str += DATE_TYPE_STR.DAY
      break
    case DATE_TYPE.WEEK:
      str += DATE_TYPE_STR.WEEK
      break
    case DATE_TYPE.MONTH:
      str += DATE_TYPE_STR.MONTH
      break
    case DATE_TYPE.YEAR:
      str += DATE_TYPE_STR.YEAR
      break
    case DATE_TYPE.PERIOD:
      str += start
      str += '〜'
      str += end
      break
    default:
      str = ''
      break
  }
  return str
}

const strEmergencyLevel = (title, emergency, important, nomal) => {
  let str = title
  let array = []
  console.log(emergency)
  console.log(important)
  console.log(nomal)
  if (emergency && important && nomal) {
    str = ''
  } else {
    if (emergency) {
      array.push(LEVEL_NAME.EMERGENCY)
    }
    if (important) {
      array.push(LEVEL_NAME.IMPORTANT)
    }
    if (nomal) {
      array.push(LEVEL_NAME.NORMAL)
    }
    str += array.join(',')
  }
  return str
}

const strOrder = (title, searchArray, order) => {
  switch (order) {
    case ORDER_TYPE.SHOOTE_DATE_NEW:
      if(searchArray.length > 0){
        searchArray.push(title + ORDER_TYPE_STR.SHOOTE_DATE_NEW)
      }
      break
    case ORDER_TYPE.SHOOTE_DATE_OLD:
      searchArray.push(title + ORDER_TYPE_STR.SHOOTE_DATE_OLD)
      break
    case ORDER_TYPE.REGISTER_DATE_NEW:
      searchArray.push(title + ORDER_TYPE_STR.REGISTER_DATE_NEW)
      break
    case ORDER_TYPE.REGISTER_DATE_OLD:
      searchArray.push(title + ORDER_TYPE_STR.REGISTER_DATE_OLD)
      break
    default:
      break
  }
  return searchArray
}
