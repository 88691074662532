import cliAuth from './axios_cli';

export default async function patchUser(
  data,
  setSuccessMessage,
  setSuccessStatus,
  setOpenSuccessBar,
  setErrorMessage,
  setErrorStatus,
  setOpenErrorBar,
  isMultiple
) {
  // パスワードが入力されていない場合は
  // requestからパスワードを削除
  delete data['password_repeat']
  if (data['password'] === null || data['password'] === '') {
    delete data['password']
  }
  const result = await cliAuth.patch(`users/${data.id}/`, data)
    .then(response => {
      // 送信成功時の処理
      console.log(`users/${data.id}/ patch success'`, response)
      if (isMultiple) {
        setSuccessMessage(`${response.data.email}のユーザー情報を更新しました`)
      } else {
        setSuccessMessage('ユーザー情報を更新しました')
      }
      setSuccessStatus('success')
      setOpenSuccessBar(true)
      return true
    })
    .catch(err => {
      if (err.response === undefined) {
        setErrorMessage('ユーザー情報の更新に失敗しました')
      } else if (err.response.data.password) {
        setErrorMessage(err.response.data.password)
      } else {
        if (isMultiple) {
          setErrorMessage(`${err.response.data.email}のユーザー情報の更新に失敗しました`)
        } else {
          setErrorMessage('ユーザー情報の更新に失敗しました')
        }
      }
      setErrorStatus('error')
      setOpenErrorBar(true)
      console.log(err);
      return false
    });

  return result
}