import React from 'react'
import CancelSubmit from '../cancel_submit'
import CenterSubmit from '../center_submit'

export default function SwitchButton(props) {
    if (props.proc_type === "update"){
      return ( 
      <CancelSubmit
        onCancel={props.onCancel}
        text={props.submitButtonName} />
     )
    }
    if (props.proc_type === "create"){
      return ( 
        <CenterSubmit
        text={props.submitButtonName} />
       )
    }
}