import React from "react";
import {
    TableCell,
    TableRow,
} from "@mui/material";
import { TypographyNomal } from '../../style/typography'
import TableRowButton from '../table_row_button'

export default function TableRowsHeader(props) {

    return (
        <>
            <TableRow
                sx={{ width: '100%' }}
                key={props.id}
            >
                {props.affiliation ?
                    <>
                        {/* スマホ版 */}
                        <TableCell
                            sx={{
                                display: { xs: 'block', sm: 'none', md: 'none' },
                                maxWidth: { xs: '50vw' },
                                overflowWrap: 'break-word',
                                borderBottom: 'none'
                            }}>
                            <>
                                <TypographyNomal>{props.name}  </TypographyNomal>
                                <TypographyNomal>{'　' + props.affiliation} </TypographyNomal>                            </>
                        </TableCell>
                        {/* PC・タブレット版 */}
                        <TableCell
                            sx={{
                                display: { xs: 'none', sm: 'table-cell', md: 'table-cell' },
                                borderBottom: 'none'
                            }}
                        >{props.name}</TableCell>
                        <TableCell
                            sx={{
                                display: { xs: 'none', sm: 'table-cell', md: 'table-cell' },
                                borderBottom: 'none'
                            }}
                        >{props.affiliation}</TableCell>
                    </>
                    :
                    <TableCell
                        sx={{
                            maxWidth: { xs: '50vw', sm: '60%', md: '70%' },
                            overflowWrap: 'break-all',
                            borderBottom: 'none'
                        }}>{props.name}
                    </TableCell>

                }
                <TableRowButton
                    id={props.id}
                    title={props.name}
                    open={props.open}
                    setOpen={props.setOpen}
                    setDeleteConfirmOpen={props.setDeleteConfirmOpen}
                    setTargetId={props.setDeleteId}
                    setTargetTitle={props.setDeleteName}
                />
            </TableRow>
        </>
    )
}