import React from 'react'
import { Stack, } from "@mui/material";
import { BoxFlexStartWrap } from '../../style/box';
import { TagChip, HoverTagChip, NoneTagChip } from '../../style/chip';
import { TypographyGray, } from '../../style/typography'

export default function SelectTags(props) {

  function Chips(props) {
    return (
      <>
        {props.tagList.map((row, index) => (
          props.selectTagList.findIndex(
            taginfo => taginfo.tag_uuid === row.tag_uuid) !== -1 ?
            <HoverTagChip
              key={index}
              label={row.tag_names}
              type="button"
              onClick={(e) => {
                e.preventDefault()
                props.setSelectTagList((list) =>
                  list.filter((tag) => tag.tag_uuid !== row.tag_uuid)
                );
              }}
            />
            :
            <TagChip
              key={index}
              label={row.tag_names}
              type="button"
              onClick={(e) => {
                e.preventDefault()
                props.setSelectTagList((prevState) => [
                  ...prevState,
                  {
                    tag_uuid: row.tag_uuid,
                    tag_names: row.tag_names
                  },
                ]);
              }}
            />
        ))}
      </>
    )
  }

  function SelectTagsNone(props) {
    let bgcolor = 'rgba(255, 69, 0)'
    props.tagList.map((row, index) => {
      if (props.selectTagList.findIndex(
        taginfo => taginfo.tag_uuid === row.tag_uuid) !== -1) {
        bgcolor = 'rgba(255, 69, 0, 0.2)'
      }
    })
    return (
      <NoneTagChip
        label='タグ未選択'
        sx={{
          backgroundColor: bgcolor,
        }}
      />
    )
  }

  return (
    <Stack>
      <TypographyGray>災害種別タグ (複数選択可)</TypographyGray>
      <BoxFlexStartWrap>
        <SelectTagsNone
          tagList={props.tagList}
          selectTagList={props.selectTagList}
        />
        <Chips
          tagList={props.tagList}
          setSelectTagList={props.setSelectTagList}
          selectTagList={props.selectTagList}
        />
      </BoxFlexStartWrap>
    </Stack>
  )
}