import * as React from 'react';
import { CONTENTS_TYPE } from '../../const/general'
import { Image,Video,} from '../../style/image'

import ZipImg from '../../_img/img_thumb_zip.png'

export default function UploadThumb(props) {

  return (
    <>
      {props.contentType === CONTENTS_TYPE.MOVIE &&
        <Video
          controls
          src={props.url}
          resizeMode={"contain"}
          poster={props.thumbnailUrl}
        />
      }
      {props.contentType === CONTENTS_TYPE.IMAGE &&
        <Image
          controls
          src={props.url}
          resizeMode={"contain"}
        />
      }
      {props.contentType === CONTENTS_TYPE.ZIP &&
        <Image
          controls
          src={ZipImg}
          resizeMode={"contain"}
        />
      }
    </>
  )
}
