import * as React from 'react';
import { InputText, } from '../../style/text_view'

export default function InputField(props) {
    return (
        <InputText
            type="text"
            size="small"
            {...props.register}
            id="outlined-basic"
            variant="outlined"
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            label={props.label}
            helperText={props.error?.message}
            error={props.error?.message.length > 0}
            onKeyUp={e => {
                if (e.key === 'Enter') e.preventDefault();
              }}
        />
    );
}
