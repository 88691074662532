import React, { useState, useEffect } from 'react'
import {
  FormControl,
  FormHelperText,
} from "@mui/material";

import ChipUnselect from '../../atoms/chip_level_unselect'
import ChipDone from '../../atoms/chip_level_selected'

import { EMERGENCY_LEVEL } from '../../const/general';
import { BoxFlexStart } from '../../style/box';

function SelectLevel(props) {

  const [emergencyLevel, setEmergencyLevel] = useState(props.emergencyLevel);

  useEffect(() => {
    props.setValue(props.setValueName, emergencyLevel)
    console.log(emergencyLevel)
  }, [emergencyLevel])

  return (
    <FormControl>
      <FormHelperText>重要度選択</FormHelperText>
      <BoxFlexStart>
        {Object.keys(EMERGENCY_LEVEL).map(lev => {
          if (emergencyLevel === EMERGENCY_LEVEL[lev]) {
            return <ChipDone
              key={EMERGENCY_LEVEL[lev]}
              level={EMERGENCY_LEVEL[lev]}
            />
          } else {
            return <ChipUnselect
              key={EMERGENCY_LEVEL[lev]}
              level={EMERGENCY_LEVEL[lev]}
              setEmergencyLevel={setEmergencyLevel}
              setValue={props.setValue}
              setValueName={props.setValueName}
            />
          }
        })}
      </BoxFlexStart>
    </FormControl>
  )
}

export default SelectLevel;
