import React from 'react';

export default function MovieThumbnail(props) {

  return (
    <>
      <img
        style={{ cursor: 'pointer', width: '100%' }}
        src={props.item.imgPath}
        alt={props.item.label}
        onClick={() => { props.jumpToChapter(props.item.start_time) }}
      />
    </>
  )
}