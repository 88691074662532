import React from 'react'
import styled from 'styled-components'
import { LOGO_IMG } from '../../const/config';

const Wrapper = styled.header`
height: 48px;
  width: 100vw;
  background-color: #061027;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  float:left;
  position: fixed;
  z-index: 10;
`
const Logo = styled.img`
width: 150px;
padding-top: 10px;
padding-left: 15px;
`
const Header = (props) => {

  return (
    <Wrapper>
      <Logo src={LOGO_IMG}></Logo>
      </Wrapper>
  )
}
export default Header;