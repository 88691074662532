import cliAuth from './axios_cli';
import {
    CONTENTS_TYPE,
    UPLOAD_STATUS,
} from '../const/general'
import getAddress from './get_address';
import getMGRS from './get_mgrs';
import getMediaInfo from './get_movie_info';
import getElevation from './get_elevation';
import errorHandler from './upload_error';
import successHandler from './upload_success';

export default async function saveContentsInfo(
    movie, item, setSuccessContents, errorContents,setErrorContents
) {
    try {
        // 国土地理院APIから住所を取得
        const address = await getAddress(item.latitude, item.longitude)
        // UTMグリッドを取得
        const utm_grid = await getMGRS(item.latitude, item.longitude)
        // 標高を取得
        const elevation =await getElevation(item.latitude, item.longitude)

        let info = { width: 0, height: 0, play_time: 0 }
        if (movie.contentType === CONTENTS_TYPE.MOVIE) {
            info = await getMediaInfo(movie.url)
        }

        if (!movie.lastModifiedDate) {
            movie.lastModifiedDate = new Date()
        }
        const isoStr = movie.lastModifiedDate.toISOString();

        let fd_contents = new FormData()
        fd_contents.append('contents_uuid', movie.id)
        fd_contents.append('title', item.title)
        fd_contents.append('shoote_date', isoStr)
        fd_contents.append('latitude', item.latitude)
        fd_contents.append('longitude', item.longitude)
        fd_contents.append('overview', item.overview)
        fd_contents.append('address', address)
        fd_contents.append('utm_grid', utm_grid)
        fd_contents.append('elevation', elevation)
        fd_contents.append('emergency_level', item.emergency_level)
        fd_contents.append('movie_width', info.width)
        fd_contents.append('movie_height', info.height)
        fd_contents.append('movie_resolution', 0)
        fd_contents.append('movie_fps', 0)
        fd_contents.append('movie_play_time', info.play_time)
        if (movie.contentType === CONTENTS_TYPE.IMAGE) {
            fd_contents.append('is_permit3d', false)
        } else {
            fd_contents.append('is_permit3d', item.is_permit3d)
        }
        fd_contents.append('is_copyright', false)
        fd_contents.append('is_public', true)
        fd_contents.append('generate_3d_status', 0)
        fd_contents.append('generate_low_quality_status', 0)
        fd_contents.append('file_name', movie.id + movie.ext)
        fd_contents.append('contents_type', movie.contentType)
        fd_contents.append('file_ext', movie.ext)
        if (item.route_log) {
            fd_contents.append('route_log', 'route_log/' + item.route_log_file_name)
            fd_contents.append('register_id', item.register_id)
        }

        console.log(item);
        if (item.tag !== undefined && item.tag.length > 0) {
            item.tag.map((t) => {
                console.log(t);
                if (t !== null && t !== '') {
                    fd_contents.append('tags', t)
                }
            })
        }
        if (item.tags_input !== undefined && item.tags_input.length > 0) {
            item.tags_input.map((tag_input) => {
                console.log(tag_input);
                if (tag_input !== null && tag_input !== '') {
                    fd_contents.append('tags_input', tag_input)
                }
            })
        }
        console.log(...fd_contents);
        cliAuth.post('create_contents/', fd_contents
            //, {timeout:AXIOS_TIMEOUT.THREE_MINUTE}
        )
            .then(contents_movie_response => {
                // 分割アップロード、動画付帯情報登録成功時の処理
                console.log('create_contents/ post success', contents_movie_response)
                successHandler(movie, item, setSuccessContents)
            })
            .catch(error => {
                // 分割アップロード完了後、動画付帯情報登録で失敗した場合の処理
                errorHandler(UPLOAD_STATUS.INFO_SAVE, movie, item, errorContents,setErrorContents)
            });
    } catch {
        // 分割アップロード最終処理で失敗した場合の処理
        errorHandler(UPLOAD_STATUS.INFO_SAVE, movie, item, errorContents,setErrorContents)
    }
}