import * as React from 'react';
import { Checkbox, FormControlLabel } from "@mui/material";
import { CONTENTS_TYPE } from '../../const/general'

export default function Checkbox3D(props) {
    return (
        props.contentType !== CONTENTS_TYPE.IMAGE &&
        <FormControlLabel control={
            <Checkbox
                {...props.register}
                defaultChecked={props.isPermit3d}
                onChange={() =>
                    props.setValue(props.name,
                        !props.getValues(props.name))}
            />}
            label="3Dを作成する"
        />
    );
}
