import React, { useState } from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import ArrowLeftIcon from '../../atoms/arrow_left_icon'
import ArrowRightIcon from '../../atoms/arrow_right_icon'
import MovieThumbnail from '../../atoms/movie_thumbnail'
import MovieChapterTime from '../../atoms/movie_chapter_time'
import MovieThumbnailInfo from '../../atoms/movie_thumbnail_info'

import { URL, MOVIE_THUMB_MAX_LENGTH } from '../../const/general';
import { BoxSpaceBetween } from '../../style/box';

function ThumbnailList(props) {

    const [currentIndex, setCurrentIndex] = useState(0);

    // サムネイル画像リストを取得
    const getImages = () => {
        let images = []
        props.chapters.map((chapter, i) => {

            let min = Math.floor(chapter.start_time % 3600 / 60);
            let rem = chapter.start_time % 60;
            let ret = ('00' + rem).slice(-2);

            images.push({
                label: chapter.caption_thumb,
                imgPath: URL.CHAPTER_BASE +
                    props.selectContentUUID + '_' + chapter.frame_no + '.jpg',
                level: chapter.risk_level,
                str_time: `${min}:${ret}`,
                start_time: chapter.start_time,
            })
        })
        return images
    }

    // チャプターにジャンプ
    const jumpToChapter = (startTime) => {
        if (props.playerRef.current) {
            console.log(startTime)
            props.playerRef.current.currentTime(startTime)
        }
    }

    const Thumbnail = (props) => {
        return (
            <ImageListItem >
                <MovieThumbnail item={props.item} jumpToChapter={jumpToChapter} />
                <MovieThumbnailInfo item={props.item} jumpToChapter={jumpToChapter} />
                <MovieChapterTime item={props.item} jumpToChapter={jumpToChapter} />
            </ImageListItem>
        )
    }
    const ThumbnailList = (props) => {
        return (
            <ImageList sx={{ width: '90%', height: 'auto', margin: '10px 5%', overflowY: 'unset' }}
                cols={props.cols}>
                {images.map((item, i) => (
                    <Thumbnail key={i} item={item} />
                ))}
            </ImageList>
        )
    }
    
    const images = getImages();
    if (images.length > MOVIE_THUMB_MAX_LENGTH) {
        return (
            <BoxSpaceBetween>
                <ArrowLeftIcon
                    setCurrentIndex={setCurrentIndex}
                    disabled={!currentIndex > 0}
                />
                <ImageList
                    sx={{ width: '90%', height: 'auto', margin: '2px 0', overflowY: 'unset' }}
                    cols={MOVIE_THUMB_MAX_LENGTH}>
                    {images.map((item, i) => {
                        if (i >= currentIndex && i < currentIndex + MOVIE_THUMB_MAX_LENGTH) {
                            return (
                                <Thumbnail key={i} item={item} />
                            )
                        }
                    })}
                </ImageList>
                <ArrowRightIcon
                    setCurrentIndex={setCurrentIndex}
                    disabled={currentIndex === images.length - MOVIE_THUMB_MAX_LENGTH}
                />
            </BoxSpaceBetween>
        )
    }
    if (images.length === MOVIE_THUMB_MAX_LENGTH) {
        return (
            <ThumbnailList cols={MOVIE_THUMB_MAX_LENGTH} />
        )
    }
    if (images.length < MOVIE_THUMB_MAX_LENGTH) {
        return (
            <ThumbnailList cols={3} />
        )
    }
}

export default ThumbnailList;