import React from 'react'
import { Controller } from 'react-hook-form'
import { TextField } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ja from 'date-fns/locale/ja'
import {BoxFlexStartMarginR} from '../../style/box'

export default function FormDatePicker(props) {
  return (
    <BoxFlexStartMarginR>
      <Controller
        control={props.control}
        name={props.name}
        hintText={props.hintText}
        defaultValue={props.defaultValue}

        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
            <DatePicker
              {...field}
              inputFormat='yyyy-MM-dd'
              mask='____-__-__'

              renderInput={(params,) =>
                <TextField {...params} 
                label={props.hintText}
                size='small' 
                error={!props.dateError ? false : true}
                onKeyUp={e => {
                  if (e.key === 'Enter') e.preventDefault();
                }}  
                helperText={props.dateError} />}
              onChange={(newValue) => {props.onChange(newValue)}}
            />
          </LocalizationProvider>
        )}
      />
    </BoxFlexStartMarginR>
  )
}