import React from 'react'
import { UnselectedLevelChip } from '../../style/chip';
import { LEVEL_ICONS } from '../../const/avater_icon';

export default function ChipUnselect(props) {

  return (
    <UnselectedLevelChip
      label={LEVEL_ICONS[props.level].TEXT}
      type="button"
      onClick={(e) => {
        e.preventDefault()
        props.setEmergencyLevel(props.level)
        props.setValue(props.setValueName, props.level)
      }}
      icon={LEVEL_ICONS[props.level].ICON_TAG_HOVER}
      level={props.level}
    />
  )
}