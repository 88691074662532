import JSZip from 'jszip';

export const checkZip = async (file) => {
    const array_ext = ['', 'gif','GIF','jpg','JPG', 'jpeg', 'JPEG', 'png','PNG'];
    const zip = new JSZip();
    const extractedFiles = await zip.loadAsync(file);
    let isImage = true
    extractedFiles.forEach(async (relativePath, file) => {
        const array =file.name.split('/')
        const ext = array[array.length-1].split('.').pop()
        if (array_ext.indexOf(ext) < 0){
            isImage=false
            return false
        }
    });
    return isImage
}
