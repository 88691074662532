
export const SEARCH_PARAMS = {
  REGISTER_DATE_TYPE: 'registered_date_type',
  REGISTER_START: 'registered_starting_date',
  REGISTER_END: 'registered_ending_date',
  SHOOTE_DATE_TYPE: 'shoote_date_type',
  SHOOTE_START: 'shoote_starting_date',
  SHOOTE_END: 'shoote_ending_date',
  EMERGENCY: 'emergency_level_emergency',
  IMPORTANT: 'emergency_level_important',
  NOMAL: 'emergency_level_nomal',
  ADDRESS: 'address',
  OVERVIEW: 'overview',
  ORDER: 'order',
}
export const DATE_TYPE= {
  ALL: 'all',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  PERIOD: 'period',
}
export const DATE_TYPE_STR= {
  ALL: 'すべて',
  DAY: '24時間以内',
  WEEK: '今週',
  MONTH: '今月',
  YEAR: '今年',
  PERIOD: '期間指定',
}
export const ORDER_TYPE= {
  SHOOTE_DATE_NEW: 'shoote_date_new',
  SHOOTE_DATE_OLD: 'shoote_date_old',
  REGISTER_DATE_NEW: 'registered_date_new',
  REGISTER_DATE_OLD: 'registered_date_old',
}
export const ORDER_TYPE_STR= {
  SHOOTE_DATE_NEW: '撮影日新しい順',
  SHOOTE_DATE_OLD: '撮影日古い順',
  REGISTER_DATE_NEW: '投稿日新しい順',
  REGISTER_DATE_OLD: '投稿日古い順',
}

export const strDateType = (title, date_type) => {
  let str = title
  switch (date_type) {
      case DATE_TYPE.ALL:
          str += DATE_TYPE_STR.ALL
          break
      case DATE_TYPE.DAY:
          str += DATE_TYPE_STR.DAY
          break
      case DATE_TYPE.WEEK:
          str += DATE_TYPE_STR.WEEK
          break
      case DATE_TYPE.MONTH:
          str += DATE_TYPE_STR.MONTH
          break
      case DATE_TYPE.YEAR:
          str += DATE_TYPE_STR.YEAR
          break
      case DATE_TYPE.PERIOD:
          str += DATE_TYPE_STR.PERIOD
          break
      default:
          str += DATE_TYPE_STR.ALL
          break
  }

  return str
}