import React from 'react';
import {
  Marker,
  Popup,
} from 'react-leaflet'
import Leaflet from "leaflet";
import { EMERGENCY_LEVEL } from '../../const/general'
import { MAP_ICON } from '../../functions/map_pin_icons'
import MarkerPopup from '../marker_popup'

const MapMarkers = (props) => {

  // const markerIcon = MarkerIcon(props.content, props.selectContent)
  const MarkerIcon = (content, selectContent) => {
    let icon = MAP_ICON.NORMAL
    switch (content.emergency_level) {
      case EMERGENCY_LEVEL.EMERGENCY:
        icon = MAP_ICON.EMERGENCY
        break
      case EMERGENCY_LEVEL.IMPORTANT:
        icon = MAP_ICON.IMPORTANT
        break
      case EMERGENCY_LEVEL.NORMAL:
        icon = MAP_ICON.NORMAL
        break
      default:
        icon = MAP_ICON.NORMAL
        break
    }
    if (content.contents_uuid === selectContent.contents_uuid) {
      icon = MAP_ICON.SELECT
    }
    return Leaflet.divIcon({
      html: icon,
      className: "",
      iconSize: [24, 40],
      iconAnchor: [12, 40],
      popupAnchor: [0, -34],

    });
  }

  return (
    <Marker
      title={props.content.title}
      position={
        [props.content.latitude,
        props.content.longitude]}
      icon={MarkerIcon(props.content, props.selectContent)}

      eventHandlers={{
        click: (e) => {
          console.log(e)
          //e.target.openPopup()
          if (props.selectContent.contents_uuid !== props.content.contents_uuid) {
            props.handleClick(props.content)
          }
        },
      }}
    >
    </Marker>
  )
}

export default MapMarkers;