import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form'
import useOnClickOutside from "./use_onclick_outside";
import Drawer from '@mui/material/Drawer';
import { BoxFlexStartTopMargin, BoxFlexEndTopMarginBR, } from '../../style/box';
import { TypographyGraySmall, TypographyNomal } from '../../style/typography'
import { CheckBoxSearch, RadioSearch } from '../../style/check_radio';
import { InputText, } from '../../style/text_view'

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';

import FormDatePickerStartEnd from '../../items/date_picker_start_end'
import Common from '../../functions/common';
import {
  SEARCH_PARAMS,
  DATE_TYPE,
  DATE_TYPE_STR,
  ORDER_TYPE,
  ORDER_TYPE_STR,
} from '../../const/search_conditions'
import { ADDRESS_SEARCH, OVERVIEW_SEARCH } from '../../const/roules';

import {
  Box,
  Button,
  Stack,
  Tooltip,
  FormHelperText
} from '@mui/material';

// 検索パネル
const SearchDrawer = (props) => {
  const { control, register, handleSubmit, setValue, getValues, formState: { errors }, reset } = useForm({ mode: 'onBlur' });
  const [registeredStartDateError, setRegisteredStartDateError] = useState('');
  const [registeredEndDateError, setRegisteredEndDateError] = useState('');
  const [shooteStartDateError, setShooteStartDateError] = useState('');
  const [shooteEndDateError, setShooteEndDateError] = useState('');
  const [isRegisteredDatePeriod, setIsRegisteredDatePeriod] = useState(false);
  const [isShooteDatePeriod, setIsShooteDatePeriod] = useState(false);

  const onSearch = () => {
    if (isRegisteredDatePeriod) {
      if (registeredStartDateError || registeredEndDateError) {
        // 期間指定にエラーがあれば検索キャンセル
          return
      }
      const start_date = getValues(SEARCH_PARAMS.REGISTER_START)
      if (start_date === null || start_date === undefined || start_date === '') {
          setRegisteredStartDateError('開始日を入力してください')
        return
      }
      const end_date = getValues(SEARCH_PARAMS.REGISTER_END)
      if (end_date === null || end_date === undefined || end_date === '') {
        setRegisteredEndDateError('終了日を入力してください')
        return
      }
    }
    if (isShooteDatePeriod) {
      if (shooteStartDateError || shooteEndDateError) {
        // 期間指定にエラーがあれば検索キャンセル
        return
      } 
      const start_date = getValues(SEARCH_PARAMS.SHOOTE_START)
      if (start_date === null || start_date === undefined || start_date === '') {
        setShooteStartDateError('開始日を入力してください')
        return
      }
      const end_date = getValues(SEARCH_PARAMS.SHOOTE_END)
      if (end_date === null || end_date === undefined || end_date === '') {
        setShooteEndDateError('終了日を入力してください')
        return
      }
   }

    props.setSearchConditions(
      {
        ...props.searchConditions,
        registered_date_type: getValues(SEARCH_PARAMS.REGISTER_DATE_TYPE),
        registered_starting_date: getDate(SEARCH_PARAMS.REGISTER_START),
        registered_ending_date: getDate(SEARCH_PARAMS.REGISTER_END),
        shoote_date_type: getValues(SEARCH_PARAMS.SHOOTE_DATE_TYPE),
        shoote_starting_date: getDate(SEARCH_PARAMS.SHOOTE_START),
        shoote_ending_date: getDate(SEARCH_PARAMS.SHOOTE_END),
        emergency_level_emergency: getValues(SEARCH_PARAMS.EMERGENCY),
        emergency_level_important: getValues(SEARCH_PARAMS.IMPORTANT),
        emergency_level_nomal: getValues(SEARCH_PARAMS.NOMAL),
        address: getValues(SEARCH_PARAMS.ADDRESS),
        overview: getValues(SEARCH_PARAMS.OVERVIEW),
        order: getValues(SEARCH_PARAMS.ORDER),
      });
    handleSearchDrawerClose();
  }

  const getDate = (name) => {
    let search_date = ''
    const date = getValues(name)
    if (date !== null && date !== undefined && date !== '') {
      search_date = Common.formatDate(getValues(name), 'YYYY-MM-DD')
    }
    return search_date
  }
  const onRegisteredDateChange = (event) => {
    console.log(event.target.value)
    setIsRegisteredDatePeriod(false)
    if (event.target.value === DATE_TYPE.PERIOD) {
      setIsRegisteredDatePeriod(true)
    }
    return
  }
  const onShooteDateChange = (event) => {
    console.log(event.target.value)
    setIsShooteDatePeriod(false)
    if (event.target.value === DATE_TYPE.PERIOD) {
      setIsShooteDatePeriod(true)
    }
    return
  }

  const handleSearchDrawerClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    // 現在の検索設定を反映
    setValue(SEARCH_PARAMS.ADDRESS, props.searchConditions.address)
    setValue(SEARCH_PARAMS.OVERVIEW, props.searchConditions.overview)
    setValue(SEARCH_PARAMS.ORDER, props.searchConditions.order)
    if (props.searchConditions.registered_date_type == DATE_TYPE.PERIOD) {
      setIsRegisteredDatePeriod(true)
    } else {
      setIsRegisteredDatePeriod(false)
    }
    if (props.searchConditions.shoote_date_type == DATE_TYPE.PERIOD) {
      setIsShooteDatePeriod(true)
    } else {
      setIsShooteDatePeriod(false)
    }
  }, [props.searchConditions]);

  const registeredDateForm = () => (
    <>
      <FormControl size="small">
        <TypographyGraySmall >投稿日</TypographyGraySmall>
        <Select
          sx={{ borderRadius: '2px' }}
          {...register(SEARCH_PARAMS.REGISTER_DATE_TYPE)}
          defaultValue={props.searchConditions.registered_date_type}
          onChange={onRegisteredDateChange}
        >
          <MenuItem value={DATE_TYPE.ALL}>{DATE_TYPE_STR.ALL}</MenuItem>
          <MenuItem value={DATE_TYPE.DAY}>{DATE_TYPE_STR.DAY}</MenuItem>
          <MenuItem value={DATE_TYPE.WEEK}>{DATE_TYPE_STR.WEEK}</MenuItem>
          <MenuItem value={DATE_TYPE.MONTH}>{DATE_TYPE_STR.MONTH}</MenuItem>
          <MenuItem value={DATE_TYPE.YEAR}>{DATE_TYPE_STR.YEAR}</MenuItem>
          <MenuItem value={DATE_TYPE.PERIOD}>{DATE_TYPE_STR.PERIOD}</MenuItem>
        </Select>
      </FormControl>
      {isRegisteredDatePeriod &&
        <FormDatePickerStartEnd
          startingDateName={SEARCH_PARAMS.REGISTER_START}
          endingDateName={SEARCH_PARAMS.REGISTER_END}
          control={control}
          startingDate=''
          endingDate=''
          startDateError={registeredStartDateError}
          endDateError={registeredEndDateError}
          setStartDateError={setRegisteredStartDateError}
          setEndDateError={setRegisteredEndDateError}
          setValue={setValue}
          getValues={getValues}
        />
      }
    </>
  )

  const shooteDateForm = () => (
    <>
      <FormControl size="small">
        <TypographyGraySmall >撮影日</TypographyGraySmall>
        <Select
          sx={{ borderRadius: '2px' }}
          {...register(SEARCH_PARAMS.SHOOTE_DATE_TYPE)}
          defaultValue={props.searchConditions.shoote_date_type}
          onChange={onShooteDateChange}
        >
          <MenuItem value={DATE_TYPE.ALL}>{DATE_TYPE_STR.ALL}</MenuItem>
          <MenuItem value={DATE_TYPE.DAY}>{DATE_TYPE_STR.DAY}</MenuItem>
          <MenuItem value={DATE_TYPE.WEEK}>{DATE_TYPE_STR.WEEK}</MenuItem>
          <MenuItem value={DATE_TYPE.MONTH}>{DATE_TYPE_STR.MONTH}</MenuItem>
          <MenuItem value={DATE_TYPE.YEAR}>{DATE_TYPE_STR.YEAR}</MenuItem>
          <MenuItem value={DATE_TYPE.PERIOD}>{DATE_TYPE_STR.PERIOD}</MenuItem>
        </Select>
      </FormControl>
      {isShooteDatePeriod &&
        <FormDatePickerStartEnd
          startingDateName={SEARCH_PARAMS.SHOOTE_START}
          endingDateName={SEARCH_PARAMS.SHOOTE_END}
          control={control}
          startingDate=''
          endingDate=''
          startDateError={shooteStartDateError}
          endDateError={shooteEndDateError}
          setStartDateError={setShooteStartDateError}
          setEndDateError={setShooteEndDateError}
          setValue={setValue}
          getValues={getValues}
        />
      }
    </>
  )

  const searchPanel = () => (
    <>
      <FormControl>
        <TypographyGraySmall >重要度</TypographyGraySmall>
        <FormGroup>
          <FormControlLabel
            control={
              <CheckBoxSearch
                {...register(SEARCH_PARAMS.EMERGENCY)}
                defaultChecked={props.searchConditions.emergency_level_emergency}
              />}
            label={<TypographyNomal>緊急</TypographyNomal>} />
          <FormControlLabel
            control={
              <CheckBoxSearch
                {...register(SEARCH_PARAMS.IMPORTANT)}
                defaultChecked={props.searchConditions.emergency_level_important}
              />}
            label={<TypographyNomal>重要</TypographyNomal>} />
          <FormControlLabel
            control={
              <CheckBoxSearch
                {...register(SEARCH_PARAMS.NOMAL)}
                defaultChecked={props.searchConditions.emergency_level_nomal}
              />}
            label={<TypographyNomal>提供</TypographyNomal>} />
        </FormGroup>
      </FormControl>
      <FormControl>
        <TypographyGraySmall>住所</TypographyGraySmall>
        <InputText size="small"
          {...register(SEARCH_PARAMS.ADDRESS, ADDRESS_SEARCH)}
          defaultValue={props.searchConditions.address}
          id="outlined-basic" variant="outlined" 
          helperText={errors.address?.message}
          error={errors.address?.message.length > 0}
          />
      </FormControl>
      <FormControl>
        <TypographyGraySmall>概要</TypographyGraySmall>
        <InputText size="small"
          {...register(SEARCH_PARAMS.OVERVIEW, OVERVIEW_SEARCH)}
          defaultValue={props.searchConditions.overview}
          id="outlined-basic" variant="outlined" 
          helperText={errors.overview?.message}
          error={errors.overview?.message.length > 0}
          />
      </FormControl>
      <Controller
        name={SEARCH_PARAMS.ORDER}
        control={control}
        // rules={validationRules.gender}
        render={({ field, fieldState }) => (
          <FormControl >
            <TypographyGraySmall >並び替え</TypographyGraySmall>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              value={field.value}
              defaultValue={props.searchConditions.order}>
              <FormControlLabel {...field}
                value={ORDER_TYPE.SHOOTE_DATE_NEW}
                control={<RadioSearch />}
                label={<TypographyNomal>{ORDER_TYPE_STR.SHOOTE_DATE_NEW}</TypographyNomal>} />
              <FormControlLabel {...field}
                value={ORDER_TYPE.SHOOTE_DATE_OLD}
                control={<RadioSearch />}
                label={<TypographyNomal>{ORDER_TYPE_STR.SHOOTE_DATE_OLD}</TypographyNomal>} />
              <FormControlLabel {...field}
                value={ORDER_TYPE.REGISTER_DATE_NEW}
                control={<RadioSearch />}
                label={<TypographyNomal>{ORDER_TYPE_STR.REGISTER_DATE_NEW}</TypographyNomal>} />
              <FormControlLabel {...field}
                value={ORDER_TYPE.REGISTER_DATE_OLD}
                control={<RadioSearch />}
                label={<TypographyNomal>{ORDER_TYPE_STR.REGISTER_DATE_OLD}</TypographyNomal>} />
            </RadioGroup>
            {/* <FormHelperText>{fieldState.error?.message}</FormHelperText> */}
          </FormControl>
        )}
      />
    </>
  );
  const searchButton = () => (
    <BoxFlexEndTopMarginBR sx={{ height: 100 }}>
      <Button variant="contained"
        // type="submit"
        sx={{
          backgroundColor: '#2c3e50',
          '&:hover': {
            backgroundColor: '#435e7a'
          }
        }}
        onClick={() => onSearch()}
      >検索</Button>
    </BoxFlexEndTopMarginBR>
  );

  const mobileSearchPanel = () => (
    <form >
      <Stack spacing={2} sx={{ m: 2 }}>
        {registeredDateForm()}
        {shooteDateForm()}
        {searchPanel()}
      </Stack>
      {searchButton()}
    </form>
  );

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          top: '48px'
        },
      }}
      anchor='right'
      open={props.openSearch}
      onClose={handleSearchDrawerClose}
    // onOpen={props.setOpen(true)}
    >
      {mobileSearchPanel()}
    </Drawer>
  );
}
export default SearchDrawer;
