// import LOGO_EDISON from '../_img/logo_edison.png'
// import LOGO_PREIN from '../_img/logo_prein.png'
import {URL_BASE, AWS_S3_BUCKET_NAME, DEFAULT_LAT, DEFAULT_LNG} from './config'

export const URL = {
  AUTH_BASE: URL_BASE + 'dj-rest-auth/',
  API_BASE: URL_BASE + 'api/v1/',
  CONTENTS_BASE: URL_BASE + 'contents/',
  LAS_BASE: URL_BASE + 'contents/las/potree_html/',
  CHAPTER_BASE: URL_BASE + 'contents/chapter/processed_chapter_dir/',
  LOGIN: 'login/',
  LOGOUT: 'logout/',
  USER: 'users/',
  USER_INFO: 'user_info/',
  ALL_CONTENTS_LIST: 'all_contents_list/',
  TAGS: 'tags/',
  SURVEY: 'survey/',
  DELETE_SURVEY: 'delete_survey/',
  DELETE_TAG: 'delete_tag/',
  DELETE_USER: 'delete_user/',
  DELETE_CONTENTS: 'delete_contents/',
  ACTIVE_TAGS: 'active_tags/',
  ACTIVE_SURVEY: 'active_survey/',
  DOWNLOAD: 'contents_download/',
  SPECIFY_UPLOAD: URL_BASE + 'upload/movie/?survey=',
}

// AWS
export const AWS_INFO = {
  REGION: 'ap-northeast-1',
  S3_BUCKET_NAME: AWS_S3_BUCKET_NAME,

}

export const S3_BUCKET_KEY = {
  MOVIE_HLS: 'contents/movie/hls/',
  MOVIE_ORIGINAL: 'contents/movie/original/',
  MOVIE_THUMBNAIL: 'contents/movie/thumbnail/',
  MOVIE_DOWNLOAD: 'contents/movie/download/',
  MOVIE_ROUTE_LOG: 'contents/movie/route_log/',
  IMAGE_ORIGINAL: 'contents/image/original/',
  IMAGE_COPYRIGHT: 'contents/image/copyright/',
  IMAGE_THUMBNAIL: 'contents/image/thumbnail/',
  ZIP_ORIGINAL: 'contents/zip/original/',
  ZIP_COPYRIGHT: 'contents/zip/copyright/',
  ZIP_THUMBNAIL: 'contents/zip/thumbnail/',
}
export const COOKIES_KEY = {
  ACCESS_TOKEN: 'accesstoken',
  REFRESH_TOKEN: 'refreshtoken',
  ID: 'id',
  LAT: 'user_latitude',
  LNG: 'user_longitude',
  ROLE: 'role',
  PREF_CONTENTS: 'pref_contents',
}
export const ROLE = {
  IS_ADMIN: 3,
  IS_POSTMAN: 2,
  IS_STAFF: 1,
}

export const DEFAULT_POSITION = {
  LAT: DEFAULT_LAT,
  LNG: DEFAULT_LNG,
}

export const DEFAULT_MAP_ZOOM = 10

export const PARAM_NAME = {
  LAT: 'latitude',
  LNG: 'longitude',
  EMERGENCY_LEVEL: 'emergency_level',
  IS_PARMIT_3D: 'is_permit3d'
}
export const EMERGENCY_LEVEL = {
  EMERGENCY: 0,
  IMPORTANT: 1,
  NORMAL: 2,
}
export const CONTENTS_TYPE = {
  NAME: 'contents_type',
  MOVIE: 0,
  IMAGE: 1,
  ZIP: 2,
}
export const UPLOAD_STATUS = {
  CHUNCK_PUT: 1,
  FINAL_POST: 2,
  INFO_SAVE: 3,
}
export const UPLOAD_FILE = {
  CHUNK_SIZE: 1024 * 1024 * 64,
  SUM_MAX: 1024 * 1024 * 1024 * 3,
  MAX_FILES_SIZE_SUM: 1024 * 1024 * 1024 * 10,
  MAX_FILES_COUNT: 10,
  CHUNK_PROMISE_COUNT: 5,
}
export const CONTENTS_URL = {
  MOVIE_HLS: URL.CONTENTS_BASE + 'movie/hls/',
  MOVIE_ORIGINAL: URL.CONTENTS_BASE + 'movie/original/',
  MOVIE_THUMBNAIL: URL.CONTENTS_BASE + 'movie/thumbnail/',
  MOVIE_COPYRIGHT: URL.CONTENTS_BASE + 'movie/copyright/',
  MOVIE_DOWNLOAD: URL.CONTENTS_BASE + 'movie/download/',
  IMAGE_ORIGINAL: URL.CONTENTS_BASE + 'image/original/',
  IMAGE_COPYRIGHT: URL.CONTENTS_BASE + 'image/copyright/',
  IMAGE_THUMBNAIL: URL.CONTENTS_BASE + 'image/thumbnail/',
  ZIP_ORIGINAL: URL.CONTENTS_BASE + 'zip/original/',
  ZIP_COPYRIGHT: URL.CONTENTS_BASE + 'zip/copyright/',
  ZIP_THUMBNAIL: URL.CONTENTS_BASE + 'zip/thumbnail/',
}
export const PAGE_TITLE = {
  ADMIN_CONTENTS: 'アップロード管理',
  SETTING_CONTENTS: 'アップロードファイル 一覧',
  NEW_USER: '新規ユーザー作成',
  ADMIN_USER: 'ユーザー 一覧',
  SETTING_USER: 'ユーザー情報更新',
  SURVEY: '調査登録画面',
  TAG: 'タグ登録画面',
  FAILED_TO_LOAD: 'ページの読み込みに失敗しました。',
  NOT_FOUND: 'このページは存在しません。',
}
export const PAGE_WIDTH = {
  SM: 'sm',
  MD: 'md',

}

export const AXIOS_TIMEOUT = {
  REGULAR: 5 * 60 * 1000,
  THREE_MINUTE: 3 * 60 * 1000,
}
export const GENERATE_3D_STATUS = {
  NOT: 0,
  PROCESS: 1,
  IMPOSSIBLE: 2,
  DONE: 3,
}

export const MOVIE_THUMB_MAX_LENGTH = 4

