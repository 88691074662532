import * as React from 'react';
import { Grid, Stack } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import ContentsImage from '../contents_image'
import TagList from '../tag_list'
import AvatarSmallIcon from '../../atoms/avatar_small_icon'

import { BoxFlexStartTopStaticHeight, StackMargin10 } from '../../style/box'
import { TypographyNomal, TypographyGraySmall } from '../../style/typography'
import { ListImageSmall, } from '../../style/image'

import { ICON_INDEX } from '../../const/risk_style'
import { GENERATE_3D_STATUS, CONTENTS_TYPE } from '../../const/general'

import Common from '../../functions/common';
import getContentsPath from '../../functions/get_contents_path';
import hazardIconIndex from '../../functions/get_hazard_icon';

export default function ContentsList(props) {
    const date_time = Common.formatDateTime(new Date(props.row.shoote_date), 'YYYY年MM月DD日 HH時mm分');
    const thumbnail = getContentsPath(props.row.contents_type, props.row.contents_uuid, props.row.file_name, props.row.is_copyright)
    let style = {}
    if (props.selectContentUUID === props.row.contents_uuid) {
        style = {
            width: '100%',
            backgroundColor: '#eaffff'
        }
    } else {
        style = {
            width: '100%',
            "&:hover": {
                backgroundColor: 'aliceblue'
            }
        }
    }

    // 最大リスクアイコン取得
    const icon = hazardIconIndex(props.row.hazard, props.row.max_risk_level);
    return (
        <Grid item xs={6} sm={6} md={6} sx={style}>
            <Stack sx={{
                marginLeft: '15px',
                marginBottom: '15px',
                marginTop: '10px'
            }}>
                <BoxFlexStartTopStaticHeight>
                    <Stack spacing={1}>
                        <AvatarSmallIcon iconType={props.row.emergency_level} />
                        {icon !== null &&
                            <AvatarSmallIcon iconType={icon} />
                        }
                        {props.row.generate_3d_status === GENERATE_3D_STATUS.DONE &&
                            <AvatarSmallIcon iconType={ICON_INDEX.THREE_D} />
                        }
                    </Stack>
                    <ListImageSmall
                        srcSet={thumbnail}
                        loading="lazy"
                        onClick={() => props.handleClick(props.row)} />
                    {props.row.contents_type === CONTENTS_TYPE.MOVIE &&
                        <PlayCircleOutlineIcon fontSize="large" style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            left: '48%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: '#fff'
                        }}
                            onClick={() => props.handleClick(props.row)} />}

                </BoxFlexStartTopStaticHeight>
                <Stack sx={{ paddingLeft: '5%', paddingTop: '2px' }}>
                    <TypographyNomal>{props.row.title}</TypographyNomal>
                    <TagList tags={props.row.tags} />
                    <TypographyGraySmall>{date_time}</TypographyGraySmall>
                </Stack>
            </Stack>
        </Grid>
    )
}
