import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components'
import media from "styled-media-query";
import axAuth from '../../functions/axios_auth';
import cliAuth from '../../functions/axios_cli';
import { URL, COOKIES_KEY,ROLE, SIGNATURE_CONTENTS } from '../../const/general'
import { LabelWrapper } from '../../const/style';

import Cookies from 'js-cookie';

import CenterSubmit from '../../items/center_submit';
import TextLabel from '../../atoms/text_label'
import ErrorMsg from '../../atoms/error_msg'

const Wrapper = styled.main`
min-height: calc(100vh - 81px);
width: 100vw;
background-color: #fff;
position: relative;
padding-top: 48px;
`
const Container = styled.div`
  ${media.greaterThan("large")`
    width: 30vw;
  `}
  ${media.between("medium", "large")`
    width: 40vw;
  `}
  ${media.lessThan("medium")`
    width: 70vw;
  `}
  ${media.lessThan('small')`
    width: 90vw;
  `}
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const TitleWrapper = styled.div`
font-weight: bold;
padding: 10px;
font-size: 1.4rem;
border-bottom: 1px solid rgba(0, 0, 0, 0.2);
text-align: left;
`

const BodyWrapper = styled.div`
padding: 10px;
`
const InputWrapper = styled.input`
display: inline-block;
width: 95%;
height: 30px;
line-height: 30px;
border: 1px solid rgba(0, 0, 0, 0.2);
`
function LoginBody() {

  const [searchParams] = useSearchParams();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const [lastPage, setLastPage] = useState('/');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const path = searchParams.get("lastpage")
    if(path !== null && path !== '' ){
      setLastPage(path)
    }else{
      setLastPage('/')
    }
  }, [searchParams.get("lastpage")]);
  
  const onSubmit = async data => {
    // console.log(data)
    await axAuth.post(URL.LOGIN, data)
      .then(response => {
        // 送信成功時の処理
        console.log(URL.LOGIN + ' post success', response.data)
        Cookies.set(COOKIES_KEY.ACCESS_TOKEN, response.data.access_token)
        Cookies.set(COOKIES_KEY.REFRESH_TOKEN, response.data.refresh_token)
        Cookies.set(COOKIES_KEY.ID, response.data.user.pk)

        // Cookiesに保存するためユーザー情報を取得
        cliAuth.get(`${URL.USER}${Cookies.get(COOKIES_KEY.ID)}/`)
        .then(response => {
          // TODO 多県対応で可変になる予定なので、正式対応されたら
          // 固定値ではなく、ログインユーザーに対応した値を渡します。
          cliAuth.get(
            `info/`
        ).then(response => {
            Cookies.set(COOKIES_KEY.PREF_CONTENTS, response.data[0].key3)
          }).catch(error => {
            console.log(error)
        })

          // console.log(URL.USER + ' get success', response.data)
          Cookies.set(COOKIES_KEY.LAT, response.data.latitude)
          Cookies.set(COOKIES_KEY.LNG, response.data.longitude)
          if(response.data.is_admin){
            Cookies.set(COOKIES_KEY.ROLE, ROLE.IS_ADMIN)
          }else if (response.data.is_postman){
            Cookies.set(COOKIES_KEY.ROLE, ROLE.IS_POSTMAN)
          }else if (response.data.is_staff){
            Cookies.set(COOKIES_KEY.ROLE, ROLE.IS_STAFF)
          }
          // ホーム画面に遷移
          navigate(lastPage)
        })
        .catch(err => {
          setErrorMessage('ユーザーID・パスワードに誤りがあるか、登録されていません。')
          console.log(err);
        });
      })
      .catch(err => {
        setErrorMessage('ユーザーID・パスワードに誤りがあるか、登録されていません。')
        console.log(err);
      });
  };

  return (

    <Wrapper>
      <Container>
        <TitleWrapper>ログイン</TitleWrapper>
        <BodyWrapper>
          {errorMessage && <ErrorMsg message={errorMessage} />}
          <form onSubmit={handleSubmit(onSubmit)}>
            <LabelWrapper>
              <TextLabel text="メールアドレス" />
              <InputWrapper type="text"
                {...register('email',
                  { required: 'メールアドレスを入力してください' })}
                placeholder="メールアドレスを入力してください"
              />
            </LabelWrapper>
            {errors.email && <ErrorMsg message={errors.email.message} />}

            <LabelWrapper>
              <TextLabel text="パスワード" />
              <InputWrapper type="password"
                {...register('password',
                  { required: 'パスワードを入力してください' })}
                placeholder="パスワードを入力してください"
              />
            </LabelWrapper>
            {errors.password && <ErrorMsg message={errors.password.message} />}
            <CenterSubmit width={100} text={'ログイン'} />
          </form>
        </BodyWrapper>
      </Container>
    </Wrapper>
  )
}

export default LoginBody;
