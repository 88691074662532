export default async function getMediaInfo(src) {
    let info = { width: 0, height: 0, play_time: 0 }
    if (!src) {
      return info
    }
    const media = await loadVideoFromPath(src).catch() // 動画の場合
    if (media !== undefined) {
      console.log(media.duration)
      info = { 
        width: media.videoWidth, 
        height: media.videoHeight, 
        play_time: Math.round(media.duration) 
      } 
    }
    return info
  }
  const loadVideoFromPath = (src) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.onloadedmetadata = () => resolve(video)
      video.onerror = (e) => reject(e)
      video.src = src
      video.load()
    })
  }
