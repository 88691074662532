import * as React from 'react';
import ImageListItemBar from '@mui/material/ImageListItemBar';

export default function MovieChapterTime(props) {
  return (
    <ImageListItemBar
      sx={{
        cursor: 'pointer', 
        width:'auto',
        height: 'auto',
        fontSize: '0.8rem',
        left: "unset",
        "& .MuiImageListItemBar-title": { fontWeight: "bold", fontSize: '0.8rem', lineHeight: '10px' }, 
        "& .MuiImageListItemBar-titleWrap": { padding:'2px 2px' }, 
      }}
      title={props.item.str_time}
      position="top"
      onClick={() => {props.jumpToChapter(props.item.start_time)}}
      />
  );
}
