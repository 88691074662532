import React, { useState } from 'react'
import {
    IconButton,
    TableCell,
    Tooltip,
} from "@mui/material";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    CONTENTS_TYPE,
    S3_BUCKET_KEY,
    AWS_INFO
} from '../../const/general'
import cliAuth from '../../functions/axios_cli';
import { BoxSpaceAround } from '../../style/box'
import ConfirmDialog from '../confirm_dialog'
import ProgressModal from '../progress_modal'
import { saveAs } from 'file-saver';
import Common from '../../functions/common';
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { Credentials } from 'aws-sdk';

export default function TableRowButton(props) {

    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [progressMsg, setProgressMsg] = useState('');

    const handleCancel = () => {
        setDownloadDialogOpen(false);
    };

    //ダウンロードボタンクリック時
    const imageDownload = async () => {

        setDownloadDialogOpen(false)
        setIsOpen(true)
        setProgressMsg('ダウンロード')

        let info1 = ''
        let info2 = ''
        await cliAuth.get(
            `info/`
        ).then(response => {
            info1 = response.data[0].key1
            info2 = response.data[0].key2
            const client = new S3Client({
                region: AWS_INFO.REGION,
                credentials: new Credentials(info1, info2)
            });
            download(client)
            
        }).catch(error => {
            console.log(error)
        })
    };

    const download = async (client) => {
        let key = ''
        let ext = ''
        if (props.contents_type === CONTENTS_TYPE.IMAGE) {
            key = S3_BUCKET_KEY.IMAGE_COPYRIGHT + props.file_name
            ext = props.file_name.split('.').pop()
        }
        if (props.contents_type === CONTENTS_TYPE.MOVIE) {
            key = S3_BUCKET_KEY.MOVIE_DOWNLOAD + props.contents_uuid + '.mp4'
            ext = "mp4"
        }

        const command = new GetObjectCommand({
            Bucket: AWS_INFO.S3_BUCKET_NAME,
            Key: key,
        });

        try {
            const response = await client.send(command);
            if (response.Body) {
                const blob = new Blob([await response.Body.transformToByteArray()]);
                const date_time = Common.formatDateTime(new Date(), 'YYYYMMDD_HHmmdd');
                saveAs(blob, `${props.title}_${date_time}_download.${ext}`);
            }
            setIsOpen(false)
            setProgressMsg('')

        } catch (err) {
            console.error(err);
            setIsOpen(false)
            setProgressMsg('')
        }
    };

    return (
        <>
            <TableCell
                sx={{
                    maxWidth: { xs: '10vw', sm: '20%', md: '15%' },
                    padding: { xs: '5px', ms: '8px', sm: '16px' },
                    borderBottom: 'none'
                }}
            >
                <IconButton color="default" onClick={() => props.setOpen(!props.open)}>
                    <ModeEditOutlinedIcon />
                </IconButton>
            </TableCell>
            {/* ダウンロード可能かを判定 */}
            {props.hasDownload &&
                <TableCell
                    sx={{
                        maxWidth: { xs: '10vw', sm: '20%', md: '15%' },
                        padding: { xs: '5px', ms: '8px', sm: '16px' },
                        borderBottom: 'none'
                    }}
                >
                    {props.contents_type === CONTENTS_TYPE.IMAGE &&
                        <IconButton color="default" disabled={!props.is_copyright}
                            onClick={() => {
                                setDownloadDialogOpen(true)
                            }}
                        >
                            <Tooltip title='画像をダウンロードします' placement="right-end">
                                <BoxSpaceAround>
                                    <DownloadIcon />
                                    <CheckCircleIcon />
                                </BoxSpaceAround>
                            </Tooltip>
                        </IconButton>
                    }
                    {props.contents_type === CONTENTS_TYPE.ZIP &&
                        <IconButton color="default">
                            <Tooltip title='画像群はダウンロードできません' placement="right-end">
                                <BoxSpaceAround>
                                    <FileDownloadOffIcon />
                                </BoxSpaceAround>
                            </Tooltip>
                        </IconButton>
                    }
                    {props.contents_type === CONTENTS_TYPE.MOVIE && props.movie_download_status === 2 &&
                        <IconButton color="default" disabled={!props.is_copyright}
                            onClick={() => {
                                setDownloadDialogOpen(true)
                            }}

                        >
                            <Tooltip title='動画をダウンロードします' placement="right-end">
                                <BoxSpaceAround>
                                    <DownloadIcon />
                                    <CheckCircleIcon />
                                </BoxSpaceAround>
                            </Tooltip>

                        </IconButton>
                    }
                    {props.contents_type === CONTENTS_TYPE.MOVIE && props.movie_download_status === 1 &&
                        <Tooltip title='ダウンロード準備中です。しばらくお待ちください。' placement="right-end">
                            <BoxSpaceAround>
                                <IconButton color="default" disabled={true}>
                                    <DownloadIcon />
                                </IconButton>
                            </BoxSpaceAround>
                        </Tooltip>
                    }
                    {props.contents_type === CONTENTS_TYPE.MOVIE && props.movie_download_status === 0 &&
                        <IconButton color="default" disabled={!props.is_copyright}
                            onClick={() => {
                                props.setSingleDownloadDialogOpen(true)
                                props.setTargetId(props.id)
                                props.setTargetTitle(props.title)
                            }}
                        >
                            <Tooltip title='ダウンロード準備します' placement="right-end">
                                <BoxSpaceAround>
                                    <DownloadIcon />
                                </BoxSpaceAround>
                            </Tooltip>
                        </IconButton>
                    }
                </TableCell>
            }
            <TableCell
                sx={{
                    maxWidth: { xs: '10vw', sm: '20%', md: '15%' },
                    padding: { xs: '5px', sm: '8px', md: '16px' },
                    borderBottom: 'none'
                }}>
                <IconButton color="default" onClick={() => {
                    props.setOpen(false)
                    props.setDeleteConfirmOpen(true)
                    props.setTargetId(props.id)
                    props.setTargetTitle(props.title)
                }}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </TableCell>
            <ConfirmDialog
                confirmOpen={downloadDialogOpen}
                setConfirmOpen={setDownloadDialogOpen}
                dialogTitle='ダウンロード'
                dialogMessage1={`「${props.title}」をダウンロードします。よろしいですか？`}
                handleAction={imageDownload}
                handleCancel={handleCancel}
                buttonName={'ダウンロード'}
            />
            <ProgressModal
                modalIsOpen={modalIsOpen}
                isFinal={true}
                progress={0}
                text1={progressMsg + '処理中です...'}
                text2={'この処理は数分かかる場合があります...'}
            />
        </>
    )
}