import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Grid,
  Checkbox,
  TextField,
  FormControlLabel,
  Stack,
} from "@mui/material";

import InputField from '../../atoms/input_field';
import InputOverview from '../../atoms/input_overview';
import Checkbox3D from '../../atoms/checkbox_3d';
import SelectLatlon from '../../items/select_latlon'
import SelectLevel from '../../items/select_level'
import SelectTags from '../../items/select_tags'
import CancelSubmit from '../../items/cancel_submit';

import { PARAM_NAME, CONTENTS_TYPE } from '../../const/general';
import { TITLE, OVERVIEW } from '../../const/roules';
import { BoxFlexStart } from '../../style/box';
import { Image, } from '../../style/image'

import getAddress from '../../functions/get_address';
import getMGRS from '../../functions/get_mgrs';
import getElevation from '../../functions/get_elevation';
import PatchData from '../../functions/patch_data';
import SendForHana from '../../functions/send_for_hana';


function MovieInfoForm(props) {
  console.log(props.assignTags)
  const { register, setValue, getValues, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' });
  const [isPublic, setIsPublic] = useState(props.is_public);
  const [isPermit3d, setIsPermit3d] = useState(props.is_permit3d);
  const [selectTagList, setSelectTagList] = useState(
    props.assignTags.map((assignTag) =>
      ({ tag_uuid: assignTag.tag_uuid, tag_names: assignTag.tag_names })));

  // 更新成功時の処理
  const onSuccess = async (response, proc, setSuccessMessage) => {
    props.setOpen(false)
    props.reload()
    // HANAにデータ連携
    SendForHana()
    setSuccessMessage(`アップロードファイル：${response.data.title}を更新しました`)
  }

  // 更新失敗時の処理
  const onError = (err, proc, setErrorMessage) => {
    if (err.response === undefined) {
      setErrorMessage(`アップロードファイルの更新に失敗しました。`)
    } else {
      setErrorMessage(`アップロードファイルの更新に失敗しました。`)
    }
  }

  const onSubmit = async data => {
    data["latitude"] = parseFloat(getValues(PARAM_NAME.LAT) ?
      getValues(PARAM_NAME.LAT) : props.latitude)
    data["longitude"] = parseFloat(getValues(PARAM_NAME.LNG) ?
      getValues(PARAM_NAME.LNG) : props.longitude)
    data["emergency_level"] = getValues(PARAM_NAME.EMERGENCY_LEVEL)
    if (data.latitude === undefined || data.longitude === undefined
      || data.latitude === 0 || data.longitude === 0) {
      props.setSnackBarMessage('調査場所が選択されていません')
      props.setSnackBarStatus('error')
      props.setOpenSnackBar(true)
      return
    } else {
      // 住所を取得
      data["address"] = await getAddress(data.latitude, data.longitude)
      // UTMグリッドを取得
      data["utm_grid"] = await getMGRS(data.latitude, data.longitude)
      // 標高を取得
      data["elevation"] = await getElevation(data.latitude, data.longitude)
    }

    if (data.is_permit3d === undefined) {
      data.is_permit3d = false
    }
    data["tags"] = selectTagList.map(tag => tag.tag_uuid);

    // チェックボックスの値は明示的に渡さないと
    // iPhoneからの操作の際に値が反映されないため明示的に渡す
    data.is_public = isPublic
    data.is_permit3d = isPermit3d
    PatchData
      (
        data,
        'アップロードファイル',
        `contents_info/`,
        props.setSnackBarMessage,
        props.setSnackBarStatus,
        props.setOpenSnackBar,
        props.setSnackBarMessage,
        props.setSnackBarStatus,
        props.setOpenSnackBar,
        onSuccess,
        onError
      )
  }

  // キャンセル動作
  const onCancel = () => {
    props.setOpen(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}
          sx={{
            paddingRight: { xs: '0px', sm: '20px', md: '20px' },
            paddingLeft: { xs: '0px', sm: '20px', md: '20px' },
          }}>
          <Grid item xs={12} md={4}>
            <Image
              controls
              src={props.thumbnail}
              resizeMode={"contain"}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField sx={{ display: 'none' }}
              {...register('contents_uuid')}
              value={props.contents_uuid}
              hidden
            />
            <Stack spacing={2}>
              <InputField
                register={register('title', TITLE)}
                defaultValue={props.title}
                placeholder="タイトル"
                error={errors.title}
              />
              <SelectLatlon
                setValue={setValue}
                getValues={getValues}
                latitude={props.latitude}
                longitude={props.longitude}
                setValueNameLat={PARAM_NAME.LAT}
                setValueNameLng={PARAM_NAME.LNG}
                buttonName="調査場所を選択"
              />
              <InputOverview
                register={register('overview', OVERVIEW)}
                overview={props.overview}
                error={errors.overview}
              />
              <SelectTags
                tagList={props.activeTags}
                selectTagList={selectTagList}
                setSelectTagList={setSelectTagList}
              />
              <SelectLevel
                emergencyLevel={getValues(PARAM_NAME.EMERGENCY_LEVEL) ?
                  getValues(PARAM_NAME.EMERGENCY_LEVEL) : props.emergency_level}
                setValue={setValue}
                setValueName={PARAM_NAME.EMERGENCY_LEVEL}
              />
              <BoxFlexStart>
                {props.contentType !== CONTENTS_TYPE.IMAGE &&
                  <FormControlLabel control={
                    <Checkbox
                      {...register('is_permit3d')}
                      checked={isPermit3d}
                      onChange={() => {
                        setIsPermit3d(!isPermit3d)
                      }}
                    />} label="3Dを作成する" />
                }
                <FormControlLabel control={
                  <Checkbox
                    {...register('is_public')}
                    checked={isPublic}
                    onChange={() => {
                      setIsPublic(!isPublic)
                    }
                    }
                  />} label="公開設定" />
              </BoxFlexStart>
            </Stack>
          </Grid>
        </Grid>
        <CancelSubmit
          onCancel={onCancel}
          text={props.submitButtonName} />
      </form>
    </>
  )
}

export default MovieInfoForm;
