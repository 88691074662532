import React, { useState } from 'react'

import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import UserInfoForm from '../../../contents/admin_user_form'
import FailedToLoad from '../../failed_to_load'
import Proggress from '../../../items/progress'
import MessageBar from '../../../items/snackbar'
import DeleteDialog from '../../../items/delete_dialog'
import TableRowsHeader from '../../../items/table_row_header'
import { URL } from '../../../const/general'
import { GetUsers, LoadMutate } from '../../../functions/get_data_swr';

function User() {
  const [deleteUserId, setDeleteUserId] = useState('');
  const [deleteUserEmail, setDeleteUserEmail] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const reload = () => {
    LoadMutate(URL.USER)
  }

  function Row(props) {
    const [open, setOpen] = useState(false);
    return (
      <React.Fragment>
        <TableRowsHeader
          id={props.row.id}
          name={props.row.email}
          affiliation={props.row.affiliation}
          open={open}
          setOpen={setOpen}
          setDeleteConfirmOpen={setDeleteConfirmOpen}
          setDeleteId={setDeleteUserId}
          setDeleteName={setDeleteUserEmail}
        />
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <UserInfoForm
                id={props.row.id}
                email={props.row.email}
                affiliation={props.row.affiliation}
                latitude={props.row.latitude}
                longitude={props.row.longitude}
                is_admin={props.row.is_admin}
                is_staff={props.row.is_staff}
                is_postman={props.row.is_postman}
                setOpen={setOpen}
                reload={reload}
                setSnackBarMessage={setSnackBarMessage}
                setSnackBarStatus={setSnackBarStatus}
                setOpenSnackBar={setOpenSnackBar} />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const { usersList, isUsersLoading, isUsersError } = GetUsers();
  if (isUsersError) return <FailedToLoad />
  if (isUsersLoading) return <Proggress />
  console.log(usersList);
  return (
    <>
      <TableContainer >
        <Table >
          <TableBody>
            {usersList.map((row, id) => (
              <Row key={id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
      <DeleteDialog
        confirmOpen={deleteConfirmOpen}
        url={URL.DELETE_USER + deleteUserId + '/'}
        targetName={deleteUserEmail}
        dialogTitle={'ユーザー'}
        setConfirmOpen={setDeleteConfirmOpen}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar}
        reload={reload}
        setTargetFileId={setDeleteUserId}
        setTargetFileTitle={setDeleteUserEmail}
      />
    </>
  )
}

export default User;
