import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

export default function ArrowLeftIcon(props) {
  const handleBack = () => {
    props.setCurrentIndex((prevCurrentIndex) => prevCurrentIndex - 1);
};

  return (
    <IconButton
      color="default"
      sx={{
        padding: 0,
        "&.MuiButtonBase-root:hover": {
          bgcolor: "transparent"
        }
        // "&.MuiButtonBase-root": {
        //   width: "20px",
        //   height: "20px",
        //   margin:'5% 0 0 2px'
        // }
      }}
      onClick={handleBack}
      disabled={props.disabled}>
      <KeyboardArrowLeft />
    </IconButton>
  );
}
