import React from 'react'
import style from 'styled-components'
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import axAuth from '../../functions/axios_auth';
import { COOKIES_KEY, URL } from '../../const/general';
import { URL_BASE, LOGO_IMG } from '../../const/config'
import { ContainerSpaceBetween } from '../../const/style';
import SlideMenu from '../slide_menu';
import SearchDrawer from '../search_drawer';
import Cookies from 'js-cookie';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import media from "styled-media-query";

const drawerWidth = 250;

const Wrapper = style.header`
position:sticky;
top:0;
left:0;
z-index: 11;
`
const Logo = style.img`
width: 190px;
margin-top: -17px;
cursor: pointer;
${media.lessThan("small")`
margin-top: -5px;
`}
`
const SelectButton = style.input`
background-color: #061027;
cursor: pointer;
color: #fff;
border: none;
margin-top: -15px;
${media.lessThan("small")`
margin-top: -10px;
`}

`

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Header = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axAuth.post(URL.LOGOUT)
      .then(response => {
        // 送信成功時の処理
        console.log('post success', response)
        Cookies.remove(COOKIES_KEY.ACCESS_TOKEN)
        Cookies.remove(COOKIES_KEY.REFRESH_TOKEN)
        Cookies.remove(COOKIES_KEY.ID)
        Cookies.remove(COOKIES_KEY.LAT)
        Cookies.remove(COOKIES_KEY.LNG)
        Cookies.remove(COOKIES_KEY.ROLE)
        Cookies.remove(COOKIES_KEY.PREF_CONTENTS)


        const nowLocation = window.location.href
        const nowUrl = nowLocation.replace(URL_BASE, '')
        console.log(nowUrl)
        const depth = (String(nowUrl).match(new RegExp(String('/'), 'g')) || []).length
        console.log('depth' + depth);

        let path_depth = ''
        for (let i = 0; i < depth; i++) {
          path_depth += '../'
        }
        window.location = path_depth + URL.LOGIN
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleSearchDrawerOpen = () => {
    console.log('handleSearchDrawerOpen')
    setOpenSearch(true);
  };

  return (
    <Wrapper >
      <ContainerSpaceBetween>
        <AppBar position="static"
          sx={{ backgroundColor: '#061027', height: '48px' }}
          open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, mt: -2, ...(open && { display: 'none' }) }}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" component="div"
              sx={{ flexGrow: 1, lineHeight: { xs: 0 } }}>
              <Logo src={LOGO_IMG} onClick={() => navigate('/')}></Logo>
            </Typography>
            {props.isSearchPanel &&
              <IconButton
                size="large"
                onClick={handleSearchDrawerOpen}
                color="inherit"
                sx={{ ml: 2, mt: -2 }}
              >
                <SearchIcon />
              </IconButton>
            }
            <SelectButton
              type="button"
              onClick={(event) => handleSubmit(event)}
              value="ログアウト" />
          </Toolbar>
        </AppBar>

      </ContainerSpaceBetween>
      {props.isSearchPanel &&
        <SearchDrawer
          openSearch={openSearch}
          setOpen={setOpenSearch}
          searchConditions={props.searchConditions}
          setSearchConditions={props.setSearchConditions}
        />
      }
      <SlideMenu open={open} setOpen={setOpen} />
    </Wrapper>
  )
}
export default Header;