import React, { useState } from "react";

import {
  Alert,
  AlertTitle,
  Divider,
} from "@mui/material";

import SendMailForm from '../../../contents/send_mail_form';
import SelectSurvey from '../../../items/select_survey'
import FailedToLoad from '../../failed_to_load'
import Proggress from '../../../items/progress'

import { StackMargin10, BoxFlexEndTop } from '../../../style/box'

import {
  TypographyTitleLeft,
  TypographyNomal,
  TypographyBold,
  TypographyTitleCenter,
} from '../../../style/typography'

import { 
  GetActiveTags, 
  GetActiveSurveys, 
  GetUsers, 
  GetMailHistory, 
  LoadMutate 
} from '../../../functions/get_data_swr';

function SendUrl(props) {
  const [title, setTitle] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [address, setAddress] = useState('');
  const [overview, setOverview] = useState('');
  const [selectSurveyUUID, setSelectSurveyUUID] = useState('');

  const reload = () => {
    LoadMutate(URL.TAGS)
  }

  const { activeTags, isActiveTagsLoading, isActiveTagsError } = GetActiveTags();
  const { activeSurvey, isActiveSurveyLoading, isActiveSurveyError } = GetActiveSurveys();
  const { usersList, isUsersLoading, isUsersError } = GetUsers();
  // const { mailHistory, isMailHistoryLoading, isMailHistoryError } = GetMailHistory();
  // if (isMailHistoryError) return <FailedToLoad />
  // if (isMailHistoryLoading) return <Proggress />
  if (isUsersError) return <FailedToLoad />
  if (isUsersLoading) return <Proggress />
  if (isActiveTagsError) return <FailedToLoad />;
  if (isActiveSurveyError) return <FailedToLoad />;
  if (isActiveTagsLoading) return <Proggress />
  if (isActiveSurveyLoading) return <Proggress />

  // console.log(mailHistory)

  return (
    <>
      <StackMargin10>
        <TypographyTitleLeft>アップロード依頼メール送信</TypographyTitleLeft>
        <TypographyNomal>調査依頼用のアップロード画面URLを作成し、依頼先にメールを送信します。</TypographyNomal>
        <TypographyNomal>依頼対象の調査およびタグを選択してください。</TypographyNomal>
      </StackMargin10>
      <Divider />
      <StackMargin10 spacing={1}>
        <TypographyBold>調査一覧</TypographyBold>
        {activeSurvey !== null && activeSurvey !== undefined && activeSurvey.length !== 0 ?
          <SelectSurvey
            surveyList={activeSurvey}
            setTitle={setTitle}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setOverview={setOverview}
            setSelectSurveyUUID={setSelectSurveyUUID}
            setAddress={setAddress}
          />
          :
          <Alert sx={{ mb: 3 }} severity="warning">
            <AlertTitle>調査が登録されていません</AlertTitle>
            アップロード依頼URLを作成するためには、先に調査情報を登録してください
          </Alert>
        }
      </StackMargin10>
      <Divider />
      {selectSurveyUUID !== undefined &&
        selectSurveyUUID !== null && selectSurveyUUID !== '' &&
        <SendMailForm
          activeTags={activeTags}
          usersList={usersList}
          title={title}
          address={address}
          latitude={latitude}
          longitude={longitude}
          overview={overview}
          selectSurveyUUID={selectSurveyUUID}
          setSelectSurveyUUID={setSelectSurveyUUID}
          reload={reload}
        />
      }
      <Divider />
      {/* <TypographyTitleCenter >メール送信履歴</TypographyTitleCenter> */}

    </>
  )
}

export default SendUrl;