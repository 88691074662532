import { CONTENTS_TYPE, CONTENTS_URL } from '../const/general'
import ZipImg from '../_img/img_thumb_zip.png'
import NoImg from '../_img/img_no_image.png'

export default function getContentsPath(
    contentsType,
    contentsUUID,
    fileName,
    isThunbnail
) {

    let path = '';
    if (isThunbnail) {
        switch (contentsType) {
            case CONTENTS_TYPE.MOVIE:
                path = CONTENTS_URL.MOVIE_COPYRIGHT + contentsUUID + '.png';
                break
            case CONTENTS_TYPE.IMAGE:
                path = CONTENTS_URL.IMAGE_THUMBNAIL + fileName;
                break
            case CONTENTS_TYPE.ZIP:
                path = CONTENTS_URL.ZIP_THUMBNAIL + contentsUUID + '.png';
                break
            default:
                path = NoImg
                break
        }
    } else {
        switch (contentsType) {
            case CONTENTS_TYPE.MOVIE:
                path = CONTENTS_URL.MOVIE_THUMBNAIL + contentsUUID + '.png';
                break
            case CONTENTS_TYPE.IMAGE:
                path = CONTENTS_URL.IMAGE_ORIGINAL + fileName;
                break
            case CONTENTS_TYPE.ZIP:
                path = ZipImg
                break
            default:
                path = NoImg
                break
        }
    }
    return path
}
