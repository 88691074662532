import React from 'react'
import { Box, Grid, } from '@mui/material';
import Common from '../../functions/common';
import getContentsPath from '../../functions/get_contents_path';
import hazardIconIndex from '../../functions/get_hazard_icon';
import ContentsInfoBox from '../../atoms/contents_info_box'
import ContentsImageAffliation from '../../items/contents_image_affiliation'


// export default React.memo(function Grids(props) {
function Grids(props) {

  const date_time = Common.formatDateTime(new Date(props.row.shoote_date), 'YYYY年MM月DD日 HH時mm分');
  const thumbnail = getContentsPath(props.row.contents_type, props.row.contents_uuid, props.row.file_name, props.row.is_copyright)

  // 最大リスクアイコン取得
  const icon = hazardIconIndex(props.row.hazard, props.row.max_risk_level);

  return (
    <Grid item xs={12} sm={4} md={3} xl={2} >
      <Box
        sx={{
          height: { xs: '200px', sm: '125px', md: '185px', xl: '150px' },
          // height:'184px',
          position: 'relative'
        }}
      >
        <ContentsImageAffliation
          imgSrc={thumbnail}
          handleClick={props.handleClick}
          row={props.row}
        />
      </Box>
      <ContentsInfoBox
        row={props.row}
        icon={icon}
        date_time={date_time}
      />
    </Grid>
  )
}
export default Grids;
