import React from 'react'
import { SelectedLevelChip } from '../../style/chip';
import { LEVEL_ICONS } from '../../const/avater_icon';

export default function ChipDone(props) {

  return (
    <SelectedLevelChip
      label={LEVEL_ICONS[props.level].TEXT}
      icon={LEVEL_ICONS[props.level].ICON_TAG}
      level={props.level}
    />
  )
}
