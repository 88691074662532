import React, { useState } from 'react'
import Modal from "react-modal";

import UserInfoForm from '../../../contents/admin_new_user_form'
import MessageBar from '../../../items/snackbar'

Modal.setAppElement("#root");

function NewUserBody() {
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');

  return (
    <>
      <UserInfoForm
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar} />
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
    </>
  )
}

export default NewUserBody;
