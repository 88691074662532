import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
} from "@mui/material";

import ViewMap from '../../../contents/view_map'
import PlayerAndContentsList from '../../../contents/player_and_contents_list'
import cliAuth from '../../../functions/axios_cli';
import { getSearchParam } from '../../../functions/get_search_conditions'
import BottomNavi from '../../../items/bottom_navigation'
import ProgressModal from '../../../items/progress_modal'
import Proggress from '../../../items/progress'
import FailedToLoad from '../../failed_to_load'

import { setSelectContentInfo } from '../../../functions/select_content_info';
import { MobileDispFlex, PcDispFlex, MainWrapper } from '../../../const/style'
import { GetMapContents, LoadMutate } from '../../../functions/get_data_swr';


function MovieMap(props) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [selectContentUUID, setSelectContentUUID] = useState('');
  const [selectImageURL, setSelectImageURL] = useState('');
  const [selectContentSources, setSelectContentSources] = useState([]);
  const [selectContentChapters, setSelectContentChapters] = useState([]);
  const [selectContent, setSelectContent] = useState({});
  const [contents, setContents] = useState([]);
  const [value, setValue] = useState(1);

  const handleClick = (content) => {
    console.log(content.contents_uuid)
    setSelectContentUUID(content.contents_uuid)
    navigate(`/view/map?contents_uuid=${content.contents_uuid}`, { replace: false })
    //LoadMutate(url)
  }
  const selectUUID = searchParams.get("contents_uuid")

  // 検索条件取得
  let param_conditions = ''
  param_conditions = getSearchParam('?', props.searchConditions)
  console.log(param_conditions)

  // タグ条件取得
  let param_tag = ''
  if (props.selectTag.tag_uuid !== '') {
    param_tag = `&tag_uuid=${props.selectTag.tag_uuid}`
  }
  // url作成
  const url = 'search_contents/' + param_conditions + param_tag
  const { mapContentsData, isMapContentsLoading, isMapContentsError } = GetMapContents(url);

  // Loading表示
  if (isMapContentsLoading) return <Proggress />

  // エラー時の表示
  if (isMapContentsError){
    return failed()
  }

  // 検索結果０件の場合、エラー表示
  console.log(mapContentsData);
  if (mapContentsData.length < 1){
    return failed()

  }

  let selected = mapContentsData[0]
  if (selectUUID !== null && selectUUID !== '') {
    selected = mapContentsData.find(item => item.contents_uuid === selectUUID);
    console.log(selected)
    // 選択コンテンツが検索リストにない場合、エラー表示
    if (selected === null || selected === undefined){
      return failed()
    }
  }

  // エラー時の表示
  function failed(){
    return (<Box sx={{
      minHeight: 'calc(100vh - 48px - 40px - 30px)',
      marginLeft: { xs: '0px', sm: '66px' },
      paddingTop: { xs: '10px', sm: '30px' },
    }} >
      <FailedToLoad />
    </Box>)
  }

  // データ取得時の表示
  props.setContentsCount(mapContentsData.length)

  return (
    <>
      {/* PC画面用 */}
      <PcDispFlex>
        <Grid container>
          <Grid item md={6}>
            <PlayerAndContentsList
              contents={mapContentsData}
              selectContent={selected}
              handleClick={handleClick}
              contentsCount={props.contentsCount}
              searchConditions={props.searchConditions}
              selectTag={props.selectTag}
            />
          </Grid>
          <Grid item md={6}>
            <ViewMap
              fullscreen={true}
              contents={mapContentsData}
              selectContent={selected}
              handleClick={handleClick}
              mapHeight='100%'
            />
          </Grid>
        </Grid>
      </PcDispFlex>
      {/* スマホ・タブレット画面用 */}
      <MobileDispFlex>
        {value === 1 &&
          <Grid container>
            <Grid item xs={12} >
              <PlayerAndContentsList
                contents={mapContentsData}
                selectContent={selected}
                handleClick={handleClick}
                contentsCount={props.contentsCount}
                searchConditions={props.searchConditions}
                selectTag={props.selectTag}
              />
            </Grid>
          </Grid>

        }
        {value === 2 &&

          <MainWrapper>
            <ViewMap
              fullscreen={false}
              contents={mapContentsData}
              selectContent={selected}
              handleClick={handleClick}
              mapHeight='calc(100vh - 89px)'
            />
          </MainWrapper>
        }
        <BottomNavi
          value={value}
          setValue={setValue}
          navigate={navigate}
        />
      </MobileDispFlex>
    </>
  )
}


// }

export default MovieMap;
