import * as React from 'react';

import { HomeImageListItemBar } from '../../style/image'
import ContentsImage from '../contents_image_play_button'

export default function ContentsImageAffliation(props) {

    return (
        <>
            <ContentsImage
                imgSrc={props.imgSrc}
                handleClick={props.handleClick}
                row={props.row} />
            <HomeImageListItemBar
                title={props.row.affiliation}
                onClick={() => props.handleClick(props.row)} />
        </>
    )
}
