import React, { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Stack } from "@mui/material";

import CancelSubmit from '../../items/cancel_submit';
import Email from '../../items/user_email';
import Affiliation from '../../items/user_affiliation';
import PasswordPair from '../../items/user_password_pair';
import RoleCheck from '../../items/user_role_check';
import MessageBar from '../../items/snackbar'

import { PARAM_NAME } from '../../const/general'
import { AFFILIATION, PASSWORD, PASSWORD_REPEAT } from '../../const/roules';

import patchUser from '../../functions/patch_user';

function UserInfoForm(props) {
  const { control, register, handleSubmit, setValue, getValues, formState: { errors }, watch } = useForm({ mode: 'onBlur' });

  const [isAdmin, setIsAdmin] = useState(props.is_admin);
  const [isStaff, setIsStaff] = useState(props.is_staff);
  const [isPostman, setIsPostman] = useState(props.is_postman);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');

  const password = useRef({});
  password.current = watch("password", "");
  const email = useRef({});
  email.current = watch("email", props.email);

  const onSubmit = async data => {

    // チェックボックスの値は明示的に渡さないと
    // iPhoneからの操作の際に値が反映されないため明示的に渡す
    data.is_admin=isAdmin
    data.is_staff=isStaff
    data.is_postman=isPostman

    if (!data.is_admin && !data.is_staff && !data.is_postman) {
      setSnackBarMessage('開発者、投稿者、閲覧者のいずれかを選択してください')
      setSnackBarStatus('error')
      setOpenSnackBar(true)
      return
    }
    if (await patchUser(
      data,
      props.setSnackBarMessage,
      props.setSnackBarStatus,
      props.setOpenSnackBar,
      setSnackBarMessage,
      setSnackBarStatus,
      setOpenSnackBar,
      true
    )) {
      // 送信成功したら最新情報を取得
      props.reload()
    }
  }

  // キャンセル動作
  const onCancel = () => {
    props.setOpen(false)
  }
  useEffect(() => {
    setValue( 'affiliation', props.affiliation);
  }, [props.affiliation]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ml:4, mr:4, mb:3}}>
          <Email
            register_id={register('id')}
            id={props.id}
            register_email={register('email')}
            email={props.email}
          />
          <Affiliation
            name='affiliation'
            control={control}
            register_affiliation={register('affiliation', AFFILIATION)}
            defaultValue_affiliation={props.affiliation}
            error_affiliation={errors.affiliation}
            setValue={setValue}
            getValues={getValues}
            latitude={props.latitude}
            longitude={props.longitude}
            setValueNameLat={PARAM_NAME.LAT}
            setValueNameLng={PARAM_NAME.LNG}
          />
          <RoleCheck
            register_admin={register('is_admin')}
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            register_postman={register('is_postman')}
            isPostman={isPostman}
            setIsPostman={setIsPostman}
            register_staff={register('is_staff')}
            isStaff={isStaff}
            setIsStaff={setIsStaff}
          />

          <PasswordPair
            register_password1={register('password', PASSWORD(email))}
            error_password1={errors.password}
            register_password2={register('password_repeat', PASSWORD_REPEAT(password))}
            error_password2={errors.password_repeat}
          />

          <CancelSubmit
            onCancel={onCancel}
            text='更新' />
        </Stack>
      </form>
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
    </>
  )
}

export default UserInfoForm;
