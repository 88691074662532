import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { TextField, Stack } from "@mui/material";

import InputField from '../../atoms/input_field';
import InputOverview from '../../atoms/input_overview';
import SwitchButton from '../../items/switch_button'
import SelectLatlon from '../../items/select_latlon'
import Common from '../../functions/common';
import PostData from '../../functions/post_data';
import PatchData from '../../functions/patch_data';
import MessageBar from '../../items/snackbar'
import FormDatePicker from '../../items/date_picker'
import { PARAM_NAME, DEFAULT_POSITION } from '../../const/general';
import { TITLE, OVERVIEW_SURVEY } from '../../const/roules';

function SurveyInfoForm(props) {
  const { control, register, setValue, getValues, reset,
    handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur' });

  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');
  const [dateError, setDateError] = useState('');

  // 本日の日付を作成
  const nowDatetime = new Date()
  const currentDate = new Date(nowDatetime.getFullYear(), nowDatetime.getMonth(), nowDatetime.getDate(), 0, 0, 0);

  // 調査登録・更新成功時の処理
  const onSuccess = async (response, proc, setSuccessMessage) => {
    // リロード
    props.reload()
    setSuccessMessage(`調査情報：${response.data.title}を${proc}しました`)
    // フォームリセット
    reset()
    setValue(PARAM_NAME.LAT, DEFAULT_POSITION.LAT)
    setValue(PARAM_NAME.LNG, DEFAULT_POSITION.LNG)

  }

  // 調査登録・更新失敗時の処理
  const onError = (err, proc, setErrorMessage) => {
    if (err.response === undefined) {
      setErrorMessage(`調査情報の${proc}に失敗しました。`)
    } else if (err.response.data.title) {
      setErrorMessage('この調査名は既に登録されています。')
    } else if (err.response.data.survey_ending_date) {
      setErrorMessage('調査終了日を正しく設定してください。')
    } else {
      setErrorMessage(`調査情報の${proc}に失敗しました。`)
    }
  }

  // キャンセル動作
  const onCancel = () => {
    props.setOpen(false)
  }

  const onSubmit = data => {
    // 終了日エラーチェック（メッセージはformに出ているので何もせずに処理を終了）
    if (dateError !== '') {
      return
    }
    if (data.survey_ending_date === '') {
      data.survey_ending_date = null
    }
    // タイトルの全角英数字を半角に変換
    data.title = Common.strConvertHalfWidth(data.title)
    
    if (props.proc_type === 'create') {
      // 緯度経度チェック（formで制御できないためここで実施）
      if (data.latitude === undefined || data.longitude === undefined
        || data.latitude === 0 || data.longitude === 0) {
        setSnackBarMessage('調査場所が選択されていません')
        setSnackBarStatus('error')
        setOpenSnackBar(true)
        return
      }
      PostData
        (
          data,
          '調査情報',
          'survey/',
          props.setSnackBarMessage,
          props.setSnackBarStatus,
          props.setOpenSnackBar,
          setSnackBarMessage,
          setSnackBarStatus,
          setOpenSnackBar,
          onSuccess,
          onError
        )
    }
    if (props.proc_type === 'update') {
      console.log(data)
      PatchData
        (
          data,
          '調査情報',
          `survey/${data.id}/`,
          props.setSnackBarMessage,
          props.setSnackBarStatus,
          props.setOpenSnackBar,
          setSnackBarMessage,
          setSnackBarStatus,
          setOpenSnackBar,
          onSuccess,
          onError
        )
    }
  }

  const onChange = (newValue) => {
    console.log(newValue)
    if (newValue === null || newValue === undefined || newValue === '') {
      setDateError('')
      setValue('survey_ending_date', null)
      return
    }
    if (Number.isNaN(newValue.getDate())) {
      setValue('survey_ending_date', newValue)
      setDateError('日付形式で入力してください')
      return
    }
    if (newValue < currentDate) {
      setValue('survey_ending_date', newValue)
      setDateError('本日以降の日付を入力してください')
      return
    }
    setDateError('')
    setValue('survey_ending_date', newValue)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <TextField sx={{ display: 'none' }}
            {...register('id')}
            value={props.id}
          />
          <InputField
            register={register('title', TITLE)}
            defaultValue={props.title}
            label="タイトル"
            error={errors.title}
          />
          <SelectLatlon
            setValue={setValue}
            getValues={getValues}
            latitude={props.latitude}
            longitude={props.longitude}
            setValueNameLat={PARAM_NAME.LAT}
            setValueNameLng={PARAM_NAME.LNG}
            buttonName="調査場所を選択"
          />
          <InputOverview
            register={register('overview', OVERVIEW_SURVEY)}
            overview={props.overview}
            error={errors.overview}
          />
          <FormDatePicker
            name='survey_ending_date'
            control={control}
            hintText="調査終了日"
            defaultValue={props.surveyEndingDate}
            onChange={onChange}
            dateError={dateError}
          />
        </Stack>
        <SwitchButton
          proc_type={props.proc_type}
          onCancel={onCancel}
          submitButtonName={props.submitButtonName}
        />

      </form>
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
    </>
  )
}
export default SurveyInfoForm;
