import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

SubTitle.propTypes = {
  children : PropTypes.string
}

// style定義
const TextLabel = styled.p`
font-size: 0.8rem;
text-align: left;
color: #6C6C6C;
`

export default function SubTitle(props){
  return (
    <TextLabel>{props.text}</TextLabel>
  )

}