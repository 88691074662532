import React, { useEffect, useState } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

import { CONTENTS_TYPE, } from '../../const/general'
import { ListImage, } from '../../style/image'
import getImage from '../../functions/get_image';

export default function ContentsImagePlayButton(props) {

    return (
        <>
            <ListImage
                srcSet={props.imgSrc}
                loading="lazy"
                onClick={() => props.handleClick(props.row)} />
            {props.row.contents_type === CONTENTS_TYPE.MOVIE && 
                <PlayCircleOutlineIcon fontSize="large" style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    fill: '#fff'
                }}
                    onClick={() => props.handleClick(props.row)} />}
        </>
    )
}
