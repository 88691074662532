import React from 'react';
import {
  TileLayer,
  LayersControl,
} from 'react-leaflet'
import Control from 'react-leaflet-custom-control';

const MapControl = (props) => {

  return (
    <Control position="topright">
      <LayersControl>
        <LayersControl.BaseLayer checked={props.nomalMap} name="国土地理院(標準地図)">
          <TileLayer
            attribution='&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
            url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
            eventHandlers={{
              add: (e) => {
                props.setNomalMap(true)
                props.setPhotoMap(false)
                props.setShadowMap(false)
              }
            }}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked={props.photoMap} name="国土地理院(写真)"
        >
          <TileLayer
            attribution='&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
            url="https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
            eventHandlers={{
              add: (e) => {
                props.setNomalMap(false)
                props.setPhotoMap(true)
                props.setShadowMap(false)
              }
            }}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked={props.shadowMap} name="地理院 陰影起伏">
          <TileLayer
            attribution='&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">地理院 陰影起伏</a>'
            url="https://cyberjapandata.gsi.go.jp/xyz/hillshademap/{z}/{x}/{y}.png"
            eventHandlers={{
              add: (e) => {
                props.setNomalMap(false)
                props.setPhotoMap(false)
                props.setShadowMap(true)
              }
            }}
          />
        </LayersControl.BaseLayer>
      </LayersControl>
    </Control>
  );
}

export default MapControl;