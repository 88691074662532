import { Chip } from '@mui/material';
import styled from '@emotion/styled';
import { LEVEL_ICONS } from '../const/avater_icon';

// 共通のチップのスタイル
// 角の丸さ指定
export const RadiusChipBase = styled(Chip)(() => ({
    borderRadius: 8,
    //overflow: 'hidden',
    textOverflow: 'ellipsis',
    //height:'22px'
  }));



// 通常サイズのタグチップ
export const TagChip = styled(RadiusChipBase)(() => ({
  maxWidth: 300,
  margin: '0px 8px 5px 0px',
}));

// 通常サイズで選択状態のタグチップ
export const SelectedTagChip = styled(TagChip)(() => ({
  backgroundColor: '#333',
  color: '#fff',
}));

// 通常サイズでホバー変化ありのタグチップ
export const HoverTagChip = styled(SelectedTagChip)(() => ({
  '&:hover': {
    backgroundColor: '#696969'
  },
}));

// 通常サイズのタグチップ(横幅制限なし)
export const TagChipWide = styled(RadiusChipBase)(() => ({
  margin: '0px 8px 5px 0px',
}));

// 小さいサイズのタグチップ
export const TagChipSmall = styled(TagChip)(() => ({
  height: 23,
}));

// 小さいサイズで選択状態のタグチップ
export const SelectedTagChipSmall = styled(SelectedTagChip)(() => ({
  height: 23,
}));

// 小さいサイズでホバー変化ありのタグチップ
export const HoverTagChipSmall = styled(HoverTagChip)(() => ({
  height: 23,
}));

// 通常サイズでタグの紐付きなしのチップ
export const NoneTagChip = styled(TagChip)(() => ({
  borderStyle: '1px solid red',
  color: '#fff',
  fontWeight: 'bold',
}));



// 重要度チップ
export const LevelChip = styled(RadiusChipBase)(() => ({
  marginRight: '10px',
  fontWeight: 'bold',
  width: '85px',
}));

// 選択状態の重要度チップ
export const SelectedLevelChip = styled(LevelChip)((props) => ({
  backgroundColor: LEVEL_ICONS[props.level].BG_COLOR,
  color: LEVEL_ICONS[props.level].FONT_COLOR,
}));

// 非選択状態の重要度チップ
export const UnselectedLevelChip = styled(LevelChip)((props) => ({
  backgroundColor: LEVEL_ICONS[props.level].BG_UNSELECT_COLOR,
  color: LEVEL_ICONS[props.level].FONT_HOVER_COLOR,
  '&:hover': {
    backgroundColor: LEVEL_ICONS[props.level].BG_HOVER_COLOR,
  }
}));



// リスト内に配置する小さめのチップ
export const SmallChip = styled(RadiusChipBase)(() => ({
  maxWidth: 150,
  height: 23,
  margin: '2px 5px 0px 0px',
  lineHeight: '11px',
  fontSize:'0.7em'
}));
// text内に配置する極小のチップ
export const SmallTextChip = styled(RadiusChipBase)(() => ({
  maxWidth: 150,
  height: 15,
  margin: '0px 0px 2px 0px',
  lineHeight: '11px',
  fontSize:'0.9em'
}));

// タグの紐付きなしのチップ
export const SmallNoneTagChip = styled(SmallChip)(() => ({
  backgroundColor: 'rgba(255, 69, 0)',
  color: '#fff',
  fontWeight: 'bold',
}));

// コンテンツ非公開のチップ
export const SmallNotPublicChip = styled(SmallChip)(() => ({
  backgroundColor: '#666666',
  color: '#fff',
  fontWeight: 'bold',
}));

