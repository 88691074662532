import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled" {...props} />);
});
export default function SimpleSnackbar(props) {

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    props.setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{marginTop: 4}}
        open={props.open}
        // autoHideDuration={6000}
        onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={props.status} sx={{ width: '100%' }}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
