import {
    CreateMultipartUploadCommand,
    UploadPartCommand,
    CompleteMultipartUploadCommand,
    AbortMultipartUploadCommand,
    S3Client,
} from "@aws-sdk/client-s3";
import {
    UPLOAD_STATUS,
    AWS_INFO,
    UPLOAD_FILE
} from '../const/general'

import AWS, { Credentials } from 'aws-sdk';


export default async function multipartUploadProc(
    movie, original_s3_prefix, setCounter, info1, info2) {

    const s3Client = new S3Client({
        region: AWS_INFO.REGION, 
        credentials: new Credentials(info1, info2)
    });
    const bucketName = AWS_INFO.S3_BUCKET_NAME;
    const key = original_s3_prefix + movie.id + movie.ext;

    let uploadId;

    try {
        const multipartUpload = await s3Client.send(
            new CreateMultipartUploadCommand({
                Bucket: bucketName,
                Key: key,
            }),
        );

        uploadId = multipartUpload.UploadId;

        // Upload each part.
        const file = movie.uploadFile
        const fileSize = file.size;
        const maxPart = Math.ceil(fileSize / UPLOAD_FILE.CHUNK_SIZE);
        const uploadPromises = [];

        // 全パートを５で割る 27partなら６になるはず
        const large_part_count = Math.ceil(maxPart / UPLOAD_FILE.CHUNK_PROMISE_COUNT)

        let partsInfo = [];

        for (let large_part = 0; large_part < large_part_count; large_part++) {

            // 最後のループだったら
            let small_part_count = UPLOAD_FILE.CHUNK_PROMISE_COUNT
            if (large_part + 1 === large_part_count) {
                small_part_count = maxPart - (large_part * UPLOAD_FILE.CHUNK_PROMISE_COUNT)
            }
            console.log(small_part_count)
            for (let part = 0; part < small_part_count; part++) {

                const slice_start = (large_part * UPLOAD_FILE.CHUNK_PROMISE_COUNT + part) * UPLOAD_FILE.CHUNK_SIZE
                let slice_end = (large_part * UPLOAD_FILE.CHUNK_PROMISE_COUNT + part + 1) * UPLOAD_FILE.CHUNK_SIZE
                if (slice_end >= fileSize) {
                    slice_end = fileSize
                }

                const slice = file.slice(slice_start, slice_end);

                uploadPromises.push(
                    s3Client
                        .send(
                            new UploadPartCommand({
                                Bucket: AWS_INFO.S3_BUCKET_NAME,
                                Key: key,
                                UploadId: uploadId,
                                Body: slice,
                                CacheControl: 'no-cache',
                                PartNumber: large_part * UPLOAD_FILE.CHUNK_PROMISE_COUNT + part + 1,
                            }),
                        )
                        .then((response) => {
                            setCounter((prevCount) => prevCount + 1)
                            console.log("Part", large_part * UPLOAD_FILE.CHUNK_PROMISE_COUNT + part + 1, "uploaded");
                            if (response.$metadata.httpStatusCode === 200) {
                                partsInfo.push({
                                    PartNumber: large_part * UPLOAD_FILE.CHUNK_PROMISE_COUNT + part + 1,
                                    ETag: response.ETag
                                });
                            }
                            console.log(response)
                            return response;
                        }),
                );
            }
            await Promise.all(uploadPromises);
        }

        const sortInfo = partsInfo.sort((a, b) => a.PartNumber - b.PartNumber)

        return await s3Client.send(
            new CompleteMultipartUploadCommand({
                Bucket: bucketName,
                Key: key,
                UploadId: uploadId,
                MultipartUpload: {
                    Parts: sortInfo,
                },
            }),
        ).then(() => { return true });

    } catch (err) {
        console.error(err);

        if (uploadId) {
            const abortCommand = new AbortMultipartUploadCommand({
                Bucket: bucketName,
                Key: key,
                UploadId: uploadId,
            });

            await s3Client.send(abortCommand);
        }
        throw err
    }
};
