import React from 'react'
import { CancelButton, } from '../../style/button'

export default function ButtonCancel(props) {

  return (
    <CancelButton
      variant="contained"
      color="inherit"
      onClick={() =>
        props.onCancel()
      }
    >{props.text}</CancelButton>
  )

}