import React from 'react'
import { Avatar } from '@mui/material';
import { SMALL_LEVEL_ICONS } from '../../const/avater_small_icon';

export default function AvatarSmallIcon(props) {

  if (props.iconType !== undefined) {
    return (
      <Avatar
        variant="rounded"
        sx={{
          bgcolor: SMALL_LEVEL_ICONS[props.iconType].BG_COLOR,
          color: SMALL_LEVEL_ICONS[props.iconType].FONT_COLOR,
          border: SMALL_LEVEL_ICONS[props.iconType].BORDER_STYLE,
          width: 20,
          height: 20,
          marginRight: '5px',
        }}>
        {SMALL_LEVEL_ICONS[props.iconType].ICON}

      </Avatar>
    )
  } else {
    return <></>
  }
}