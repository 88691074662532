import React, { useState } from "react";

import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
} from "@mui/material";

import ContactMailIcon from '@mui/icons-material/ContactMail';

import { useForm } from 'react-hook-form'

import SelectTags from '../../items/select_tags'
import SelectUsersTable from '../../items/select_users_table'
import CancelSubmit from '../../items/cancel_submit';
import MessageBar from '../../items/snackbar'
import PostData from '../../functions/post_data';

import {
  TypographyNomal,
  TypographyGray,
  TypographyGraySmall,
} from '../../style/typography'
import { InputText, } from '../../style/text_view'
import { TagChipWide } from '../../style/chip';
import { BoxFlexStartTopMarginB } from '../../style/box'

function SendMailForm(props) {
  const { control, register, handleSubmit, setValue, getValues, formState: { errors }, watch, reset } = useForm({ mode: 'onBlur' });

  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');
  const [checkedId, setCheckedId] = useState([0]);
  const [mailAddressList, setMailAddressList] = useState([]);
  const [selectTagList, setSelectTagList] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const onCancel = () => {
    props.setSelectSurveyUUID(null)
  }

  const selectEmail = () => {
    const selectedRows = props.usersList.filter((item) =>
      checkedId.includes(item.id)
    );
    console.log(selectedRows)
    let list = []
    selectedRows.map((row, id) => (
      list.push({
        'id': row.id,
        'affiliation': row.affiliation,
        'email': row.email
      })
    ))
    setMailAddressList(list);
    setOpen(false);
  };

  // メール送信成功時の処理
  const onSuccess = async (response, proc, setSuccessMessage) => {
    // リロード
    props.reload()
    setSuccessMessage(`メールを送信しました。ご本人様にもメールが届きますのでご確認ください。`)
    // // フォームリセット
    // reset()
    // setValue(PARAM_NAME.LAT, DEFAULT_POSITION.LAT)
    // setValue(PARAM_NAME.LNG, DEFAULT_POSITION.LNG)

  }

  // メール送信失敗時の処理
  const onError = (err, proc, setErrorMessage) => {
    setErrorMessage(`メール送信に失敗しました。`)
  }

  const onSubmit = async () => {
    let fd = new FormData()
    fd.append('survey_uuid', props.selectSurveyUUID)

    let tagUUIDs = ''
    if (selectTagList.length > 0) {
      // 選択されているタグUUIDをカンマ区切り文字列で取得
      const tagUUIDList = selectTagList.map((value) => {
        return value.tag_uuid;
      });
      console.log(tagUUIDList)

      tagUUIDs = tagUUIDList.join(",");
      console.log(tagUUIDs)
      fd.append('tag_ids', tagUUIDs)
    } else {
      // タグが選択されていなければエラー
      setSnackBarStatus('error')
      setSnackBarMessage(`タグを１つは選択してください。`)
      setOpenSnackBar(true)
      return
    }

    if (mailAddressList.length > 0) {
      // 選択されている送信先ユーザーIDをカンマ区切り文字列で取得（テーブル登録用）
      const recipient_user_list = mailAddressList.map((value) => {
        return value.id;
      });
      console.log(recipient_user_list)
      const recipients = recipient_user_list.join(",");
      console.log(recipients)
      fd.append('recipient_contributor', recipients)

    } else {
      // タグが選択されていなければエラー
      setSnackBarStatus('error')
      setSnackBarMessage(`メール送信先を選択してください。`)
      setOpenSnackBar(true)
      return
    }

    // 選択されている送信先メールアドレスをカンマ区切り文字列で取得（Lambda起動用）
    const recipient_mail_list = mailAddressList.map((value) => {
      return value.email;
    });
    console.log(recipient_mail_list)

    const recipient_mails = recipient_mail_list.join(",");
    console.log(recipient_mails)
    fd.append('recipient_mails', recipient_mails)

    console.log(fd)
    if (await PostData
      (
        fd,
        '調査依頼メール送信',
        'send_survey_request/',
        setSnackBarMessage,
        setSnackBarStatus,
        setOpenSnackBar,
        setSnackBarMessage,
        setSnackBarStatus,
        setOpenSnackBar,
        onSuccess,
        onError
      )) {
      // 送信成功したら最新情報を取得
      props.reload()
    }
  }

  return (
    <>
      <Container maxWidth='md'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container
            sx={{ marginTop: '10px' }}
            spacing={3}>
            <Grid item xs={12} md={7}>
              <Stack spacing={2}>
                <InputText type="text" size="small" sx={{ width: '100%' }}
                  label="タイトル"
                  disabled='true'
                  value={props.title}
                  variant="outlined"
                />
                <Stack>
                  <TypographyNomal >撮影場所 </TypographyNomal>
                  <TypographyNomal >{props.address} </TypographyNomal>
                  <TypographyGraySmall >{`${props.latitude} / ${props.longitude}`}</TypographyGraySmall>
                </Stack>
                <InputText
                  value={props.overview}
                  label="概要"
                  disabled='true'
                  multiline
                  minRows={5}
                  placeholder="概要"
                />
                {props.activeTags !== null && props.activeTags !== undefined && props.activeTags.length !== 0 ?
                  <SelectTags
                    tagList={props.activeTags}
                    selectTagList={selectTagList}
                    setSelectTagList={setSelectTagList}
                  />
                  :
                  <>
                    <TypographyGray>災害種別タグ</TypographyGray>
                    <Alert sx={{ mb: 3 }} severity="warning">
                      <AlertTitle>タグが登録されていません</AlertTitle>
                      アップロード依頼URLにタグを指定する場合は、先にタグを登録してください
                    </Alert>
                  </>
                }
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <BoxFlexStartTopMarginB>
                <Button variant="contained"
                  startIcon={<ContactMailIcon />}
                  sx={{
                    color: '#fff',
                    backgroundColor: '#6c8eaf',
                    '&:hover': {
                      backgroundColor: '#6c8eafde'
                    }
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setOpen(true)
                  }}
                >メール送信先指定</Button>
              </BoxFlexStartTopMarginB>
              <Stack direction="row" useFlexGap flexWrap="wrap">
                {mailAddressList.length > 0 &&
                  mailAddressList.map((row, id) => (
                    <TagChipWide
                      label={`${row.affiliation} : ${row.email}`}
                    // onDelete={handleDelete}
                    />
                  ))
                }
              </Stack>
            </Grid>
          </Grid>
          {/* <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} /> */}
          <CancelSubmit
            onCancel={onCancel}
            text='依頼メール送信' />
        </form>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >調査依頼メールの送信先を選択してください</DialogTitle>
        <DialogContent >
          <SelectUsersTable
            checkedId={checkedId}
            setCheckedId={setCheckedId}
            usersList={props.usersList}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={selectEmail}>送信先を選択</Button>
        </DialogActions>
      </Dialog>
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
    </>
  )
}

export default SendMailForm;
