import React from 'react'
import {
  Autocomplete,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { BoxFlexStartWrap } from '../../style/box';
import { InputText, } from '../../style/text_view'
import { TagChip, HoverTagChip, NoneTagChip, SmallTextChip } from '../../style/chip';

export default function SelectTags(props) {

  const handleTagChange = ((e, value) => {
    console.log(value)
    props.setValue(`itemRows.${props.id}.tags_input`, value)
  });

  function Chips(props) {
    return (
      <>
        {props.tagList.map((row, index) => (
          props.getValues(`itemRows.${props.id}.tag.${row.id}`) === row.tag_uuid ?
            <HoverTagChip
              key={index}
              name={`itemRows.${props.id}.tag.${row.id}`}
              label={row.tag_names}
              type="button"
              onClick={(e) => {
                e.preventDefault()
                props.setValue(`itemRows.${props.id}.tag.${row.id}`, null);
              }}
            />
            :
            <TagChip
              key={index}
              name={`itemRows.${props.id}.tag.${row.id}`}
              label={row.tag_names}
              type="button"
              onClick={(e) => {
                e.preventDefault()
                props.setValue(`itemRows.${props.id}.tag.${row.id}`, row.tag_uuid);
              }}
            />
        ))}
      </>
    )
  }

  function SelectTagsNone(props) {
    let bgcolor = 'rgba(255, 69, 0)'
    props.tagList.map((row) => {
      if (props.getValues(`itemRows.${props.id}.tag.${row.id}`) === row.tag_uuid) {
        bgcolor = 'rgba(255, 69, 0, 0.2)'
      }
    })
    return (
      <NoneTagChip
        label='タグ未選択'
        sx={{
          backgroundColor: bgcolor,
        }}
      />
    )
  }

  return (
    <FormControl>
      <FormHelperText>災害種別タグ (複数選択可)</FormHelperText>
      <BoxFlexStartWrap>
        <SelectTagsNone
          tagList={props.tagList}
          id={props.id}
          getValues={props.getValues}
        />
        <Chips
          tagList={props.tagList}
          id={props.id}
          setValue={props.setValue}
          getValues={props.getValues}
        />
      </BoxFlexStartWrap>
      <FormHelperText>新規タグを追加できます </FormHelperText>
      <FormHelperText>エンターキー押下で <SmallTextChip label='新規タグ'/> の形式にしてアップロードしてください</FormHelperText>
      <Autocomplete
        clearIcon={false}
        options={[]}
        freeSolo
        multiple
        onChange={handleTagChange}
        renderTags={(value, props) => {
          return value.map((option, index) =>
          (<TagChip label={option} {...props({ index })} />))
        }}
        renderInput={(params) => <InputText placeholder="新規タグ" {...params} />}
      />
    </FormControl>
  )
}