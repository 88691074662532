import * as React from 'react';
import { InputText, } from '../../style/text_view'

export default function InputPassword(props) {
    return (
        <>
            <InputText
                type="password"
                size="small"
                {...props.register}
                id="outlined-basic"
                variant="outlined"
                label={props.label}
                helperText={props.helperText}
                error={props.error}
                autoComplete='new-password'
            />
        </>
    );
}
