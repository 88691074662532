import React from 'react'
import { BoxSpaceBetweenMarginTB, BoxFlexStart } from '../../style/box'
import { TypographySmall,
   TypographyNomal, 
   TypographyBold, 
   TypographyGraySmall } from '../../style/typography'
import AvatarIcon from '../avatar_icon'
import TagList from '../../items/tag_list'
import { GENERATE_3D_STATUS } from '../../const/general'
import { ICON_INDEX} from '../../const/risk_style'


export default function ContentsInfoBox(props) {
  return (
    <>
      <BoxSpaceBetweenMarginTB>
        <BoxFlexStart>
          <AvatarIcon iconType={props.row.emergency_level} />
          {props.icon !== null &&
            <AvatarIcon iconType={props.icon} />
          }
          {props.row.generate_3d_status === GENERATE_3D_STATUS.DONE &&
            <AvatarIcon iconType={ICON_INDEX.THREE_D} />
          }
        </BoxFlexStart>
        <TypographyGraySmall>{props.date_time} </TypographyGraySmall>
      </BoxSpaceBetweenMarginTB>
      <TypographyBold >{props.row.title}</TypographyBold>
      <TypographyNomal>{props.row.address}</TypographyNomal>
      <TypographySmall>{props.row.overview}</TypographySmall>
      <TagList tags={props.row.tags} />
    </>
  )
}