import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useSWRInfinite from "swr/infinite";
import { Box, Grid, } from '@mui/material';

import cliAuth from '../../functions/axios_cli';
import { useIntersection } from '../../functions/useintersection';
import { getSearchParam } from '../../functions/get_search_conditions'

import FailedToLoad from '../failed_to_load'

import Proggress from '../../items/progress'
import Grids from '../../items/home_grid_item'

function HomeBody(props) {
  const navigate = useNavigate()

  // topへ戻る要素への参照
  const scrollToTopRef = useRef();
  // トリガーのdiv要素への参照
  const ref = useRef();
  // トリガーが表示されているか監視
  const intersection = useIntersection(ref);
  // 一回の取得数
  const limit = 15;
  // useSWRInfiniteのキーとなるパラメータ付きURLを生成
  const getKey = (pageIndex, previousPageData) => {
    console.log(pageIndex)
    if (previousPageData && !previousPageData.length) {
      return null
    }
    console.log(previousPageData)
    console.log(props.selectTag)
    console.log(props.searchConditions)

    let param_tag = ''
    if (props.selectTag.tag_uuid !== '') {
      param_tag = `&tag_uuid=${props.selectTag.tag_uuid}`
    }
    const param_conditions = getSearchParam('&', props.searchConditions)
    console.log(param_conditions)

    if (pageIndex === 0) {
      // 1ページ目はとりあえず取得する
      console.log(param_tag)
      return `search_contents_pagination/?page=1&limit=${limit}${param_tag}${param_conditions}`
    }
    if (props.contentsCount > pageIndex * limit) {
      // 続きのページがあると判定して続きのページを取得する
      return `search_contents_pagination/?page=${pageIndex + 1}&limit=${limit}${param_tag}${param_conditions}`
    }
    // 1ページ目ではなく、続きのページも無いと判定してnullを返す
    return null
  };

  const {
    data: contentsMovieList,
    error,
    size,
    setSize
  } = useSWRInfinite(getKey, (url) =>
    cliAuth.get(url).then((res) => {
      props.setContentsCount(res.data.count)
      return res.data.results
    }));
  const isEmpty = contentsMovieList?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty
    || (contentsMovieList && contentsMovieList[contentsMovieList.length - 1]?.length < limit)
    || (contentsMovieList && contentsMovieList.length * limit >= props.contentsCount);

  // 次のデータの取得
  const getPics = async () => {
    setSize(size + 1);
  };

  // ページのトップにスクロール
  const scrollToTopOfList = React.useCallback(() => {
    scrollToTopRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }, [scrollToTopRef])

  useEffect(() => {
    // トリガーが表示されたらデータを取得
    if (intersection && !isReachingEnd) {
      getPics();
    }
  }, [intersection, isReachingEnd]);

  useEffect(() => {
    // タグが選択されたらページのトップにスクロール
    scrollToTopOfList()
  }, [props.selectTag, props.searchConditions]);

  if (error) return <FailedToLoad />;
  if (!contentsMovieList) return <Proggress />;

  const handleClick = (content) => {
    //console.log(movie_src)
    //navigate("/view/map", { state: { content: content } })
    navigate(`/view/map?contents_uuid=${content.contents_uuid}`)
  }
  // 一覧表示でデータを扱いやすいように整形
  const contents = contentsMovieList.flat();
  console.log(contents)

  return (
    <>
      <div id="top-of-list" ref={scrollToTopRef} />
      <Box sx={{ mt: 2 }}>
        <Grid container rowSpacing={3} columnSpacing={1}>
          {contents.map((row, i) => (
            <Grids
              key={i}
              row={row}
              handleClick={handleClick} />
          ))}
        </Grid>
        {/* 次のデータを取得するトリガー */}
        <div ref={ref}>
          <p style={{ textAlign: "center", paddingTop: "40px" }}>
            {!isReachingEnd ? "読み込み中です..." : "すべて読み込みました。"}
            {isEmpty ? "取得するデータはありませんでした。" : null}
          </p>
        </div>
      </Box>
    </>
  )
}

export default HomeBody;
