import * as React from 'react';
import LevelTitle from '../level_title'

export default function MarkerPopup(props) {

    return (
        <>
            <LevelTitle
                emergency_level={props.content.emergency_level}
                title={props.content.title}
                affiliation={props.content.affiliation}
                popup={'true'}
            />
        </>
    )
}
