import React, { useRef, useState } from "react";
import {
  FormControl,
  InputAdornment,
  Stack,
  TextField
} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';

import InputField from '../../atoms/input_field';
import UploadThumb from '../../items/upload_thumb'
import { CONTENTS_TYPE } from '../../const/general'


function UploadThumbForm(props) {

  const [isFlightLog, setIsFlightLog] = useState(false);
  const inputRef = useRef(null);

  // 削除ボタンイベント
  const onCancel = () => {
    props.deleteMovies(props.movieID)
    props.useFieldArray.remove(props.id)
  }

  // フライトログファイル選択ボタンイベント
  const fileUpload = () => {
    // refを通じてinputボタンをクリックする
    // これによりonFileInputChangeがコールされる
    inputRef.current.click()
  }
  const onFileInputChange = (event) => {
    console.log(event.target.files[0])
    props.setValue(props.setValueName_routeLog, event.target.files[0])
    props.setValue(props.setValueName_routeLogFileName, event.target.files[0].name)
    setIsFlightLog(true)
    event.target.value = ""
  }

  // フライトログファイル取り消しイベント
  const fileClear = () => {
    props.setValue(props.setValueName_routeLog, null)
    props.setValue(props.setValueName_routeLogFileName, '')
    setIsFlightLog(false)
  }

  // フライトログアップロード用コンポーネント
  const FlightLog = (props) => {
    return (
      <FormControl>
        <TextField
          {...props.register_routeLogFileName}
          placeholder="フライトログがあれば添付"
          sx={{ width: '100%', height: 'auto' }}
          size='small'
          value={props.getValues(props.setValueName_routeLogFileName)}
          helperText={props.error_routeLogFileName?.message}
          error={props.error_routeLogFileName?.message.length > 0}
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {props.icon}
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </FormControl>
    )
  }

  return (
    <Stack>
      <UploadThumb
        contentType={props.contentType}
        url={props.url}
        thumbnailUrl={props.thumbnailUrl}
      />
      {/* {props.contentType === CONTENTS_TYPE.MOVIE &&
        <>
          < Stack spacing={1} sx={{ mt: 3 }}>
            {isFlightLog ?
              <FlightLog
                icon={<ClearIcon fontSize="small" onClick={() => fileClear()} />}
                getValues={props.getValues}
                setValueName_routeLogFileName={props.setValueName_routeLogFileName}
                error_routeLogFileName={props.error_routeLogFileName}
              />
              :
              <FlightLog
                icon={<AttachFileIcon onClick={() => fileUpload()} />}
                getValues={props.getValues}
                setValueName_routeLogFileName={props.setValueName_routeLogFileName}
                error_routeLogFileName={props.error_routeLogFileName}
              />
            }
            <FormControl>
              <InputField
                register={props.register_registerId}
                label="機体番号"
                placeholder="機体番号(例：JUABCD123456)"
                error={props.error_registerId}
              />
            </FormControl>

          </Stack>
          <input
            hidden
            ref={inputRef}
            type="file"
            accept="text/plain"
            onChange={onFileInputChange}
          />
        </>
      } */}
    </Stack >
  )
}

export default UploadThumbForm;
