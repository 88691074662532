import React from 'react'
import { styled, useTheme } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

import {
    PermMedia,
    LocationOn,
    Home,
} from '@mui/icons-material';

const CustomNavigation = styled(BottomNavigationAction)(({ theme }) => ({
    backgroundColor: '#061027',
    color: '#fff',
}));

export default function BottomNavi(props) {
    return (
        <Paper sx={{
            position: 'fixed',
            bottom: 11,
            left: 0,
            right: 0,
            zIndex:3,
        }} elevation={3}>
            <BottomNavigation
                showLabels
                value={props.value}
                onChange={(event, newValue) => {
                    console.log(newValue)
                    if (newValue === 0)
                        props.navigate('/')
                    props.setValue(newValue);
                }}
                sx={{
                    backgroundColor: '#061027',
                    "& .Mui-selected, .Mui-selected > svg": {
                      color: "#f9a405e6"
                    }
                  }}
            >
                <CustomNavigation label="ホーム" icon={<Home />} />
                <CustomNavigation label="動画・画像" icon={<PermMedia />} />
                <CustomNavigation label="マップ" icon={<LocationOn />} />
            </BottomNavigation>
        </Paper>
    )
}