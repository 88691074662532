import React from 'react'
import { Avatar, Tooltip } from '@mui/material';
import { LEVEL_ICONS } from '../../const/avater_icon';

export default function AvatarIcon(props) {

  if (props.iconType !== undefined) {
    return (
      <Avatar
        variant="rounded"
        sx={{
          bgcolor: LEVEL_ICONS[props.iconType].BG_COLOR,
          color: LEVEL_ICONS[props.iconType].FONT_COLOR,
          border: LEVEL_ICONS[props.iconType].BORDER_STYLE,
          fill: LEVEL_ICONS[props.iconType].FONT_COLOR,
          width: 30,
          height: 30,
          marginRight: 1,
        }}>
        {props.popup ?
          LEVEL_ICONS[props.iconType].ICON
          :
          <Tooltip
            title={LEVEL_ICONS[props.iconType].TEXT} placement="right-end"
          >
            {LEVEL_ICONS[props.iconType].ICON}
          </Tooltip>
        }
      </Avatar>
    )
  } else {
    return <></>
  }
}