import React, { useState } from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import getAddress from '../../functions/get_address';

export default function SearchByAddress(props) {
    const [searchAddress, setSearchAddress] = useState("");
    const { normalize } = require('@geolonia/normalize-japanese-addresses')

    const SearchPosition = () => {
        normalize(searchAddress).then(async result => {
            const address = await getAddress(result.lat,result.lng)
            props.setAddress(address)
            props.setClickPosition({
                lat: result.lat,
                lng: result.lng,
              })
        })
    }

    return (
        <Paper
            component="form"
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mb: 1,
            }}
        >
            <InputBase
                value={searchAddress}
                onChange={(event) => setSearchAddress(event.target.value)}
                sx={{ ml: 1, flex: 1 }}
                placeholder="住所から検索（丁目まで入力してください）"
                onKeyDown={e => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
            />
            <IconButton
                onClick={SearchPosition}
                type="button"
                sx={{ p: '10px' }}
                aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}