export default function successHandler(
    movie, item, setSuccessContents
) {
    setSuccessContents((prevState) => [
        ...prevState,
        {
            movie: movie,
            item: item,
            fileId: movie.id
        },
    ]);
    return
}