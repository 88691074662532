import React from 'react';
import "video.js/dist/video-js.css";
import './movie.css';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import VideoPlayer from '../video_player'
import { Box } from '@mui/material';

import { CONTENTS_TYPE } from '../../const/general'
import { ViewImage, FullScreenImage } from '../../style/image'

export const ViewMovie = (props) => {

    const handle = useFullScreenHandle();

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        preload: "auto",
        playbackRates: [0.5, 1, 1.5, 2],
        width: 640,
        height: 360,
        userActions: {
            hotkeys: true
        },
        techOrder: ["chromecast", "html5"],
        sources: props.sources,
        chapters: props.chapters,
        selectContentUUID: props.selectContentUUID,
        contentsType: props.contentsType,
        selectContent: props.selectContent,
    };

    if (props.contentsType === CONTENTS_TYPE.MOVIE) {

        return (
            <VideoPlayer {...videoJsOptions} />
        )
    } else {
        return (
            <>
                <Box>
                    <ViewImage
                        src={props.imageUrl}
                        resizeMode={"contain"}
                        onClick={handle.enter}
                    ></ViewImage>
                </Box>
                <FullScreen handle={handle}>
                    {handle.active &&
                        <FullScreenImage
                            src={props.imageUrl}
                        ></FullScreenImage>
                    }
                </FullScreen>
            </>
        )
    }

};
export default ViewMovie;
