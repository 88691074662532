import React, { useState } from 'react'

import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";

import SurveyInfoForm from '../../../contents/admin_survey_form'
import Proggress from '../../../items/progress'
import FailedToLoad from '../../failed_to_load'
import MessageBar from '../../../items/snackbar'
import DeleteDialog from '../../../items/delete_dialog'
import TableRowsHeader from '../../../items/table_row_header'
import { GetSurveys, LoadMutate } from '../../../functions/get_data_swr';
import { URL, DEFAULT_POSITION } from '../../../const/general'

function Survey() {
  const [deleteSurveyId, setDeleteSurveyId] = useState('');
  const [deleteSurveyTitle, setDeleteSurveyTitle] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const reload = () => {
    LoadMutate(URL.SURVEY)
  }

  const Row = (props) => {
    const [open, setOpen] = useState(false);
    return (
      <React.Fragment>
        <TableRowsHeader
          id={props.row.id}
          name={props.row.title}
          open={open}
          setOpen={setOpen}
          setDeleteConfirmOpen={setDeleteConfirmOpen}
          setDeleteId={setDeleteSurveyId}
          setDeleteName={setDeleteSurveyTitle}
        />
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <SurveyInfoForm
                proc_type='update'
                id={props.row.id}
                title={props.row.title}
                latitude={props.row.latitude}
                longitude={props.row.longitude}
                overview={props.row.overview}
                surveyEndingDate={props.row.survey_ending_date}
                setOpen={setOpen}
                setSnackBarMessage={setSnackBarMessage}
                setSnackBarStatus={setSnackBarStatus}
                setOpenSnackBar={setOpenSnackBar}
                reload={reload}
                submitButtonName="調査更新" />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const { surveyList, isSurveyLoading, isSurveyError } = GetSurveys();
  if (isSurveyError) return <FailedToLoad />
  if (isSurveyLoading) return <Proggress />
  // console.log(surveyList);

  return (
    <>
      <SurveyInfoForm
        proc_type='create'
        latitude={DEFAULT_POSITION.LAT}
        longitude={DEFAULT_POSITION.LNG}
        surveyEndingDate={null}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar}
        reload={reload}
        submitButtonName="調査登録" />
      <TableContainer >
        <Table >
          <TableBody>
            {surveyList.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
      <DeleteDialog
        confirmOpen={deleteConfirmOpen}
        url={URL.DELETE_SURVEY + deleteSurveyId + '/'}
        targetName={deleteSurveyTitle}
        dialogTitle={'調査'}
        setConfirmOpen={setDeleteConfirmOpen}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar}
        reload={reload}
        setTargetFileId={setDeleteSurveyId}
        setTargetFileTitle={setDeleteSurveyTitle}
      />
    </>
  )
}

export default Survey;
