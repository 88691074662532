import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import style from 'styled-components'
import { styled, useTheme } from '@mui/material/styles';
import { COOKIES_KEY, ROLE } from '../../const/general'
import { 
  SERVICE_NAME, 
  URL_BASE, 
  MANUAL, 
  ARCHIVE_SITE_URL,
  DASHBOARD_URL,
  RISK_URL,
  SERVICE_TOP_URL,
 } from '../../const/config'
import Cookies from 'js-cookie';
import MuiDrawer from '@mui/material/Drawer';
import useOnClickOutside from "./use_onclick_outside";

import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';

import {
  ChevronLeft,
  ChevronRight,
  Email,
  Home,
  LocationOn,
  PhotoCamera,
  ViewInAr,
  Stream,
  LiveTv,
  ManageAccounts,
  Edit,
  AdminPanelSettings,
  PersonAdd,
  Image,
  Videocam,
  Tag,
  Hub,
  ArticleOutlined,
  Analytics,
  Equalizer,
  LocalLibrary,
  Dashboard,
  MenuBook,
} from '@mui/icons-material';

const drawerWidth = 250;

const TextSmallOpen = style.div`
font-size:0.8rem;
text-align: left;
color: #fff;
padding-top:10px;
padding-left:20px;
`
const TextSmallClose = style.div`
font-size:0.8rem;
text-align: center;
color: #fff;
padding-top:10px;
`
const menu_main = [
  { name: 'ホーム', url: '/', icon: Home, role: ROLE.IS_STAFF },
  { name: '地図', url: '/view/map', icon: LocationOn, role: ROLE.IS_STAFF },
  { name: 'マニュアル', url: URL_BASE + 'contents/manual/' + MANUAL, icon: MenuBook, role: ROLE.IS_STAFF },

  // { name: '3Dデータ', url: '#', icon: ViewInAr, role: ROLE.IS_STAFF },
  // { name: 'ライブ配信', url: '#', icon: Stream, role: ROLE.IS_STAFF },
]
const menu_upload = [
  { name: '動画・画像', url: '/upload/movie', icon: PhotoCamera, role: ROLE.IS_POSTMAN },
  // { name: 'ライブ配信登録', url: '#', icon: LiveTv, role: ROLE.IS_ADMIN },
]
const menu_setting = [
  { name: 'ユーザー設定変更', url: '/settings/user', icon: ManageAccounts, role: ROLE.IS_STAFF },
  { name: 'アップロード情報変更', url: '/settings/contents', icon: Edit, role: ROLE.IS_POSTMAN },
]
const menu_other = [
  { name: `${SERVICE_NAME}トップ`, url: `${SERVICE_TOP_URL}`, icon: Hub, role: ROLE.IS_STAFF },
  { name: 'リスク分析', url: `${RISK_URL}`, icon: Analytics, role: ROLE.IS_STAFF },
  // { name: 'リスク分析デモver', url: 'https://demo.cerd-edison.com', icon: Equalizer, role: ROLE.IS_STAFF },
  { name: '災害データアーカイブ', url: `${ARCHIVE_SITE_URL}`, icon: LocalLibrary, role: ROLE.IS_STAFF },
  { name: 'ダッシュボード', url: `${DASHBOARD_URL}`, icon: Dashboard, role: ROLE.IS_STAFF },
]
const menu_manage = [
  { name: 'ユーザー管理', url: '/admin/user', icon: AdminPanelSettings, role: ROLE.IS_ADMIN },
  { name: '新規ユーザー作成', url: '/admin/new_user/', icon: PersonAdd, role: ROLE.IS_ADMIN },
  { name: 'アップロード管理', url: '/admin/contents', icon: Image, role: ROLE.IS_ADMIN },
  // { name: 'ライブ配信管理', url: '#', icon: Videocam, role: ROLE.IS_ADMIN },
  { name: 'タグ登録', url: '/admin/tag', icon: Tag, role: ROLE.IS_ADMIN },
  { name: '調査登録', url: '/admin/survey', icon: ArticleOutlined, role: ROLE.IS_ADMIN },
  { name: 'アップロードURL作成', url: '/admin/send_url', icon: Email, role: ROLE.IS_ADMIN },
]
const menu = [
  { items: menu_main, open_header_label: '', close_header_label: '', role: ROLE.IS_STAFF },
  { items: menu_upload, open_header_label: 'アップロード', close_header_label: '投稿', role: ROLE.IS_POSTMAN },
  { items: menu_setting, open_header_label: '設定', close_header_label: '設定', role: ROLE.IS_STAFF },
  { items: menu_other, open_header_label: `${SERVICE_NAME}他のサービス`, close_header_label: SERVICE_NAME, role: ROLE.IS_STAFF },
  { items: menu_manage, open_header_label: '管理', close_header_label: '管理', role: ROLE.IS_ADMIN },
]

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: '#061027',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  color: '#fff'
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: '#061027',
  color: '#fff'
});


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '4px',
  paddingBottom: '4px',
  backgroundColor: '#061027',
  justifyContent: 'flex-end',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: '#333',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      backgroundColor: '#f00',
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const MenuItem = (props) => {
  return (
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: props.open ? 'initial' : 'center',
        px: 2.5,
        color: '#fff'
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: props.open ? 3 : 'auto',
          justifyContent: 'center',
          color: '#fff'
        }}
      >
        {props.open ?
          <props.item.icon />
          :
          <Tooltip title={props.item.name} placement="right-end">
            <props.item.icon />
          </Tooltip>
        }
      </ListItemIcon>
      <ListItemText primary={props.item.name} sx={{ opacity: props.open ? 1 : 0 }} />
    </ListItemButton>
  )
}

const SlideMenu = (props) => {

  const ref = useRef();
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => props.setOpen(false));

  const theme = useTheme();
  const navigate = useNavigate();
  console.log(Cookies.get(COOKIES_KEY.ID))

  const handleDrawerClose = () => {
    props.setOpen(false);
  }
  const handleClick = (url) => {
    navigate(url)
  }

  return (
    <Drawer
      ref={ref}
      variant="permanent"
      open={props.open}
      sx={{
        '& ::-webkit-scrollbar': {
          display: "none"
        },
        '& :hover': {
          '::-webkit-scrollbar': {
            display: "none"
          }
        }
      }}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ?
            <ChevronRight sx={{ color: '#fff' }} /> : <ChevronLeft sx={{ color: '#fff' }} />}
        </IconButton>
      </DrawerHeader>
      <>
        {menu.map((menu, menu_index) => (
          <div key={menu_index}>
            {menu_index !== 0 && menu.role <= Cookies.get(COOKIES_KEY.ROLE) ?
              <Divider
                sx={{
                  border: '0.1px solid #fff',
                  margin: '0px 10px'
                }} />
              :
              <></>
            }
            <List key={menu_index}>
              {menu.role > Cookies.get(COOKIES_KEY.ROLE) ?
                <></>
                :
                menu_index !== 0 && props.open ?
                  <TextSmallOpen>{menu.open_header_label}</TextSmallOpen>
                  :
                  <TextSmallClose>{menu.close_header_label}</TextSmallClose>

              }
              {menu.items.map((item, item_index) => {
                if (item.role > Cookies.get(COOKIES_KEY.ROLE)) {
                  return <div key={item_index}></div>
                } else {
                  if (menu.items === menu_other) {
                    if (item.url !== '') {
                      return <ListItem key={item_index}
                        disablePadding
                        sx={{ display: 'block' }}
                        component="a" href={item.url} target="_blank"
                      >
                        <MenuItem
                          item={item}
                          open={props.open}
                          setOpen={props.setOpen}
                        />
                      </ListItem>
                    }
                  } else if (menu.items === menu_main && item.name === 'マニュアル') {
                    return <ListItem key={item_index}
                      disablePadding
                      sx={{ display: 'block' }}
                      component="a" href={item.url} target="_blank"
                    >
                      <MenuItem
                        item={item}
                        open={props.open}
                        setOpen={props.setOpen}
                      />
                    </ListItem>
                  } else {
                    return <ListItem key={item_index}
                      disablePadding
                      sx={{ display: 'block' }}
                      onClick={() => handleClick(item.url)}
                    >
                      <MenuItem
                        item={item}
                        open={props.open}
                        setOpen={props.setOpen}
                      />
                    </ListItem>
                  }
                }
              })}
            </List>
          </div>
        ))}
      </>
    </Drawer>
  );
}
export default SlideMenu;
