import React, { useEffect, useState } from "react";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { Button, Box, IconButton, Stack } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import SearchDrawer from '../../contents/search_drawer';
import Proggress from '../progress'
import { GetTags } from '../../functions/get_data_swr';
import { TagChip, SelectedTagChip } from '../../style/chip';
import { BoxSpaceBetweenMarginLR, BoxFlexStart, BoxFlexStartWrap } from '../../style/box';
import { TypographyBreakWordGraySmallR } from '../../style/typography';
import { getSearchStr } from '../../functions/get_search_conditions'

export default function SearchConditions(props) {

    const [viewSearchStr, setViewSearchStr] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);

    useEffect(() => {
        // 現在の検索条件を文字列に起こす
        setViewSearchStr(getSearchStr(props.searchConditions))

    }, [props.searchConditions]);

    const handleSearchDrawerOpen = () => {
        console.log('handleSearchDrawerOpen')
        setOpenSearch(true);
    };
    const resetSearchConditions = () => {

        props.setSearchConditions(
            {
                registered_date_type: 'all',
                registered_starting_date: null,
                registered_ending_date: null,
                shoote_date_type: 'all',
                shoote_starting_date: null,
                shoote_ending_date: null,
                emergency_level_emergency: true,
                emergency_level_important: true,
                emergency_level_nomal: true,
                address: '',
                overview: '',
                order: 'shoote_date_new',
            });
    }

    const { tags, isTagsLoading } = GetTags();
    if (isTagsLoading) return <Proggress />
    console.log(tags);

    return (
        <>
            <Stack>
                <Box sx={{
                    display: "flex",
                    flexGrow: 1,
                    maxWidth: "100%",
                    minHeight: '32px'
                }}>
                    <Tabs
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable auto tabs example"
                        sx={{
                            minHeight: '32px',
                            [`& .${tabsClasses.scrollButtons}`]: {
                                paddingTop: '-10px',
                                //   '&.Mui-disabled': { display: 'none' },
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        }}
                    >
                        {props.selectTag.tag_uuid === '' ?
                            <SelectedTagChip label='すべて' type="button" />
                            :
                            <TagChip label='すべて' type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    props.setSelectTag({tag_uuid:'',tag_name:''})
                                }}
                            />
                        }
                        {tags.map((row, i) => {
                            const tag_name = row.tag_names
                            return (
                                props.selectTag.tag_uuid === row.tag_uuid ?
                                    <SelectedTagChip key={i} label={tag_name} title={tag_name}
                                        type="button" />
                                    :
                                    <TagChip key={i} label={tag_name} title={tag_name}
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            props.setSelectTag({tag_uuid:row.tag_uuid,tag_name:tag_name})
                                        }} />
                            )
                        })}
                    </Tabs>
                </Box>
                <BoxSpaceBetweenMarginLR>
                    <BoxFlexStartWrap>
                        <TypographyBreakWordGraySmallR>{props.contentsCount}件</TypographyBreakWordGraySmallR>
                        {viewSearchStr.map((str) => {
                            return (
                                <TypographyBreakWordGraySmallR>{str}</TypographyBreakWordGraySmallR>
                            )
                        })}
                    </BoxFlexStartWrap>
                    <BoxFlexStart>
                        {viewSearchStr.length > 0 &&
                            <Button variant="contained"
                                size='small'
                                sx={{
                                    color: '#333',
                                    padding: '3px',
                                    marginRight: '10px',
                                    backgroundColor: 'rgb(0 0 0 / 10%)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.12)'
                                    }
                                }}

                                onClick={(e) => {
                                    // e.preventDefault()
                                    resetSearchConditions()
                                }}
                            >検索条件クリア</Button>
                        }
                    </BoxFlexStart>
                </BoxSpaceBetweenMarginLR>
            </Stack>
        </>
    );
}

