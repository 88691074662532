import React, { useState } from 'react'
import cliAuth from '../../functions/axios_cli';
import ConfirmDialog from '../confirm_dialog'
import ProgressModal from '../progress_modal'
import { GetUser } from '../../functions/get_data_swr';
import FailedToLoad from '../../pages/failed_to_load'
import Proggress from '../progress'

export default function SingleDownloadDialog(props) {

  const [modalIsOpen, setIsOpen] = useState(false);
  const [progressMsg, setProgressMsg] = useState('');

  const handleCancel = () => {
    props.setConfirmOpen(false);
    props.setTargetFileId('');
    props.setTargetFileTitle('')
  };

  const handleAction = () => {
    props.setConfirmOpen(false)
    setIsOpen(true)
    setProgressMsg('ダウンロード準備')

    cliAuth.post(props.url)
      .then(response => {
        console.log(props.url + ' download success', response)
        props.setSnackBarMessage(`「${props.targetName}」のダウンロード準備を開始しました。メール通知をお待ちください。`)
        props.setSnackBarStatus('success')
        props.setOpenSnackBar(true)
        props.setTargetFileId('')
        props.setTargetFileTitle('')
        setIsOpen(false)
        setProgressMsg('')
        props.reload()
      })
      .catch(err => {
        props.setSnackBarMessage(`「${props.targetName}」のダウンロード準備を開始できませんでした`)
        props.setSnackBarStatus('error')
        props.setOpenSnackBar(true)
        props.setTargetFileId('')
        props.setTargetFileTitle('')
        props.setConfirmOpen(false)
        setIsOpen(false)
        setProgressMsg('')
        console.log(err);
      })
  }
  // ダウンロード実施ユーザーの情報を取得
  const { user, isUserLoading, isUserError } = GetUser();
  if (isUserError) return <FailedToLoad />
  if (isUserLoading) return <Proggress />

  return (
    <>
      <ConfirmDialog
        confirmOpen={props.confirmOpen}
        setConfirmOpen={props.setConfirmOpen}
        dialogTitle={`${props.dialogTitle}`}
        // dialogMessage1={`「${props.targetName}」のダウンロード可能な動画を作成します。`}
        dialogMessage1='ダウンロードの準備が完了しましたら'
        dialogMessage2={`「${user[0].email}」宛にメールにて通知いたします。`}
        // dialogMessage3={`「${props.targetName}」のダウンロード動画を作成してよろしいですか？`}
        handleAction={handleAction}
        handleCancel={handleCancel}
        buttonName={'ダウンロード準備'}
      />
      <ProgressModal
        modalIsOpen={modalIsOpen}
        isFinal={true}
        progress={0}
        text1={progressMsg + '処理中です...'}
        text2={'この処理は数分かかる場合があります...'}
      />
  </>
  );
}
