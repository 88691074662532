import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function IconImportant(props) {

  return (
    <SvgIcon
      sx={{ width: props.width, height: props.height }}
      viewBox='0 0 18 18'>
      <path d="M17.9061 14.2101L10.1104 0.738382C9.54073 -0.246127 8.60852 -0.246127 8.03884 0.738382L0.243138 14.2101C-0.326547 15.1946 0.139559 16 1.27893 16H16.8703C18.0097 16 18.4758 15.1946 17.9061 14.2101ZM10.2216 13.3882C10.2216 13.6534 10.0041 13.8705 9.73822 13.8705H8.44107C8.17522 13.8705 7.9577 13.6534 7.9577 13.3882V11.9586C7.9577 11.6934 8.17522 11.4764 8.44107 11.4764H9.73822C10.0041 11.4764 10.2216 11.6934 10.2216 11.9586V13.3882ZM10.2216 9.66786C10.2216 9.93311 10.0041 10.1501 9.73822 10.1501H8.44107C8.17522 10.1501 7.9577 9.93311 7.9577 9.66786V5.32128C7.9577 5.05603 8.17522 4.83901 8.44107 4.83901H9.73822C10.0041 4.83901 10.2216 5.05603 10.2216 5.32128V9.66786Z" fill="#4d4d4d" />
    </SvgIcon>
  );
}
