import { BG_COLOR, HAZARD, RISK} from '../const/risk_style'

export default function getPolyLines(
  routes
) {
  let polylineList = []
  if (routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].latitude === null) {
        continue
      }
      const start_position = [routes[i].latitude, routes[i].longitude]
      let end_position = start_position
      if (i < routes.length - 1) {
        end_position = [routes[i + 1].latitude, routes[i + 1].longitude]
      }
      const lineObj = {
        risk_level: routes[i].risk_level,
        start_position: start_position,
        end_position: end_position,
        risk_level_style: risk_level_style(
          routes[i].risk_level)
      }
      polylineList.push(lineObj)
      polylineList.sort((a, b) => a.risk_level - b.risk_level)
    }
  }
  console.log(polylineList)
  return polylineList

}

const risk_level_style = (level) => {
  let color = BG_COLOR.WHITE
  switch (level) {
    case 0:
      color = BG_COLOR.WHITE
      break
    case 1:
      color = BG_COLOR.LOW
      break
    case 2:
      color = BG_COLOR.MIDDLE
      break
    case 3:
      color = BG_COLOR.HIGHT
      break
    default:
      color = BG_COLOR.WHITE
      break
  }
  return { weight: 5, color: color }

}