export default async function generateMovieThumbnail(videoFile) {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(videoFile);

        video.onloadeddata = () => {
            let rate = 0;
            // 横長の動画
            if (video.videoHeight < video.videoWidth) {
                rate = 480 / video.videoWidth
            }
            // 縦長の動画
            else {
                rate = 270 / video.videoHeight
            }

            canvas.height = video.videoHeight * rate
            canvas.width = video.videoWidth * rate
            console.log(canvas.height)
            console.log(canvas.width)
            const context = canvas.getContext("2d");

            // 動画の一部をキャプチャしてサムネイルを生成
            requestAnimationFrame(function frame() {
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                // Canvasからblobを取得して解決
                canvas.toBlob((blob) => {
                    console.log(blob)
                    if (blob.size < 2436){
                        frame()
                    }else{
                        resolve(blob);
                    }
                });
            });
        };
        video.onerror = (error) => {
            reject(error);
        };
    });
}
