import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
  IconButton,
  Toolbar,
  Typography,
  Tooltip
} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EnhancedTableToolbar(props) {
  const { numSelected,
    setMessageDialogOpen,
    setDownloadDialogOpen,
    setDeleteDialogOpen,
    hasDownload,
    movies,
    setDownloadMessage1,
    setDownloadMessage2,
    setDownloadDisAbledList
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >

      {numSelected > 0 && (
        <>
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} 件選択中
          </Typography>
          {/* 複数ダウンロードは一時的に停止 */}
          {/* {hasDownload &&
            <Tooltip title="ダウンロード">
              <IconButton color="default" onClick={() => {
                const enable_movies = movies.filter(movie => movie.is_copyright)
                const disable_movies = movies.filter(movie => movie.is_copyright === false)
                let msg1 = ''
                let msg2 = ''
                if (disable_movies.length > 0){
                  const disable_titles = disable_movies.map(movie => movie.title);
                  setDownloadDisAbledList(disable_titles)
                  msg2= `選択されたファイル中、以下の${disable_movies.length}件はダウンロードできません。`
                }
                setDownloadMessage2(msg2)
                if (enable_movies.length > 0){
                  msg1= `${enable_movies.length}件のファイルをダウンロードします。よろしいですか？`
                  setDownloadDialogOpen(true)
                  setDownloadMessage1(msg1)
                }else{
                  msg1= `申し訳ございませんが、選択されたファイルはダウンロードできません。`
                  msg2= `しばらく時間を空けて再度お試しください。`
                  setDownloadMessage1(msg1)
                  setDownloadMessage2(msg2)
                  setMessageDialogOpen(true)
                }
              }}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          } */}
          <Tooltip title="削除" sx={{ ml: 2 }}>
            <IconButton color="default" onClick={() => {
              setDeleteDialogOpen(true)
            }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setMessageDialogOpen: PropTypes.func.isRequired,
  setDownloadDialogOpen: PropTypes.func.isRequired,
  setDeleteDialogOpen: PropTypes.func.isRequired,
  hasDownload: PropTypes.bool.isRequired,
  movies: PropTypes.array.isRequired,
  setDownloadMessage1: PropTypes.func.isRequired,
  setDownloadMessage2: PropTypes.func.isRequired,
};

