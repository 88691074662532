import React from 'react'
import {
    Alert,
    AlertTitle,
    Button,
    Divider,
    Grid,
    Stack,
} from "@mui/material";
import { chunkUpload } from '../../functions/chunk_upload';
import { CONTENTS_TYPE, UPLOAD_FILE } from '../../const/general'
import ChipDone from '../../atoms/chip_level_selected'
import UploadThumb from '../../items/upload_thumb'
import { TypographyGraySmallLeft, } from '../../style/typography'
import { BoxSpaceAroundMarginTB30 } from '../../style/box'


function UploadRetry(props) {

    const setProgressCount = () => {
        // エラー情報のoffsetに応じてプログレスを進めておく
        props.retryContents.map(retry => {
            const progressCount = Math.ceil(
                retry.offset / UPLOAD_FILE.CHUNK_SIZE)
            props.setCounter((prevCount) => prevCount + progressCount)
        })
    }

    const getChunkSumCount = () => {
        let chunkSumCount = 0
        props.retryContents.map(retry => {
            console.log(retry.movie.size)
            chunkSumCount += Math.ceil(retry.movie.size / UPLOAD_FILE.CHUNK_SIZE)
        })
        console.log(chunkSumCount)
        return chunkSumCount
    }

    const onSubmit = () => {

        // プログレス表示の準備
        props.setProgress(0)
        const chunkSumCount = getChunkSumCount();
        setProgressCount();
        props.setChunkSumCount(chunkSumCount)
        props.setIsOpen(true)

        props.retryContents.map((retry) => {
            // 分割アップロード
            chunkUpload(
                true,
                retry.movie,
                retry.item,
                props.setCounter,
                props.setSuccessContents,
                props.successContents,
                props.setErrorContents,
                props.errorContents
            );
        })
    }

    return (
        <>
            <Alert sx={{ mt: 3 }} severity="error">
                <AlertTitle>以下のファイルはアップロードに失敗しました。</AlertTitle>
                ネットワーク接続状態を確認して再度アップロードボタンを押してください。
            </Alert>
            <div >
                {props.retryContents.map((retry, id) => {
                    return (
                        <>
                            {id !== 0 && <Divider />}
                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                <Grid item xs={12} md={5}>
                                    <UploadThumb
                                        contentType={retry.movie.contentType}
                                        url={retry.movie.url}
                                    />
                                    {retry.movie.contentType === CONTENTS_TYPE.ZIP &&
                                        <Alert severity="error">
                                            <AlertTitle>画像群のzipフォルダのみアップロード可能です。
                                                他のファイルが含まれていないかご確認ください。</AlertTitle>
                                        </Alert>
                                    }
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Stack spacing={1}>
                                        <TypographyGraySmallLeft>タイトル：{retry.item.title}</TypographyGraySmallLeft>
                                        <TypographyGraySmallLeft>緯度経度：{retry.item.latitude} / {retry.item.longitude}</TypographyGraySmallLeft>
                                        <TypographyGraySmallLeft>説明：{retry.item.overview}</TypographyGraySmallLeft>
                                        <ChipDone level={retry.item.emergency_level} />
                                        <TypographyGraySmallLeft>ファイル名：{retry.movie.name}</TypographyGraySmallLeft>
                                        <TypographyGraySmallLeft>ファイル容量：{retry.movie.strSize}</TypographyGraySmallLeft>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </>
                    )
                })}
            </div>
            {props.retryContents.length > 0 &&
                <BoxSpaceAroundMarginTB30>
                    <Button
                        variant="contained"
                        sx={{
                            width: '100%',
                            backgroundColor: '#2c3e50',
                            '&:hover': {
                                backgroundColor: '#435e7a'
                            }
                        }}
                        onClick={() =>
                            onSubmit()
                        }
                    >アップロード</Button>
                </BoxSpaceAroundMarginTB30>
            }
        </>
    );
}
export default UploadRetry;
