import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, useMapEvents, Marker, useMap } from 'react-leaflet'
import Button from '@mui/material/Button';
import Modal from "react-modal";
import 'leaflet/dist/leaflet.css';
import Leaflet from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import ButtonCancel from '../../atoms/button_close'

import SearchByAddress from '../search_by_address'
import { DEFAULT_POSITION, DEFAULT_MAP_ZOOM } from '../../const/general';
import getAddress from '../../functions/get_address';
import { BoxSpaceAroundMarginTB10 } from '../../style/box'

// marker setting
let DefaultIcon = Leaflet.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [24, 37],
  iconAnchor: [12, 37],
});
Leaflet.Marker.prototype.options.icon = DefaultIcon;

const customStyles = {
  overlay: {
    zIndex: 100
  },
  content: {
    height: '75%',
    width: '70%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#E7EBF0'
  }
};
const UpdateMapCentre = (props) => {
  const map = useMap();
  const mapEvents = useMapEvents({
    zoomend: () => {
      props.setZoom(mapEvents.getZoom());
    },
  })
  useEffect(() => {
    map.openPopup(Leaflet.popup({
      offset: [0, -30],
      closeButton: false
    })
      .setLatLng(props.clickPosition)
      .setContent(props.address)
      .openOn(map)
    )
    map.flyTo(props.clickPosition, props.zoom)
  }, [props.clickPosition])
  return null
}

const MapModal = (props) => {

  // 初期マップ中心地点
  const lat = props.latitude ? props.latitude : DEFAULT_POSITION.LAT
  const lon = props.longitude ? props.longitude : DEFAULT_POSITION.LNG
  const position = [lat, lon];

  // 初期マップズームレベル
  const [zoom, setZoom] = useState(DEFAULT_MAP_ZOOM);

  // マーカー位置
  const [clickPosition, setClickPosition] = useState({
    lat: props.latitude,
    lng: props.longitude,
  });

  // ダイアログ表示時にマーカーと吹き出しをセットする
  useEffect(() => {
    async function fetchData() {
      const address = await getAddress(props.latitude, props.longitude)
      props.setAddress(address)
    }
    fetchData();
    setClickPosition({
      lat: props.latitude,
      lng: props.longitude,
    })
  }, [props.modalIsOpen])

  // 地図をクリック時にマーカーと吹き出しをセットする
  function LocationMarker() {
    useMapEvents({
      async click(e) {
        console.log(e.latlng);
        const address = await getAddress(e.latlng.lat, e.latlng.lng)
        setClickPosition(e.latlng)
        props.setAddress(address)
      },
    })
    return null
  }

  return (
    <>
      <Modal
        isOpen={props.modalIsOpen}
        style={customStyles}
      >
        <SearchByAddress
          setClickPosition={setClickPosition}
          setAddress={props.setAddress}
        />

        <MapContainer
          center={position}
          zoom={zoom}
          scrollWheelZoom={false}
          style={{ height: '80%' }}
        >
          <UpdateMapCentre
            mapCentre={position}
            clickPosition={clickPosition}
            address={props.address}
            zoom={zoom}
            setZoom={setZoom}
          />

          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LocationMarker />
          {clickPosition !== null && <Marker position={clickPosition}></Marker>}
        </MapContainer>
        <BoxSpaceAroundMarginTB10>
          <ButtonCancel onCancel={async () => {
            setClickPosition({
              lat: props.latitude,
              lng: props.longitude,
            })
            const address = await getAddress(
              props.latitude, props.longitude)
            props.setAddress(address)
            props.setModalIsOpen(false)
          }
          } text="キャンセル" />

          <Button
            variant="contained"
            sx={{
              width: '40%',
              backgroundColor: '#2c3e50',
              '&:hover': {
                backgroundColor: '#435e7a'
              }
            }}
            type="button"
            onClick={(e) => {
              e.preventDefault()
              console.log(clickPosition)

              const clickLat = Math.round(clickPosition.lat * 10000000) / 10000000
              const clickLng = Math.round(clickPosition.lng * 10000000) / 10000000

              props.setValue(props.setValueNameLat, clickLat)
              props.setValue(props.setValueNameLng, clickLng)

              props.setModalIsOpen(false)
            }
            }
          >この場所を選択する</Button>

        </BoxSpaceAroundMarginTB10>
      </Modal>
    </>
  )
}

export default MapModal;
