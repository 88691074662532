import React, { useState } from 'react'
import cliAuth from '../../functions/axios_cli';
import SendForHana from '../../functions/send_for_hana';
import ConfirmDialog from '../confirm_dialog'
import ProgressModal from '../progress_modal'

export default function DeleteDialog(props) {

  const [modalIsOpen, setIsOpen] = useState(false);
  const [progressMsg, setProgressMsg] = useState('');

  const handleCancel = () => {
    props.setConfirmOpen(false);
    props.setTargetFileId('');
    props.setTargetFileTitle('')
  };

  const handleAction = () => {
    props.setConfirmOpen(false)
    setIsOpen(true)
    setProgressMsg('削除')

    cliAuth.delete(props.url)
      .then(response => {
        console.log(props.url + ' delete success', response)
        props.setSnackBarMessage(`${props.dialogTitle}「${props.targetName}」を削除しました`)
        props.setSnackBarStatus('success')
        props.setOpenSnackBar(true)
        props.setTargetFileId('')
        props.setTargetFileTitle('')
        setIsOpen(false)
        setProgressMsg('')
        if (props.isSendForHana) {
          // HANAにデータ連携
          SendForHana()

        }
        props.reload()
      })
      .catch(err => {
        props.setSnackBarMessage(`${props.dialogTitle}「${props.targetName}」の削除に失敗しました`)
        props.setSnackBarStatus('error')
        props.setOpenSnackBar(true)
        props.setTargetFileId('')
        props.setTargetFileTitle('')
        props.setConfirmOpen(false)
        setIsOpen(false)
        setProgressMsg('')
        console.log(err);
      })
  }

  return (
    <>
      <ConfirmDialog
        confirmOpen={props.confirmOpen}
        setConfirmOpen={props.setConfirmOpen}
        dialogTitle={`${props.dialogTitle}の削除`}
        dialogMessage1={`「${props.targetName}」を削除します。`}
        dialogMessage2={props.additionalMessage}
        dialogMessage3={`この操作は取り消しできません。削除してよろしいですか？`}
        handleAction={handleAction}
        handleCancel={handleCancel}
        buttonName={'削除'}
      />
      <ProgressModal
        modalIsOpen={modalIsOpen}
        isFinal={true}
        progress={0}
        text1={progressMsg + '処理中です...'}
        text2={'この処理は数分かかる場合があります...'}
      />
    </>
  );
}
