import React, { useEffect, useRef, useState } from "react";

import videojs from "video.js";
import "videojs-vtt-thumbnails";
import "videojs-markers";
import "videojs-markers/dist/videojs.markers.css";

import "./thumb.css"
import ThumbnailList from '../video_thumbnail_list';

require("@silvermine/videojs-chromecast")(videojs);
require("@silvermine/videojs-airplay")(videojs);
require("@silvermine/videojs-quality-selector")(videojs);

export default function VideoPlayer(props) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { sources, chapters, selectContentUUID, contentsType, selectContent, selectChapterThumbnails, ...options } = props;
  const [duration, setDuration] = useState(0);

  const EditableMarkers = ({ text }) => {
    return <div>{text}</div>;
  };

  // シークバー上のマーカーを作成
  const getMarkers = () => {
    let markers = []
    chapters.map((chapter) => {
      markers.push(
        {
          time: chapter.start_time,
          text: chapter.caption_thumb,
          class: getClass(chapter.risk_level),
        }
      )
    })
    return markers
  }

  // リスクレベルに応じたマーカーのスタイルを返す
  const getClass = (risk_level) => {
    let classText = ''
    switch (risk_level) {
      case 1:
        classText = 'risk-marker1'
        break
      case 2:
        classText = 'risk-marker2'
        break
      case 3:
        classText = 'risk-marker3'
        break
      default:
        classText = ''
        break
    }
    return classText

  }
  useEffect(() => {

    if (videoRef.current) {
      console.log(sources);
      if (playerRef.current) {
        // プレイヤーの準備ができている場合に動画URLをセット
        console.log('already');
        console.log(sources);
        playerRef.current.src(sources)
        playerRef.current.bigPlayButton.show()

      } else {
        console.log('not already');
        // video-jsタグを作成してvideoRefの子要素として登録
        // videoRefはレンダー時に作成されて再作成されない
        // 動画の切り替えはplayerRefの中身を変えることで対応するし
        // 画像のコンポーネントに切り替えるときはplayerRefがアンマウントされる
        // playerRefがアンマウントされて破棄されてもvideoRefは健在
        const videoElement = document.createElement("video-js");
        videoRef.current.appendChild(videoElement);

        // プレイヤーコンポーネントを作成
        playerRef.current = videojs(videoElement, options);
        playerRef.current.chromecast();
        playerRef.current.airPlay();
        playerRef.current.controlBar.addChild("QualitySelector");
        playerRef.current.src(sources)
        playerRef.current.bigPlayButton.show()

        // チャプターマーカーの振る舞いを定義しておく
        playerRef.current.markers(
          {
            markerTip: {
              display: true,
              html: function (marker) {
                return <EditableMarkers text={marker.text} />;
              }
            },
            breakOverlay: {
              display: true,
              displayTime: 3,
              text: function (marker) {
                return marker.text;
              }
            },
            onMarkerReached: function (marker) {
              console.log(marker);
            },
            // ここでは具体的なマーカーは取得しない
            // 再生実行されたら取得する
            markers: getMarkers()
          }
        );


      }
      playerRef.current.on("play", () => {
        // 動画再生時に再生時間が更新される
        // そのタイミングでチャプターのマーカーを再設定する
        // 再設定自体は useEffect で処理している 
        if (playerRef.current.duration()) {
          setDuration(playerRef.current.duration())
        }
        console.log('play');
        playerRef.current.bigPlayButton.hide()
      });

      playerRef.current.on("pause", () => {
        console.log('pause');
        playerRef.current.bigPlayButton.show()
      });

      playerRef.current.on("waiting", () => {
        console.log('waiting');
        playerRef.current.bigPlayButton.show()
      });
    }
  }, [sources]);


  // 動画再生時間が変更されたら、チャプターマーカーを再設定する
  useEffect(() => {
    if (videoRef.current) {
      if (playerRef.current) {
        console.log(playerRef.current.duration());
        const markers = getMarkers()
        console.log(markers);
        playerRef.current.markers.reset(markers);
      }
    }
  }, [duration]);

  // Imageコンポーネントに切り替わる時にプレーヤーはアンマウントされる
  // コンポーネントのアンマウント時にプレーヤーを破棄
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
        console.log('player.isDisposed')
      }
    };
  }, [playerRef]);

  return (
    <React.Fragment>
      <div data-vjs-player>
        <div ref={videoRef} />
      </div>
      <ThumbnailList
        chapters={chapters}
        selectContentUUID={selectContentUUID}
        selectChapterThumbnails={selectChapterThumbnails}
        playerRef={playerRef}
      />
    </React.Fragment>
  );
}
