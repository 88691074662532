import React from 'react'
import { Controller } from 'react-hook-form'

import SelectLatlon from '../select_latlon'
import { InputText, } from '../../style/text_view'

function Affiliation(props) {
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue_affiliation}
        render={({ field }) => (
          <InputText
            {...field}
            size="small"
            label="ユーザー所属先組織名"
            variant="outlined"
            helperText={props.error_affiliation?.message}
            error={props.error_affiliation?.message.length > 0}
            onKeyUp={e => {
              if (e.key === 'Enter') e.preventDefault();
            }}
             InputLabelProps={{ shrink: field.value ? true :false }}
          />
        )}
      />
      <SelectLatlon
        setValue={props.setValue}
        getValues={props.getValues}
        latitude={props.latitude}
        longitude={props.longitude}
        setValueNameLat={props.setValueNameLat}
        setValueNameLng={props.setValueNameLng}
        buttonName="所属拠点を選択"
      />
    </>
  )
}

export default Affiliation;
