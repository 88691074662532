import {NewReleasesOutlined,
  ReportProblemRounded,
  ErrorOutlineOutlined,
} from '@mui/icons-material/'
import LandSlide from '../atoms/icon_land_slide'
import Flood from '../atoms/icon_flood'
import HouseFlood from '../atoms/icon_house_flood'
import Normal from '../atoms/icon_normal'
import Important from '../atoms/icon_important'
import Emergency from '../atoms/icon_emergency'
import ThreeD from '../atoms/icon_three_d'

export const BG_COLOR = {
  HIGHT: 'rgb(170,0,170)',
  MIDDLE: 'rgb(255, 40, 0)',
  LOW: 'rgb(242, 231, 0)',
  NONE: 'rgb(0,65,255)',
  THREE_D: 'forestgreen',
  WHITE: 'rgb(255,255,255)',
}
export const BG_UNSELECT_COLOR = {
  HIGHT: 'rgba(180, 0, 104, 0.4)',
  MIDDLE: '',
  LOW: 'rgba(255, 245, 0, 0.5)',
  NONE: 'rgba(0,65,255, 0.4)',
  THREE_D: 'forestgreen',
}
export const BG_HOVER_COLOR = {
  HIGHT: 'rgba(180, 0, 104, 0.6)',
  MIDDLE: '',
  LOW: 'rgba(255, 245, 0, 0.9)',
  NONE: 'rgba(0,65,255, 0.6)',
  THREE_D: 'mediumseagreen',
}

export const BORDER_STYLE = {
  HIGHT: '0.1px solid #9a0178',
  MIDDLE: '0.1px solid #db0505',
  LOW: '0.1px solid #d7cf01',
  NONE: '0.1px solid #0332bd',
  THREE_D: '0.1px solid darkgreen',
}
export const FONT_COLOR = {
  HIGHT: '#fff',
  MIDDLE: '#fff',
  LOW: '#4d4d4d',
  NONE: '#fff',
  THREE_D: '#fff',
}
export const FONT_HOVER_COLOR = {
  HIGHT: '#fff',
  MIDDLE: '#fff',
  LOW: 'rgba(77, 77, 77, 0.5)',
  NONE: '#fff',
  THREE_D: '#fff',
}
export let FONT__RISK = {
  HIGHT: '#fff',
  MIDDLE: '#fff',
  LOW: '#4d4d4d',
  NONE: '#fff',
}
export const HAZARD_NAME = {
  LAND_SLIDE: '土砂崩れ',
  FLOOD: '河川氾濫',
  HOUSE_FLOOD: '家の浸水',
}
export const HAZARD_ICON_SVG = {
  LAND_SLIDE: <LandSlide width={26} height={26} />,
  FLOOD: <Flood width={26} height={26} />,
  HOUSE_FLOOD: <HouseFlood width={26} height={26} />,
}
export const HAZARD_SMALL_ICON_SVG = {
  LAND_SLIDE: <LandSlide width={18} height={18} />,
  FLOOD: <Flood width={19} height={19} />,
  HOUSE_FLOOD: <HouseFlood width={20} height={20} />,
}

export const LEVEL_NAME = {
  EMERGENCY: '緊急',
  IMPORTANT: '重要',
  NORMAL: '提供',
  THREE_D: '3D',
}
export const LEVEL_ICON_SVG = {
  EMERGENCY: <Emergency width={20} height={20} />,
  IMPORTANT: <Important width={18} height={18} />,
  NORMAL: <Normal width={18} height={18} />,
  THREE_D: <ThreeD width={20} height={20} />,
}
export const LEVEL_SMALL_ICON_SVG = {
  EMERGENCY: <Emergency width={15} height={15} />,
  IMPORTANT: <Important width={14} height={14} />,
  NORMAL: <Normal width={15} height={15} />,
  THREE_D: <ThreeD width={15} height={15} />,
}
export const LEVEL_ICON_TAG = {
  EMERGENCY: <NewReleasesOutlined style={{fill: FONT_COLOR.HIGHT}} />,
  IMPORTANT:<ReportProblemRounded style={{fill: FONT_COLOR.LOW}} />,
  NORMAL:<ErrorOutlineOutlined style={{fill: FONT_COLOR.NONE}} />,
  THREE_D: <ThreeD width={20} height={20} />,
}
export const LEVEL_SMALL_ICON_TAG = {
  EMERGENCY: <NewReleasesOutlined style={{fill: FONT_COLOR.HIGHT}} />,
  IMPORTANT:<ReportProblemRounded style={{fill: FONT_COLOR.LOW}} />,
  NORMAL:<ErrorOutlineOutlined style={{fill: FONT_COLOR.NONE}} />,
  THREE_D: <ThreeD width={15} height={20} />,
}
export const LEVEL_ICON_TAG_HOVER = {
  EMERGENCY: <NewReleasesOutlined style={{fill: FONT_HOVER_COLOR.HIGHT}} />,
  IMPORTANT:<ReportProblemRounded style={{fill: FONT_HOVER_COLOR.LOW}} />,
  NORMAL:<ErrorOutlineOutlined style={{fill: FONT_HOVER_COLOR.NONE}} />,
  THREE_D: <ThreeD width={20} height={20} />,
}
export const LEVEL_SMALL_ICON_TAG_HOVER = {
  EMERGENCY: <NewReleasesOutlined style={{fill: FONT_HOVER_COLOR.HIGHT}} />,
  IMPORTANT:<ReportProblemRounded style={{fill: FONT_HOVER_COLOR.LOW}} />,
  NORMAL:<ErrorOutlineOutlined style={{fill: FONT_HOVER_COLOR.NONE}} />,
  THREE_D: <ThreeD width={15} height={15} />,
}

export const ICON_INDEX = {
  EMERGENCY: 0,
  IMPORTANT: 1,
  NORMAL: 2,
  THREE_D: 3,
  LAND_SLID_RISK_LOW: 4,
  LAND_SLID_RISK_MIDDLE: 5,
  LAND_SLID_RISK_HIGHT: 6,
  FLOOD_RISK_LOW: 7,
  FLOOD_RISK_MIDDLE: 8,
  FLOOD_RISK_HIGHT: 9,
  HOUSE_FLOOD_LOW: 10,
  HOUSE_FLOOD_MIDDLE: 11,
  HOUSE_FLOOD_HIGHT: 12,
}
export const HAZARD ={
  LAND_SLID:0,
  FLOOD:2,
  HOUSE_FLOOD:7,
}
export const RISK = {
  LOW: 1,
  MIDDLE: 2,
  HIGHT: 3,
}
