import {
  CONTENTS_TYPE,
  CONTENTS_URL
} from '../const/general'

export const setSelectContentSources = (content) => {
  let contentSources = []

  if (content.is_copyright) {
    // コピーライトあり 動画
    // hlsのsourceを指定
    contentSources.push(
      setHlsSouece(CONTENTS_URL.MOVIE_HLS, content.contents_uuid))
  } else {
    // コピーライトなし
    // オリジナルのsourceを指定
    contentSources.push(
      setOriginalSouece(CONTENTS_URL.MOVIE_ORIGINAL, content.file_name))
  }
  return contentSources
}
const setOriginalSouece = (url, fileName) => {
  const source = {
    src: url + fileName + '#t=0.1',
    type: "video/mp4"
  }
  return source;
}
const setHlsSouece = (url, contents_uuid) => {
  const source = {
    src: url + contents_uuid + '.m3u8#t=0.1',
    type: "application/x-mpegURL"

  }
  return source;
}
