import React, { useState } from 'react'
import { useForm, useFieldArray } from "react-hook-form";
import { Button, Divider, Grid, Stack, FormControl, FormHelperText } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import ErrorMsg from '../../atoms/error_msg'
import InputField from '../../atoms/input_field';
import InputOverview from '../../atoms/input_overview';
import Checkbox3D from '../../atoms/checkbox_3d';
import CenterSubmit from '../../items/center_submit';
import SelectLatlon from '../../items/select_latlon'
import SelectLevel from '../../items/select_level'
import SelectSurvey from '../../items/select_survey_upload';
import SelectTags from '../../items/select_tags_upload';
import UploadThumbForm from '../upload_thumb_form'

import { UPLOAD_FILE, EMERGENCY_LEVEL } from '../../const/general'
import { TITLE, OVERVIEW, FLIGHT_LOG, REGISTER_ID } from '../../const/roules';
import { BoxFlexStartWrap, BoxSpaceBetweenMarginBR } from '../../style/box';
import { TypographyGrayNomal, } from '../../style/typography'
import { SelectedTagChip } from '../../style/chip';

import { chunkUpload } from '../../functions/chunk_upload';
import { DispBlockPc } from '../../const/style'


function Movie(props) {
    let default_title = ''
    let default_latitude = 0
    let default_longitude = 0
    let default_overview = ''
    if (props.selectSurveyInfo !== null) {
        default_title = props.selectSurveyInfo.title
        default_latitude = props.selectSurveyInfo.latitude
        default_longitude = props.selectSurveyInfo.longitude
        default_overview = props.selectSurveyInfo.overview
    }

    // const [isPermit3D_01, setIsPermit3D_01] = useState(false);
    // const [isPermit3D_02, setIsPermit3D_02] = useState(false);
    // const [isPermit3D_03, setIsPermit3D_03] = useState(false);
    // const [isPermit3D_04, setIsPermit3D_04] = useState(false);
    // const [isPermit3D_05, setIsPermit3D_05] = useState(false);
    // const [isPermit3D_06, setIsPermit3D_06] = useState(false);
    // const [isPermit3D_07, setIsPermit3D_07] = useState(false);
    // const [isPermit3D_08, setIsPermit3D_08] = useState(false);
    // const [isPermit3D_09, setIsPermit3D_09] = useState(false);
    // const [isPermit3D_10, setIsPermit3D_10] = useState(false);

    const {
        register,
        setValue,
        getValues,
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        mode: "onBlur"
    });

    const { fields, remove } = useFieldArray({
        name: "itemRows",
        control
    });

    // itemRowsの入力状態を監視させる
    const watchFieldArray = watch("itemRows");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const getChunkSumCount = () => {
        let chunkSumCount = 0
        props.movies.map(movie => {
            console.log(movie.size)
            chunkSumCount += Math.ceil(movie.size / UPLOAD_FILE.CHUNK_SIZE)
        })
        console.log(chunkSumCount)
        return chunkSumCount
    }

    const onSubmit = data => {

        // プログレス表示の準備
        props.setProgress(0)
        const chunkSumCount = getChunkSumCount();
        props.setChunkSumCount(chunkSumCount)
        props.setIsOpen(true)

        data.itemRows.map((item, id) => {
            // 調査依頼の場合は指定されたタグUUIDをセットする
            if (props.selectTagList.length > 0) {
                item.tag = props.selectTagList.map((value) => {
                    return value.tag_uuid;
                });
            }
            // 分割アップロード
            chunkUpload(
                props.movies[id],
                item,
                props.info1,
                props.info2,
                props.setCounter,
                props.setSuccessContents,
                props.successContents,
                props.setRetryContents,
                props.retryContents
            );
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div >
                    {props.movies.map((movie, id) => (
                        <div key={id}>
                            {id !== 0 && <Divider sx={{ margin: '10px' }} />}
                            {/* 調査依頼の場合は調査は選択しない */}
                            {props.selectSurveyInfo === null &&
                                <SelectSurvey
                                    surveyList={props.surveyList}
                                    id={id}
                                    setValue={setValue}
                                />
                            }
                            <BoxSpaceBetweenMarginBR>
                                <TypographyGrayNomal>
                                    {id + 1}：{movie.name}（{movie.strSize}）
                                </TypographyGrayNomal>
                                <Button variant="contained"
                                    size='small'
                                    startIcon={<ClearIcon />}
                                    sx={{
                                        color: '#333',
                                        boxShadow: 'none',
                                        backgroundColor: 'rgb(0 0 0 / 10%)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.12)'
                                        }
                                    }}

                                    onClick={(e) => {
                                        e.preventDefault()
                                        props.deleteMovies(movie.id)
                                        remove(id)
                                    }}
                                >削除</Button>
                            </BoxSpaceBetweenMarginBR>
                            <Grid container spacing={2} sx={{ paddingRight: '20px' }}>
                                <Grid item xs={12} md={5}>
                                    <UploadThumbForm
                                        id={id}
                                        name={movie.name}
                                        fileSize={movie.strSize}
                                        contentType={movie.contentType}
                                        url={movie.url}
                                        thumbnailUrl={movie.thumbnailUrl}
                                        movieID={movie.id}
                                        deleteMovies={props.deleteMovies}
                                        useFieldArray={useFieldArray}
                                        setValue={setValue}
                                        getValues={getValues}
                                        setValueName_routeLog={`itemRows.${id}.route_log`}
                                        setValueName_routeLogFileName={`itemRows.${id}.route_log_file_name`}
                                        setValueName_registerId={`itemRows.${id}.register_id`}

                                        register_routeLog={register(`itemRows.${id}.route_log`)}
                                        register_routeLogFileName={register(`itemRows.${id}.route_log_file_name`,
                                            FLIGHT_LOG(watch(`itemRows.${id}.register_id`, "")))}
                                        register_registerId={register(`itemRows.${id}.register_id`,
                                            REGISTER_ID(watch(`itemRows.${id}.route_log_file_name`, "")))}

                                        error_registerId={errors.itemRows?.[id]?.register_id}
                                        error_routeLogFileName={errors.itemRows?.[id]?.route_log_file_name}
                                    />
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Stack spacing={2}>
                                        <InputField
                                            register={register(`itemRows.${id}.title`, TITLE)}
                                            defaultValue={default_title}
                                            placeholder="タイトル"
                                            error={errors.itemRows?.[id]?.title}
                                        />
                                        <SelectLatlon
                                            setValue={setValue}
                                            getValues={getValues}
                                            latitude={default_latitude}
                                            longitude={default_longitude}
                                            setValueNameLat={`itemRows.${id}.latitude`}
                                            setValueNameLng={`itemRows.${id}.longitude`}
                                            buttonName="撮影場所を選択"
                                        />
                                        {errors.itemRows?.[id]?.latitude && <ErrorMsg message={errors.itemRows?.[id]?.latitude.message} />}
                                        <InputOverview
                                            register={register(`itemRows.${id}.overview`, OVERVIEW)}
                                            overview={default_overview}
                                            error={errors.itemRows?.[id]?.overview}
                                        />
                                        {props.selectSurveyInfo !== null ?
                                            // 調査依頼の場合はタグは固定
                                            <FormControl>
                                                <FormHelperText>災害種別タグ</FormHelperText>
                                                <BoxFlexStartWrap>
                                                    {props.selectTagList.map((tagInfo, id) => (
                                                        <SelectedTagChip
                                                            key={id}
                                                            label={tagInfo.tag_names}
                                                        />
                                                    ))}
                                                </BoxFlexStartWrap>
                                            </FormControl>
                                            :
                                            // 調査依頼以外の場合、タグは選択可
                                            <SelectTags
                                                tagList={props.activeTags}
                                                id={id}
                                                setValue={setValue}
                                                getValues={getValues}
                                            // register={register(`itemRows.${id}.tag_input`)}
                                            />
                                        }
                                        <SelectLevel
                                            emergencyLevel={getValues(`itemRows.${id}.emergency_level`) ?
                                                getValues(`itemRows.${id}.emergency_level`) : EMERGENCY_LEVEL.NORMAL}
                                            setValue={setValue}
                                            setValueName={`itemRows.${id}.emergency_level`}
                                        />
                                        {/* <FormControlLabel control={
                                            <Checkbox
                                                {...register(`itemRows.${id}.is_permit3d`)}
                                                checked={isPublic}
                                                onChange={(e) => {
                                                    console.log('isPublic::::::::::::::' + isPublic)
                                                    console.log('e.target.checked::::::::::::::' + e.target.checked)

                                                    setIsPublic(e.target.checked)
                                                }
                                                }
                                            />} label="3Dを作成する" /> */}
                                        <DispBlockPc>
                                            <Checkbox3D
                                                register={register(`itemRows.${id}.is_permit3d`)}
                                                setValue={setValue}
                                                getValues={getValues}
                                                isPermit3d={movie.is_permit3d}
                                                contentType={movie.contentType}
                                                setValueName={`itemRows.${id}.is_permit3d`}
                                            />
                                        </DispBlockPc>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </div>
                {props.movies.length > 0 && <CenterSubmit width={100} text={'アップロード'} />}
            </form>
        </>
    );
}
export default Movie;
