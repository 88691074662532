import cliAuth from './axios_cli';

export default function SendForHana(

) {
  cliAuth.post(
    `create_for_hana_info/`
  ).then(response => {
    console.log(response.data)
    cliAuth.post(
      `send_for_hana/`
    ).then(response => {
      console.log(response.data)
    })
      .catch(error => {
        console.log(error)
      })
  })
    .catch(error => {
      console.log(error)
    })
}