import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Stack } from "@mui/material";

import InputField from '../../atoms/input_field';
import RoleCheck from '../../items/user_role_check';
import PasswordPair from '../../items/user_password_pair';
import Affiliation from '../../items/user_affiliation';
import CancelSubmit from '../../items/cancel_submit';

import { PARAM_NAME, DEFAULT_POSITION } from '../../const/general'
import { E_MAIL, AFFILIATION, PASSWORD_REPEAT, PASSWORD_REQUIRED } from '../../const/roules';

import PostData from '../../functions/post_data';

function UserInfoForm(props) {
  const { control, register, handleSubmit, setValue, getValues, formState: { errors }, watch, reset } = useForm({ mode: 'onBlur' });

  const [isAdmin, setIsAdmin] = useState(false);
  const [isPostman, setIsPostman] = useState(false);
  const [isStaff, setIsStaff] = useState(true);

  const password = useRef({});
  password.current = watch("password", "");
  const email = useRef({});
  email.current = watch("email", "");

  // ユーザー登録成功時の処理
  const onSuccess = async (response, proc, setSuccessMessage) => {
    onCancel()
    setSuccessMessage(`ユーザー：${response.data.email}を${proc}しました`)
  }

  // ユーザー登録失敗時の処理
  const onError = (err, proc) => {
    if (err.response.data.password) {
      props.setSnackBarMessage(err.response.data.password)
    } else if (err.response.data.email) {
      const error = err.response.data.email
      const errorMessage = error.toString().replace(/\s+/g, '')
      props.setSnackBarMessage(errorMessage)
    } else {
      props.setSnackBarMessage('ユーザー作成に失敗しました。')
    }
  }
  // フォームリセット
  const onCancel = () => {
    reset()
    setValue('latitude', DEFAULT_POSITION.LAT)
    setValue('longitude', DEFAULT_POSITION.LNG)
    setIsAdmin(false)
    setIsPostman(false)
    setIsStaff(true)
  }
  const onSubmit = async (data) => {
    if (data.latitude === undefined || data.longitude === undefined
      || data.latitude === 0 || data.longitude === 0) {
      props.setSnackBarMessage('所属拠点が選択されていません')
      props.setSnackBarStatus('error')
      props.setOpenSnackBar(true)
      return
    }

    // チェックボックスの値は明示的に渡さないと
    // iPhoneからの操作の際に値が反映されないた明示的に渡す
    data.is_admin=isAdmin;
    data.is_staff=isStaff
    data.is_postman=isPostman
    
    if (!data.is_admin && !data.is_staff && !data.is_postman) {
      props.setSnackBarMessage('開発者、投稿者、閲覧者のいずれかを選択してください')
      props.setSnackBarStatus('error')
      props.setOpenSnackBar(true)
      return
    }
    PostData
      (
        data,
        'ユーザー',
        'users/',
        props.setSnackBarMessage,
        props.setSnackBarStatus,
        props.setOpenSnackBar,
        props.setSnackBarMessage,
        props.setSnackBarStatus,
        props.setOpenSnackBar,
        onSuccess,
        onError
      )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ marginTop:3 ,marginBottom: 3 }}>
          <InputField
            register={register('email', E_MAIL)}
            defaultValue=''
            label="ユーザーメールアドレス"
            error={errors.email}
          />
          <Affiliation
            name='affiliation'
            control={control}
            register_affiliation={register('affiliation', AFFILIATION)}
            defaultValue_affiliation={props.affiliation}
            error_affiliation={errors.affiliation}
            setValue={setValue}
            getValues={getValues}
            latitude={0}
            longitude={0}
            setValueNameLat={PARAM_NAME.LAT}
            setValueNameLng={PARAM_NAME.LNG}
          />
          <RoleCheck
            register_admin={register('is_admin')}
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            register_postman={register('is_postman')}
            isPostman={isPostman}
            setIsPostman={setIsPostman}
            register_staff={register('is_staff')}
            isStaff={isStaff}
            setIsStaff={setIsStaff}
          />
          <PasswordPair
            register_password1={register('password', PASSWORD_REQUIRED(email))}
            error_password1={errors.password}
            register_password2={register('password_repeat', PASSWORD_REPEAT(password))}
            error_password2={errors.password_repeat}
          />
          <CancelSubmit
            onCancel={onCancel}
            text='登録' />
        </Stack>
      </form>
    </>
  )
}

export default UserInfoForm;
