import React, { useState, useEffect } from 'react'
import {
  Checkbox,
  TableCell,
  Collapse,
  TableRow,
} from "@mui/material";
import { TypographyBreakWordSmall, } from '../../style/typography'
import { SmallNotPublicChip } from '../../style/chip';

import MovieInfoForm from '../../contents/settings_contents_form'
import TableRowButton from '../table_row_button'
import ContentsImage from '../contents_image'
import TagList from '../tag_list'
import getContentsPath from '../../functions/get_contents_path';


export default function Row(props) {

  const [open, setOpen] = useState(false);
  const isItemSelected = props.isSelected(props.row.id);
  const thumbnail = getContentsPath(props.row.contents_type, props.row.contents_uuid, props.row.file_name, props.row.is_copyright)

  useEffect(() => {
    setOpen(false)
  }, [props.page]);

  return (
    <React.Fragment >
      {/* タブレット以上の画面用 */}
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        sx={{
          display: { xs: 'none', sm: 'block', md: 'brock' },
        }}
      >
        <TableCell padding="checkbox" sx={{ borderBottom: 'none' }}
          onClick={(event) => props.handleClick(props.row)}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onChange={(event) => props.handleClick(props.row)}
          />
        </TableCell>

        {/* 詳細を開いた際はサムネイルとタイトルは非表示 */}
        <TableCell sx={{ borderBottom: 'none' }}>
          {!open &&
            <ContentsImage
              imgSrc={thumbnail}
              handleClick={props.handleClick}
              row={props.row}
              width='20vw'
              maxWidth='200px'
              height='100px'
            />

          }
        </TableCell>
        {open ?
          <TableCell
            sx={{
              width: { sm: '50%', md: '50%' },
              padding: { ms: '8px', sm: '16px' },
              borderBottom: 'none'
            }}
          ></TableCell>
          :
          <TableCell
            sx={{
              width: { sm: '50%', md: '50%' },
              padding: { ms: '8px', sm: '16px' },
              borderBottom: 'none'
            }}

            onClick={(event) => props.handleClick(props.row)}>
            <TypographyBreakWordSmall>{props.row.title}</TypographyBreakWordSmall>
            <TagList tags={props.row.tags} />
            {!props.row.is_public &&
              <SmallNotPublicChip label='非公開' title='コンテンツは非公開です' />
            }
          </TableCell>
        }
        {open ?
          <TableCell
            sx={{
              width: { sm: '50%', md: '50%' },
              padding: { ms: '8px', md: '16px' },
              borderBottom: 'none'
            }}></TableCell>
          :
          <TableCell
            sx={{
              width: { sm: '50%', md: '50%' },
              padding: { ms: '8px', md: '16px' },
              borderBottom: 'none'
            }}
            onClick={(event) => props.handleClick(props.row)}>
            {props.row.affiliation}</TableCell>
        }

        <TableRowButton
          id={props.row.id}
          title={props.row.title}
          is_copyright={props.row.is_copyright}
          contents_type={props.row.contents_type}
          movie_download_status={props.row.movie_download_status}
          contents_uuid={props.row.contents_uuid}
          file_name={props.row.file_name}
          open={open}
          setOpen={setOpen}
          setTargetId={props.setTargetFileId}
          setTargetTitle={props.setTargetFileTitle}
          setDeleteConfirmOpen={props.setSingleDeleteConfirmOpen}
          hasDownload={props.hasDownload}
          setSingleDownloadDialogOpen={props.setSingleDownloadDialogOpen}
          setDownloadMessage1={props.setDownloadMessage1}
        />
      </TableRow>
      {/* スマホ画面用 */}
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        sx={{
          display: { xs: 'block', sm: 'none', md: 'none' },
        }}          >
        <TableCell
          sx={{
            borderBottom: 'none'
          }}
          padding="checkbox"
          onClick={(event) => props.handleClick(props.row)}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onChange={(event) => props.handleClick(props.row)}
          />
        </TableCell>

        {/* 詳細を開いた際はサムネイルとタイトルは非表示 */}
        <TableCell
          sx={{
            width: '50vw',
            borderBottom: 'none'
          }}>
          {!open &&
            <>
              <ContentsImage
                imgSrc={thumbnail}
                handleClick={props.handleClick}
                row={props.row}
                width='40vw'
                maxWidth='40vw'
                height='100px'
              />
              <TypographyBreakWordSmall>{props.row.title}</TypographyBreakWordSmall>
              <TypographyBreakWordSmall>{props.row.affiliation}</TypographyBreakWordSmall>
              {!props.row.is_public &&
                <SmallNotPublicChip label='非公開' title='コンテンツは非公開です' />
              }

            </>
          }
        </TableCell>

        <TableRowButton
          id={props.row.id}
          title={props.row.title}
          is_copyright={props.row.is_copyright}
          contents_type={props.row.contents_type}
          movie_download_status={props.row.movie_download_status}
          contents_uuid={props.row.contents_uuid}
          file_name={props.row.file_name}
          open={open}
          setOpen={setOpen}
          setTargetId={props.setTargetFileId}
          setTargetTitle={props.setTargetFileTitle}
          setDeleteConfirmOpen={props.setSingleDeleteConfirmOpen}
          hasDownload={props.hasDownload}
          setSingleDownloadDialogOpen={props.setSingleDownloadDialogOpen}
          setDownloadMessage1={props.setDownloadMessage1}
        />
      </TableRow>
      <TableRow>
        <TableCell sx={{
          paddingBottom: '0px', paddingTop: '0px',
          paddingRight: { xs: '0px', sm: 'none', md: 'none' },
          paddingLeft: { xs: '0px', sm: 'none', md: 'none' },
        }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <MovieInfoForm
              proc_type='update'
              id={props.row.id}
              contents_uuid={props.row.contents_uuid}
              thumbnail={thumbnail}
              title={props.row.title}
              latitude={props.row.latitude}
              longitude={props.row.longitude}
              overview={props.row.overview}
              assignTags={props.row.tags}
              activeTags={props.activeTags}
              emergency_level={props.row.emergency_level}
              is_public={props.row.is_public}
              is_permit3d={props.row.is_permit3d}
              contentType={props.row.contents_type}
              setOpen={setOpen}
              setSnackBarMessage={props.setSnackBarMessage}
              setSnackBarStatus={props.setSnackBarStatus}
              setOpenSnackBar={props.setOpenSnackBar}
              reload={props.reload}
              submitButtonName="更新"
              open={open} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
