import React from 'react';
import { Box } from '@mui/material';

import { ListImage, } from '../../style/image'

export default function ContentsImage(props) {

    return (
        <>
            <Box sx={{
                width: props.width,
                maxWidth: props.maxWidth,
            }}>
                <ListImage
                    srcSet={props.imgSrc}
                    loading="lazy"
                    onClick={() => props.handleClick(props.row)} />
            </Box>
        </>
    )
}
