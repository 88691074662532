import React, { useState, useEffect } from 'react'
import Modal from "react-modal";
import 'leaflet/dist/leaflet.css';
import PlaceIcon from '@mui/icons-material/Place';
import MapModal from '../map_modal'
import { DEFAULT_POSITION } from '../../const/general'
import Button from '@mui/material/Button';
import getAddress from '../../functions/get_address';
import { Stack } from '@mui/material';
import { TypographyNomal, TypographyGraySmall } from '../../style/typography'

Modal.setAppElement("#root");

function SelectLatlon(props) {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [address, setAddress] = useState('');

  let default_lat = DEFAULT_POSITION.LAT
  let default_lng = DEFAULT_POSITION.LNG

  if (props.latitude !== 0 && props.latitude !== 0) {
    default_lat = props.latitude
    default_lng = props.longitude
  }

  let latitude = parseFloat(props.getValues(props.setValueNameLat) ?
    props.getValues(props.setValueNameLat) : default_lat);
  let longitude = parseFloat(props.getValues(props.setValueNameLng) ?
    props.getValues(props.setValueNameLng) : default_lng);

  // 初期値の設定
  useEffect(() => {
    props.setValue(props.setValueNameLat, latitude )
    props.setValue(props.setValueNameLng, longitude )
    async function fetchData() {
      const address = await getAddress(latitude, longitude)
      setAddress(address)
    }
    fetchData();
  }, [latitude, longitude])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Button variant="contained"
          startIcon={<PlaceIcon />}
          sx={{
            color: '#fff',
            backgroundColor: '#6c8eaf',
            '&:hover': {
              backgroundColor: '#6c8eafde'
            }
          }}

          onClick={(e) => {
            e.preventDefault()
            setModalIsOpen(true)
          }}
        >{props.buttonName}</Button>
        <MapModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setValue={props.setValue}
          getValues={props.getValues}
          latitude={latitude}
          longitude={longitude}
          setValueNameLat={props.setValueNameLat}
          setValueNameLng={props.setValueNameLng}
          address={address}
          setAddress={setAddress}
        />
        <Stack>
          <TypographyNomal >{address} </TypographyNomal>
          <TypographyGraySmall >{`${latitude} / ${longitude}`}</TypographyGraySmall>
        </Stack>
      </Stack>
    </>
  )
}

export default SelectLatlon;
