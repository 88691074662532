import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

ErrorMessage.propTypes = {
  children : PropTypes.string
}

// style定義
const ErrorMsg = styled.span`
color: red;
`

export default function ErrorMessage(props){
  // 引数を取得
  const {message} = props
  return (
    <ErrorMsg>{message}</ErrorMsg>
  )

}