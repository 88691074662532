import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography
} from "@mui/material";

export default function MessageDialog(props) {

  return (
    <>
      <Dialog
        open={props.confirmOpen}
        onClose={props.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          {props.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Typography sx={props.messageStyle} >{props.dialogMessage1}</Typography>
          <Typography sx={props.messageStyle} >{props.dialogMessage2}</Typography>
          <Typography sx={props.messageStyle} >{props.dialogMessage3}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleMessageClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
