import React, { useState } from "react";
import style from 'styled-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Transition } from "react-transition-group";
import { Avatar } from '@mui/material';

import AvatarIcon from '../../atoms/avatar_icon'
import TextLabel from '../../atoms/text_label'
import { TypographyNomal } from '../../style/typography'
import ChipDone from '../../atoms/chip_level_selected'
import TagList from '../tag_list'
import Common from '../../functions/common';
import hazardIconIndex from '../../functions/get_hazard_icon';
import { GENERATE_3D_STATUS, URL } from '../../const/general';
import { UnselectedLevelChip } from '../../style/chip';
import { LEVEL_ICONS } from '../../const/avater_icon';
import {
    BoxSpaceBetween,
    BoxSpaceAround,
    BoxFlexEndTop,
    StackTB5LR30
} from '../../style/box'

const MovieTitle = style.div`
font-weight: bold;
font-size: 1.3rem;
text-align: left;
padding-left: 8px;
width:auto;
`
const InnerContainer = style.div`
margin-top: 6px;
display: flex;
justify-content: space-between;
`
export default function ContentInfo(props) {
    const [showAllInfo, setShowAllInfo] = useState(false);

    const minHeight = '60px'

    const transitionIcon = {
        entering: { transform: 'rotate(180deg)', transition: 'all 0.5s 0s ease' },
        entered: { transform: 'rotate(180deg)', transition: 'all 0.5s 0s ease' },
        exiting: { transition: 'all 0.5s 0s ease' },
        exited: { transition: 'all 0.5s 0s ease' },
    }
    const transitionBody = {
        entering: { height: 'auto', transition: 'all 0.5s 0s ease' },
        entered: { height: 'auto', transition: 'all 0.5s 0s ease' },
        exiting: { height: minHeight, overflow: "hidden", transition: 'all 0.5s 0s ease' },
        exited: { height: minHeight, overflow: "hidden", transition: 'all 0.5s 0s ease' },
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    // 最大リスクアイコン取得
    const icon = hazardIconIndex(props.selectContent.hazard, props.selectContent.max_risk_level);
    return (
        <Transition in={showAllInfo} timeout={1000} >
            {(state) => {
                return (
                    <>
                        <StackTB5LR30
                            style={transitionBody[state]}
                            className={showAllInfo ? 'entered' : ''}
                        >
                            <BoxSpaceBetween>
                                <BoxSpaceAround>
                                    <Avatar
                                        type='button'
                                        title={showAllInfo ? '情報を隠す' : 'すべての情報を表示'}
                                        sx={{
                                            bgcolor: '#666',
                                            width: 30,
                                            height: 30,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => { setShowAllInfo(!showAllInfo) }} >
                                        <ExpandMoreIcon style={transitionIcon[state]} />
                                    </Avatar>
                                    <MovieTitle>{props.selectContent.title}</MovieTitle>
                                </BoxSpaceAround>
                                <BoxFlexEndTop>
                                    <div style={{ display: 'none' }}>
                                        <AvatarIcon iconType={props.selectContent.emergency_level} />
                                    </div>
                                    {icon !== null &&
                                        <AvatarIcon iconType={icon} />
                                    }
                                    {props.selectContent.generate_3d_status === GENERATE_3D_STATUS.DONE &&
                                        <UnselectedLevelChip
                                            type='button'
                                            title='3D画像を表示'
                                            label={LEVEL_ICONS[GENERATE_3D_STATUS.DONE].TEXT}
                                            onClick={() => { openInNewTab(URL.LAS_BASE + props.selectContent.contents_uuid + '.html') }}
                                            icon={LEVEL_ICONS[GENERATE_3D_STATUS.DONE].ICON_TAG_HOVER}
                                            level={GENERATE_3D_STATUS.DONE}
                                        />}
                                </BoxFlexEndTop>
                            </BoxSpaceBetween>
                            <TagList tags={props.selectContent.tags} />
                            <InnerContainer>
                                <ChipDone level={props.selectContent.emergency_level} />
                                <TextLabel text={
                                    Common.formatDateTime(new Date(props.selectContent.shoote_date), 'YYYY年MM月DD日 HH時mm分')
                                } />
                            </InnerContainer>
                            <TypographyNomal>{'撮影場所：' + props.selectContent.address}</TypographyNomal>
                            <TypographyNomal>{'UTMグリッド：' + props.selectContent.utm_grid}</TypographyNomal>
                            <TypographyNomal>{'標高：' + props.selectContent.elevation + ' m'}</TypographyNomal>
                            <TypographyNomal>{'撮影者：' + props.selectContent.affiliation}</TypographyNomal>
                            <TypographyNomal>{'概要：' + props.selectContent.overview}</TypographyNomal>
                        </StackTB5LR30>
                    </>
                )
            }}
        </Transition>
    );
}
