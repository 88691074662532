import React, { useState } from 'react'
import { BoxFlexStartMarginTBWrap } from '../../style/box';
import { TagChip, HoverTagChip } from '../../style/chip';

export default function SelectSurvey(props) {

  const [selectUUID, setSelectUUID] = useState('');

  const onClick = (event, id, row, setValue) => {
    event.preventDefault()
    setSelectUUID(row.survey_uuid)
    console.log(id)
    console.log(row.latitude)
    setValue(`itemRows.${id}.title`, row.title);
    setValue(`itemRows.${id}.latitude`, row.latitude);
    setValue(`itemRows.${id}.longitude`, row.longitude);
    setValue(`itemRows.${id}.overview`, row.overview);
  }

  return (
    <BoxFlexStartMarginTBWrap>
      {props.surveyList.map((row, index) => (
        selectUUID === row.survey_uuid ?
          <HoverTagChip
            key={index}
            label={row.title}
            type="button"
            onClick={(e) => {onClick(e, props.id, row, props.setValue)}}
            />
          :
          <TagChip
            key={index}
            label={row.title}
            type="button"
            onClick={(e) => {onClick(e, props.id, row, props.setValue)}}
            />
      ))}
    </BoxFlexStartMarginTBWrap>
  )
}