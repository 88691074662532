import * as React from 'react';
import { SvgIcon } from '@mui/material';

export default function IconEmergency(props) {

  return (
    <SvgIcon
      sx={{ width: props.width, height: props.height }}
      viewBox='-1 -1 18 18'>
      <path d="M15.3047 8.76602C15.2259 8.55887 15.2259 8.32994 15.3047 8.12212L15.9409 6.43618C16.0912 6.03836 15.9461 5.58941 15.5936 5.35719L14.097 4.37089C13.9127 4.2495 13.7793 4.06345 13.7222 3.8497L13.2549 2.10801C13.1448 1.69766 12.766 1.41991 12.3455 1.44102L10.56 1.53107C10.3408 1.54163 10.1248 1.47137 9.95357 1.33183L8.56187 0.200394C8.23412 -0.0667979 7.76587 -0.0667979 7.4378 0.200394L6.0461 1.33183C5.87487 1.47104 5.65888 1.54163 5.43929 1.53107L3.65417 1.44102C3.23362 1.41991 2.8549 1.69766 2.74478 2.10801L2.27783 3.8497C2.22032 4.06345 2.087 4.2495 1.9027 4.37089L0.406114 5.35719C0.0535331 5.58974 -0.0912245 6.03869 0.0587612 6.43618L0.695301 8.12245C0.773725 8.32994 0.773725 8.5592 0.695301 8.76635L0.0587612 10.4526C-0.0912245 10.8498 0.0535331 11.2994 0.406114 11.5316L1.9027 12.5182C2.087 12.6393 2.22032 12.8247 2.27783 13.0391L2.74478 14.7808C2.8549 15.1915 3.23362 15.4689 3.65417 15.4478L5.43929 15.3581C5.65888 15.3465 5.87487 15.4178 6.0461 15.5566L7.4378 16.6887C7.76587 16.9556 8.23412 16.9556 8.56187 16.6887L9.9539 15.5566C10.1248 15.4178 10.3411 15.3465 10.5604 15.3581L12.3458 15.4478C12.7664 15.4689 13.1451 15.1915 13.2552 14.7808L13.7225 13.0391C13.7797 12.8247 13.9133 12.6393 14.0973 12.5182L15.5939 11.5316C15.9465 11.2991 16.0915 10.8498 15.9409 10.4526L15.305 8.76635L15.3047 8.76602ZM9.07097 12.3065C9.07097 12.5605 8.86511 12.7683 8.6135 12.7683H7.38584C7.13423 12.7683 6.92836 12.5605 6.92836 12.3065V10.9375C6.92836 10.6835 7.13423 10.4757 7.38584 10.4757H8.6135C8.86511 10.4757 9.07097 10.6835 9.07097 10.9375V12.3065ZM9.07097 8.74392C9.07097 8.99792 8.86511 9.20573 8.6135 9.20573H7.38584C7.13423 9.20573 6.92836 8.99792 6.92836 8.74392V4.58167C6.92836 4.32768 7.13423 4.11986 7.38584 4.11986H8.6135C8.86511 4.11986 9.07097 4.32768 9.07097 4.58167V8.74392Z" fill="white" />
    </SvgIcon>
  );
}
