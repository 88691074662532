import React from 'react'
import ContentsList from '../../../contents/contents_list'
import FailedToLoad from '../../failed_to_load'
import Proggress from '../../../items/progress'
import { GetActiveTags, GetUserContents } from '../../../functions/get_data_swr';

export default function SettingsContents() {

  const { activeTags, isActiveTagsLoading, isActiveTagsError } = GetActiveTags();
  const { userContents, isUserContentsLoading, isUserContentsError } = GetUserContents();
  if (isActiveTagsError) return <FailedToLoad />
  if (isUserContentsError) return <FailedToLoad />
  if (isActiveTagsLoading) return <Proggress />
  if (isUserContentsLoading) return <Proggress />

  return (
    <ContentsList
      reloadUrl={`user_contents/`}
      activeTags={activeTags}
      contentList={userContents}
      hasDownload = {false}
    />
  )
}
