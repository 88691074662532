import React from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import { COPYRIGHT_TEXT } from '../../const/config'

const WrapperLarge = styled.footer`
${media.greaterThan("large")`
display: block;
`}

${media.lessThan("large")`
display: none;
`} 

background-color: #061027;
color: #fff;
text-align: center;

& > p {
  margin: 0;
  line-height: 40px;
}
`
const WrapperSmall = styled.footer`
${media.greaterThan("large")`
display: none;
`}
${media.lessThan("large")`
display: block;
`} 

background-color: #061027;
color: #fff;
text-align: center;
position:fixed;
bottom:0;
left:0;
width:100%;
hight:15px;

& > p {
  margin: 0;
  font-size: 10px;
}
`

const Footer = () => {
  return (
    <>
      <WrapperLarge>
        <p><small>{COPYRIGHT_TEXT}</small></p>
      </WrapperLarge>
      <WrapperSmall>
        <p><small>{COPYRIGHT_TEXT}</small></p>
      </WrapperSmall>
    </>
  )
}
export default Footer;