import { TextField } from "@mui/material";
import styled from '@emotion/styled';

// テキスト入力フォームの共通スタイル
export const InputText = styled(TextField)(() => (
    {
        width: '100%',
        wordBreak: 'break-all',
        // padding: '5px 0 5px 0',
        '& label': {
            // padding: '5px 0 5px 0',
            color: '#AAAAAA',
        },
        '& fieldset':  {
            borderRadius:'2px',
        }
    }));
export const InputTextMB = styled(InputText)(() => (
    {
        padding: '10px 0 5px 0',
        '& label': {
            padding: '10px 0 5px 0',
            color: '#AAAAAA',
        },
    }));



