import React, { useState, useRef } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
} from "@mui/material";

import Row from '../../items/contents_row'
import MessageDialog from '../../items/message_dialog'
import DownloadDialog from '../../items/download_dialog'
import BulkDeleteDialog from '../../items/confirm_dialog'
import SingleDeleteDialog from '../../items/delete_dialog'
import MessageBar from '../../items/snackbar'
import ProgressModal from '../../items/progress_modal'
import EnhancedTableHead from '../../items/enhanced_table_head'
import EnhancedTableToolbar from '../../items/enhanced_table_toolbar'
import cliAuth from '../../functions/axios_cli';
import Common from '../../functions/common';
import { URL, CONTENTS_URL, CONTENTS_TYPE } from '../../const/general'
import { LoadMutate } from '../../functions/get_data_swr';
import SendForHana from '../../functions/send_for_hana';

import SingleCreateDownloadFileDialog from '../../items/single_download_dialog'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ContentsList(props) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [downloadMessage1, setDownloadMessage1] = useState('');
  const [downloadMessage2, setDownloadMessage2] = useState('');
  const [downloadDisAbledList, setDownloadDisAbledList] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [progressMsg, setProgressMsg] = useState('');
  const [singleDeleteConfirmOpen, setSingleDeleteConfirmOpen] = useState(false);
  const [targetFileId, setTargetFileId] = useState('');
  const [targetFileTitle, setTargetFileTitle] = useState('');

  const [singleDownloadDialogOpen, setSingleDownloadDialogOpen] = useState(false);

  // topへ戻る要素への参照
  const scrollToTopRef = useRef();

  const reload = () => {
    setSelected([])
    LoadMutate(props.reloadUrl)
  }

  {/* 複数ダウンロードは一時的に停止 */ }
  const handleDownload = () => {
    setDownloadDialogOpen(false)
    setIsOpen(true)
    setProgressMsg('ダウンロード')

    let fd_contents = new FormData()
    selected.map((id) => {
      console.log(id);
      if (id !== null) {
        fd_contents.append('contents_ids', id)
      }
    })
    cliAuth.post(URL.DOWNLOAD, fd_contents,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        // create file link in browser's memory
        const blob = new Blob([response.data], { type: 'application/zip' })
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const date_time = Common.formatDateTime(new Date(), 'YYYYMMDD_HHmmdd');
        link.download = `edison_drone_archive_${date_time}.zip`
        link.href = url;
        document.body.appendChild(link);
        link.click();

        setSnackBarMessage('ファイルをダウンロードしました')
        setSnackBarStatus('success')
        setOpenSnackBar(true)
        setIsOpen(false)
        setProgressMsg('')
        reload()
      })
      .catch(err => {
        setSnackBarMessage('ファイルのダウンロードに失敗しました')
        setSnackBarStatus('error')
        setOpenSnackBar(true)
        setIsOpen(false)
        setProgressMsg('')
        console.log(err);
      })
  }

  const handleBulkDelete = () => {
    setDeleteDialogOpen(false)
    setIsOpen(true)
    setProgressMsg('削除')

    cliAuth.delete(URL.DELETE_CONTENTS + '?pk_ids=' + selected.join(','))
      .then(response => {
        console.log(response);
        setSnackBarMessage(response.data)
        setSnackBarStatus('success')
        setOpenSnackBar(true)
        setIsOpen(false)
        setProgressMsg('')
        setSelected([])
        reload()
        // HANAにデータ連携
        SendForHana()
      })
      .catch(err => {
        setSnackBarMessage('アップロードファイルの削除に失敗しました')
        setSnackBarStatus('error')
        setOpenSnackBar(true)
        setIsOpen(false)
        setProgressMsg('')
        console.log(err);
      })
  }
  const handleMessageClose = () => {
    setMessageDialogOpen(false);
  };
  const handleDownloadCancel = () => {
    setDownloadDialogOpen(false);
  };
  const handleBulkDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (movies) => (event) => {
    if (event.target.checked) {
      const newSelected = movies.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (row) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    scrollToTopRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.contentList.length) : 0;

  return (
    <>
      <div id="top-of-list" ref={scrollToTopRef} />
      <Box sx={{ width: '100%' }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setMessageDialogOpen={setMessageDialogOpen}
          setDownloadDialogOpen={setDownloadDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          hasDownload={props.hasDownload}
          movies={props.contentList.filter(movie => selected.includes(movie.id))}
          setDownloadMessage1={setDownloadMessage1}
          setDownloadMessage2={setDownloadMessage2}
          setDownloadDisAbledList={setDownloadDisAbledList}
        />
        <TableContainer>
          <Table >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.contentList.length}
              movies={props.contentList}
            />
            <TableBody>
              {stableSort(props.contentList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => (
                  <Row
                    key={id}
                    row={row}
                    activeTags={props.activeTags}
                    hasDownload={props.hasDownload}
                    isSelected={isSelected}
                    handleClick={handleClick}
                    setTargetFileId={setTargetFileId}
                    setTargetFileTitle={setTargetFileTitle}
                    setSingleDeleteConfirmOpen={setSingleDeleteConfirmOpen}
                    setSingleDownloadDialogOpen={setSingleDownloadDialogOpen}
                    setDownloadMessage1={setDownloadMessage1}
                    setSnackBarMessage={setSnackBarMessage}
                    setSnackBarStatus={setSnackBarStatus}
                    setOpenSnackBar={setOpenSnackBar}
                    reload={reload}
                    page={page}
                  />
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            '.MuiTablePagination-toolbar': {
              paddingLeft: 0,
            },
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.contentList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'1ページあたり'}
        />
      </Box>
      <ProgressModal
        modalIsOpen={modalIsOpen}
        isFinal={true}
        progress={0}
        text1={progressMsg + '処理中です...'}
        text2={'この処理は数分かかる場合があります...'}
      />
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
      <DownloadDialog
        confirmOpen={downloadDialogOpen}
        setConfirmOpen={setDownloadDialogOpen}
        dialogTitle={`ダウンロード`}
        dialogMessage1={downloadMessage1}
        dialogMessage2={downloadMessage2}
        downloadDisAbledList={downloadDisAbledList}
        messageStyle={{ fontSize: '18px' }}
        handleAction={handleDownload}
        handleCancel={handleDownloadCancel}
        buttonName={'ダウンロード'}
      />
      <SingleCreateDownloadFileDialog
        confirmOpen={singleDownloadDialogOpen}
        setConfirmOpen={setSingleDownloadDialogOpen}
        dialogTitle={`ダウンロード準備`}
        url={URL.DOWNLOAD + '?pk_ids=' + targetFileId}
        targetName={targetFileTitle}
        setTargetFileId={setTargetFileId}
        setTargetFileTitle={setTargetFileTitle}
        setSelected={setSelected}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar}
        reload={reload} 
        />
      <MessageDialog
        confirmOpen={messageDialogOpen}
        setConfirmOpen={setMessageDialogOpen}
        dialogTitle={`ダウンロード`}
        dialogMessage1={downloadMessage1}
        dialogMessage2={downloadMessage2}
        messageStyle={{ fontSize: '18px' }}
        handleMessageClose={handleMessageClose}
        buttonName={'ダウンロード'}
      />
      <BulkDeleteDialog
        confirmOpen={deleteDialogOpen}
        setConfirmOpen={setDeleteDialogOpen}
        dialogTitle={'アップロードファイル削除'}
        dialogMessage1={`${selected.length}件のアップロードファイルを削除します。`}
        dialogMessage2={`この操作は取り消しできません。削除してよろしいですか？`}
        handleAction={handleBulkDelete}
        handleCancel={handleBulkDeleteCancel}
        buttonName={'削除'}
      />
      <SingleDeleteDialog
        confirmOpen={singleDeleteConfirmOpen}
        setConfirmOpen={setSingleDeleteConfirmOpen}
        dialogTitle={'アップロードファイル'}
        url={URL.DELETE_CONTENTS + '?pk_ids=' + targetFileId}
        targetName={targetFileTitle}
        setTargetFileId={setTargetFileId}
        setTargetFileTitle={setTargetFileTitle}
        setSelected={setSelected}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar}
        reload={reload}
        isSendForHana={true}
      />
    </>
  );
}
