import React from 'react'
import ButtonSubmit from '../../atoms/button_submit'
import { BoxSpaceAroundMarginTB30 } from '../../style/box'

export default function CenterSubmit(props) {
  return (
    <BoxSpaceAroundMarginTB30>
      <ButtonSubmit text={props.text} />
    </BoxSpaceAroundMarginTB30>
  )
}