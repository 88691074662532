import React, { useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Stack } from "@mui/material";

import Email from '../../items/user_email';
import Affiliation from '../../items/user_affiliation';
import PasswordPair from '../../items/user_password_pair';
import CancelSubmit from '../../items/cancel_submit';

import { PARAM_NAME } from '../../const/general';
import { AFFILIATION, PASSWORD, PASSWORD_REPEAT } from '../../const/roules';

import patchUser from '../../functions/patch_user';

function UserInfoForm(props) {
  const { control, register, handleSubmit, setValue, getValues, formState: { errors }, watch, reset } = useForm({ mode: 'onBlur' });

  const password = useRef({});
  password.current = watch("password", "");
  const email = useRef({});
  email.current = watch("email", props.email);

  useEffect(() => {
    setValue('affiliation', props.affiliation)
  }, [props.affiliation]);

  const onCancel = () => {
    reset()
    setValue('affiliation', props.affiliation)
    setValue(PARAM_NAME.LAT, props.latitude)
    setValue(PARAM_NAME.LNG, props.longitude)
  }

  const onSubmit = async data => {
    console.log(data)
    if (await patchUser(
      data,
      props.setSnackBarMessage,
      props.setSnackBarStatus,
      props.setOpenSnackBar,
      props.setSnackBarMessage,
      props.setSnackBarStatus,
      props.setOpenSnackBar,
      false
    )) {
      // 送信成功したら最新情報を取得
      props.reload()
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ marginBottom: 3 }}>
          <Email
            register_id={register('id')}
            id={props.id}
            register_email={register('email')}
            email={props.email}
          />

          <Affiliation
            name='affiliation'
            control={control}
            register_affiliation={register('affiliation', AFFILIATION)}
            defaultValue_affiliation={props.affiliation}
            error_affiliation={errors.affiliation}
            setValue={setValue}
            getValues={getValues}
            latitude={props.latitude}
            longitude={props.longitude}
            setValueNameLat={PARAM_NAME.LAT}
            setValueNameLng={PARAM_NAME.LNG}
          />
          <PasswordPair
            register_password1={register('password', PASSWORD(email))}
            error_password1={errors.password}
            register_password2={register('password_repeat', PASSWORD_REPEAT(password))}
            error_password2={errors.password_repeat}
          />
          <CancelSubmit
            onCancel={onCancel}
            text='更新' />
        </Stack>
      </form>
    </>
  )
}

export default UserInfoForm;
