import { useDropzone } from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import styled from 'styled-components';

const getColor = (props) => {
	if (props.isDragAccept) {
		return '#00e676';
	}
	if (props.isDragReject) {
		return '#ff1744';
	}
	if (props.isFocused) {
		return '#2196f3';
	}
	return '#eeeeee';
};

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 40px;
	border-width: 2px;
	border-radius: 10px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: black;
	font-weight: bold;
	font-size: 1.3rem;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

function DropBox({ onDrop }) {
	const {
		getRootProps,
		getInputProps,
		open,
		isDragAccept,
		isFocused,
		isDragReject,
		fileRejections,
	} = useDropzone({
		accept: [
			'image/png',
			'image/jpeg',
			'image/gif',
			// 'video/mpeg',
			'video/mp4',
			// 'video/webm',
			// 'video/ogg',
			'video/quicktime',
			'application/zip',
        	'application/x-zip-compressed'
		],
		onDrop,
		noClick: true,
		noKeyboard: true,
	});

	return (
		<>
			<section>
				<Container
					{...getRootProps({ isDragAccept, isFocused, isDragReject })}
					onClick={open}
				>
					<input {...getInputProps()} />
					<p>
						<FileUploadOutlinedIcon
							fontSize='large'
							sx={{
								verticalAlign: 'bottom',
								paddingRight: 3
							}} />
						動画・画像ファイルを選択・ドラッグ ＆ ドロップ
					</p>
					{fileRejections.length > 0 && 
					<div style={{
						color:'red', 
						fontSize:'1.0rem', 
						fontWeight:'normal'}} >
                    {'申し訳ございません。指定されたファイルはアップロードできません。'}<br/>{ '画像・動画（.mp4 .mov）・ZIPファイルのいずれかをご指定ください。'}
					</div>}
				</Container>
			</section>
		</>
	);
}

export default DropBox;
