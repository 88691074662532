import { Box, Stack } from '@mui/material';
// import styled from '@emotion/styled';
import { styled } from "@mui/system";

// 横並び
// 左右両端にアイテムを寄せる
// 上下中央揃え
export const BoxSpaceBetween = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }));
export const BoxSpaceBetweenMarginLR = styled(BoxSpaceBetween)(() => (
    {
        margin: '0 30px'
    }));
export const BoxSpaceBetweenMarginTB = styled(BoxSpaceBetween)(() => (
    {
        margin: '10px 0'
    }));
export const BoxSpaceBetweenMarginBR = styled(BoxSpaceBetween)(() => (
    {
        margin: '0 20px 5px 0'
    }));

// 横並び
// アイテムを均等配置
// 上下中央揃え
export const BoxSpaceAround = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    }));
export const BoxSpaceAroundMarginLR10 = styled(BoxSpaceAround)(() => (
    {
        margin: '0 10px'
    }));
export const BoxSpaceAroundMarginTB10 = styled(BoxSpaceAround)(() => (
    {
        margin: '10px 0'
    }));
export const BoxSpaceAroundMarginTB30 = styled(BoxSpaceAround)(() => (
    {
        margin: '30px 0'
    }));

// 横並び
// 左端にアイテムを寄せる
// 上下中央揃え
export const BoxFlexStart = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }));
export const BoxFlexStartWrap = styled(BoxFlexStart)(() => (
    {
        flexWrap: 'wrap',
    }));
export const BoxFlexStartMargin = styled(BoxFlexStart)(() => (
    {
        margin: '15px'
    }));

export const BoxFlexStartMarginR = styled(BoxFlexStart)(() => (
    {
        margin: '0 15px 0 0'
    }));
export const BoxFlexStartMarginTB = styled(BoxFlexStart)(() => (
    {
        margin: '15px 0'
    }));
export const BoxFlexStartMarginTBWrap = styled(BoxFlexStartMarginTB)(() => (
    {
        flexWrap: 'wrap',
    }));

// 横並び
// 左端にアイテムを寄せる
// 上揃え
export const BoxFlexStartTop = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    }));
export const BoxFlexStartTopMargin = styled(BoxFlexStartTop)(() => (
    {
        margin: '15px'
    }));
export const BoxFlexStartTopMarginB = styled(BoxFlexStartTop)(() => (
    {
        margin: '0 0 15px 0'
    }));

// 横並び
// 右端にアイテムを寄せる
// 上揃え
export const BoxFlexEndTop = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
    }));
export const BoxFlexEndTopMarginBR = styled(BoxFlexEndTop)(() => (
    {
        margin: '0 15px 15px 0'
    }));
// 横並び
// 左右中央揃え
// 上揃え
export const BoxCenterStart = styled(Box)(() => (
    {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
    }));
export const BoxFlexStartTopStaticHeight = styled(BoxFlexStartTop)(({ theme }) => (
    {
        [theme.breakpoints.up("xs")]: {
            position: 'relative',
            height: '78px'
        },
        [theme.breakpoints.up("sm")]: {
            position: 'relative',
            height: '148px'
        },
        [theme.breakpoints.up("md")]: {
            position: 'relative',
            height: '148px'
        },
    }));

export const BodyWrapper = styled('div')(({ theme }) => ({
    padding: '0',
    [theme.breakpoints.up("sm")]: {
        margin: '15px 10px 10px 15px'
    },
    [theme.breakpoints.up("md")]: {
        padding: '10px 30px'
    },
    [theme.breakpoints.up("lg")]: {
        padding: '10px'
    },
}));

export const StackMargin10 = styled(Stack)(() => (
    {
        margin: '10px'
    }));

export const StackTB5LR30 = styled(Stack)(() => (
    {
        margin: '5PX 30px'
    }));

