import * as React from 'react';
import style from 'styled-components'
import AvatarIcon from '../../atoms/avatar_icon'
import { BoxSpaceBetweenMarginTB, BoxFlexStart } from '../../style/box'
import { Stack } from '@mui/material';

const TextTitle = style.div`
font-weight: bold;
font-size: 1.0rem;
text-align: left;
width: 100%;
overflow-wrap: normal;
`
const TextSub = style.div`
font-size: 0.8rem;
text-align: left;
width: 100%;
overflow-wrap: normal;
`
export default function LevelTitle(props) {

  return (
    <BoxFlexStart>
      <AvatarIcon iconType={props.emergency_level} popup={props.popup} />
      <Stack>
        <TextTitle>{props.title}</TextTitle>
        <TextSub>{props.affiliation}</TextSub>
      </Stack>
    </BoxFlexStart>
  );
}
