import React from 'react'

import {
    Alert,
    AlertTitle,
    Grid,
    Box,
    Button,
} from "@mui/material";
import getContentsPath from '../../functions/get_contents_path';
import { Item } from '../../const/style'
import { TypographyGraySmallLeft, } from '../../style/typography'
import { Image, } from '../../style/image'
import { BoxSpaceAroundMarginTB30 } from '../../style/box'

function UploadSuccess(props) {


    return (
        <>
            <Alert sx={{ mt: 3 }} severity="info">
                <AlertTitle>以下のファイルは正常にアップロードにされました。</AlertTitle>
                OKボタンを押すと新規アップロード画面に戻ります。
            </Alert>
            <Box sx={{ width: '100%', mt: 2 }}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    {props.successContents.map((success, i) => 
                            (
                                <Grid key={i} item xs={12} sm={4} md={3}>
                                    <Item>
                                        <Image
                                            src={getContentsPath(
                                                success.movie.contentType, 
                                                success.fileId, 
                                                success.fileId+success.movie.ext, 
                                                false)}
                                            resizeMode={"contain"}
                                        />
                                        <TypographyGraySmallLeft>タイトル：{success.item.title}</TypographyGraySmallLeft>
                                        <TypographyGraySmallLeft>ファイル名：{success.movie.name}</TypographyGraySmallLeft>
                                        <TypographyGraySmallLeft>ファイル容量：{success.movie.strSize}</TypographyGraySmallLeft>
                                    </Item>
                                </Grid>)
                    )}
                </Grid>

            </Box>
            <BoxSpaceAroundMarginTB30>
                <Button
                    variant="contained"
                    sx={{
                        width: '100%',
                        backgroundColor: '#2c3e50',
                        '&:hover': {
                            backgroundColor: '#435e7a'
                        }
                    }}
                    onClick={() =>
                        props.allClear()
                    }
                >OK</Button>
            </BoxSpaceAroundMarginTB30>
        </>
    );
}
export default UploadSuccess;
