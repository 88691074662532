import ImageListItemBar, { imageListItemBarClasses } from '@mui/material/ImageListItemBar';
import styled from '@emotion/styled';
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Video = styled('video')({
  objectFit: 'contain',
  width: '100%',
});
export const Image = styled('img')({
  objectFit: 'contain',
  width: '100%',
});
export const FullScreenImage = styled(Image)({
  height: '100%',
});
export const ViewImage = styled(Image)({
  height: '50vh',
});

export const ListImage = styled(LazyLoadImage)({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  cursor: 'pointer', 
});
export const ListImageSmall = styled(ListImage)({
  width: '80%',
});

export const ThumbImageListItemBar = styled(ImageListItemBar)(() => ({
    height: '25%',
    fontSize: '0.8rem',
    cursor: 'pointer', 
    [`& .${imageListItemBarClasses.title}`]: {
      fontSize: '0.8rem', lineHeight: '14px',
    },
  }));

export const HomeImageListItemBar = styled(ImageListItemBar)({
    height: '15%',
    backgroundColor: 'rgba(0,0,0,0.3)',
    cursor: 'pointer', 
    [`& .${imageListItemBarClasses.title}`]: {
      fontSize: '0.8rem', lineHeight: '14px', textAlign: 'right'
    },
  });


