import * as React from 'react';
import { InputText, } from '../../style/text_view'

export default function InputOverview(props) {
  return (
    <>
      <InputText
        {...props.register}
        multiline
        minRows={5}
        placeholder="概要"
        defaultValue={props.overview}
        helperText={props.error?.message}
        error={props.error?.message.length > 0}
      />
    </>
  );
}
