import React from 'react'
import PropTypes from 'prop-types';
import {
    Backdrop,
    Box,
    CircularProgress,
    Typography
} from '@mui/material/';

const style = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}
const ProggressLabel = (props) => {
    return (
        <Typography
            variant="caption"
            component="div"
            color="#fff"
            width='80%'
        >
            <Box fontSize={14} textAlign="center">
                {props.text1}
            </Box>
            <Box fontSize={props.textSize} textAlign="center">
                {props.text2}
            </Box>
        </Typography>
    )
}

const CircularProgressWithLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" color='inherit' {...props} />
            <Box sx={style} >
                <ProggressLabel
                    text1='アップロード中です...'
                    text2={`${Math.round(props.value)}%`}
                    textSize={'h6.fontSize'}
                />
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
}

function ProgressModal(props) {

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={props.modalIsOpen}
            >
                {props.isFinal ?
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress color='inherit' size={200} />
                        <Box sx={style} >
                            <ProggressLabel
                                text1={props.text1}
                                text2={props.text2}
                                textSize={14}
                            />
                        </Box>
                    </Box>
                    :
                    <CircularProgressWithLabel
                        value={props.progress}
                        size={200}
                    />
                }
            </Backdrop>
        </>
    )
}

export default ProgressModal;
