import React from 'react'

import {
    Alert,
    AlertTitle,
    Grid,
    Box,
    Button,
} from "@mui/material";
import { Item } from '../../const/style'
import UploadThumb from '../../items/upload_thumb'
import { BoxSpaceAroundMarginTB30 } from '../../style/box'
import { TypographyGraySmallLeft, } from '../../style/typography'
import { CONTENTS_TYPE } from '../../const/general'

function UploadError(props) {

    return (
        <>
            <Alert sx={{ mt: 3 }} severity="error">
                <AlertTitle>以下のファイルはアップロードできませんでした。</AlertTitle>
                申し訳ございませんが、OKボタンを押して最初からアップロードしてください。
            </Alert>
            <Box sx={{ width: '100%', mt: 2 }}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    {props.errorContents.map((error, i) => (
                        <Grid key={i} item xs={12} sm={4} md={3}>
                            <Item>
                                <UploadThumb
                                    contentType={error.movie.contentType}
                                    url={error.movie.url}
                                />
                                {error.movie.contentType === CONTENTS_TYPE.ZIP &&
                                    <Alert severity="error">
                                        <AlertTitle>画像群のzipフォルダのみアップロード可能です。
                                            他のファイルが含まれていないかご確認ください。</AlertTitle>
                                    </Alert>
                                }

                                <TypographyGraySmallLeft>タイトル：{error.item.title}</TypographyGraySmallLeft>
                                <TypographyGraySmallLeft>ファイル名：{error.movie.name}</TypographyGraySmallLeft>
                                <TypographyGraySmallLeft>ファイル容量：{error.movie.strSize}</TypographyGraySmallLeft>
                            </Item>
                        </Grid>))}
                </Grid>

            </Box>
            <BoxSpaceAroundMarginTB30>
                <Button
                    variant="contained"
                    sx={{
                        width: '100%',
                        backgroundColor: '#2c3e50',
                        '&:hover': {
                            backgroundColor: '#435e7a'
                        }
                    }}
                    onClick={() =>
                        props.allClear()
                    }
                >OK</Button>
            </BoxSpaceAroundMarginTB30>
        </>
    );
}
export default UploadError;
