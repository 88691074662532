import React from 'react'
import { Stack } from "@mui/material";
import InputPassword from '../../atoms/input_password';
import { TypographyBreakWordGraySmall } from '../../style/typography';

function PasswordPair(props) {
  return (
    <Stack spacing={1}>
      <InputPassword
        register={props.register_password1}
        label="新規パスワード"
        error={props.error_password1?.message.length > 0 }
        helperText={props.error_password1?.message }
      />
      <InputPassword
        register={props.register_password2}
        label="パスワードの確認"
        error={props.error_password2?.message.length > 0 }
        helperText={props.error_password2?.message }
        />
      <TypographyBreakWordGraySmall>パスワードは英字と数字を含む8文字以上20文字以下の文字列を指定してください</TypographyBreakWordGraySmall>
    </Stack>
  )
}

export default PasswordPair;
