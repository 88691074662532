import React, { useState } from "react";
import { Routes, BrowserRouter, Route, Outlet } from 'react-router-dom'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// pages
import Login from '../pages/login'
import ViewMap from '../pages/view/map'
import Home from '../pages/home'
import NewUser from '../pages/admin/new_user/'
import User from '../pages/admin/user'
import SettingsUser from '../pages/settings/user'
import SettingsContents from '../pages/settings/contents'
import AdminContents from '../pages/admin/contents/index'
import Tag from '../pages/admin/tag'
import Survey from '../pages/admin/survey'
import Movie from '../pages/upload/movie'
import SendUrl from '../pages/admin/send_url'
import FailedToLoad from '../pages/failed_to_load'
import SearchConditions from '../items/search_conditions'

// contents
import Header from '../contents/header'
import Footer from '../contents/footer'

import { PAGE_TITLE, PAGE_WIDTH } from '../const/general'
import { TypographyTitleCenter } from '../style/typography'

const HomePages = (
  contentsCount, setContentsCount,
  searchConditions, setSearchConditions,
  selectTag, setSelectTag) => {
  return (
    <>
      <Header
        isSearchPanel={true}
        searchConditions={searchConditions}
        setSearchConditions={setSearchConditions}
      />
      <Container maxWidth='90%'
        sx={{
          minHeight: 'calc(100vh - 88px)',
          paddingLeft: { xs: '20px', sm: '80px' },
          paddingTop: '10px',
          paddingBottom: { xs: '10px', sm: '20px' },
        }}>
        <SearchConditions
          contentsCount={contentsCount}
          selectTag={selectTag}
          setSelectTag={setSelectTag}
          searchConditions={searchConditions}
          setSearchConditions={setSearchConditions}
        />
        <Home
          contentsCount={contentsCount}
          setContentsCount={setContentsCount}
          searchConditions={searchConditions}
          selectTag={selectTag}
        />
      </Container>
      <Footer />
    </>
  )
};
const MapPages = (
  contentsCount, setContentsCount,
  searchConditions, setSearchConditions,
  selectTag, setSelectTag) => {
  return (
    <>
      <Header />
      <Container disableGutters={true} maxWidth={false}
      sx={{
        minHeight: 'calc(100vh - 88px)',
      }}>
        <Box sx={{
          marginLeft: { xs: '0px', sm: '66px' },
        }} >
          <ViewMap
            contentsCount={contentsCount}
            setContentsCount={setContentsCount}
            searchConditions={searchConditions}
            setSearchConditions={setSearchConditions}
            selectTag={selectTag}
            setSelectTag={setSelectTag}
          />
        </Box>
      </Container>
      <Footer />
    </>
  )
};
const FullScreenPages = (route) => {
  return (
    <>
      <Header />
      <Container maxWidth='90%'
        sx={{
          minHeight: 'calc(100vh - 88px)',
          paddingLeft: { xs: '20px', sm: '80px' },
          paddingTop: '10px',
          paddingBottom: { xs: '10px', sm: '20px' },
        }}>
        {route}
      </Container>
      <Footer />
    </>
  )
};
const CenterdPages = (route, title, maxWidth) => {
  return (
    <>
      <Header />
      <Container maxWidth={maxWidth}>
        <Box sx={{
          minHeight: 'calc(100vh - 48px - 40px - 30px)',
          marginLeft: { xs: '0px', sm: '66px' },
          paddingTop: { xs: '10px', sm: '30px' },
          paddingBottom: { xs: '15px', sm: '40px' },
        }} >
          <TypographyTitleCenter >{title}</TypographyTitleCenter>
          {route}
        </Box>
      </Container>
      <Footer />
    </>
  )
};

const GuestLayout = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};
function Apps() {
  const [contentsCount, setContentsCount] = useState(0);
  const [selectTag, setSelectTag] = useState(
    {
      tag_uuid: '',
      tag_name: ''
    }
  );
  const [searchConditions, setSearchConditions] = useState(
    {
      registered_date_type: 'all',
      registered_starting_date: null,
      registered_ending_date: null,
      shoote_date_type: 'all',
      shoote_starting_date: null,
      shoote_ending_date: null,
      emergency_level_emergency: true,
      emergency_level_important: true,
      emergency_level_nomal: true,
      address: '',
      overview: '',
      order: 'shoote_date_new',

    });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<GuestLayout />}>
          <Route index
            element={HomePages(
              contentsCount, setContentsCount,
              searchConditions, setSearchConditions,
              selectTag, setSelectTag
            )} />
          <Route path='view/map'
            element={MapPages(
              contentsCount, setContentsCount,
              searchConditions, setSearchConditions,
              selectTag, setSelectTag
            )} />
          <Route path="login" element={<Login />} />
          <Route path='upload/movie' element={CenterdPages(<Movie />, '', PAGE_WIDTH.MD)} />
          <Route path='settings/user' element={CenterdPages(<SettingsUser />, PAGE_TITLE.SETTING_USER, PAGE_WIDTH.SM)} />
          <Route path='settings/contents' element={CenterdPages(<SettingsContents />, PAGE_TITLE.SETTING_CONTENTS, PAGE_WIDTH.MD)} />
          <Route path='admin/contents' element={CenterdPages(<AdminContents />, PAGE_TITLE.ADMIN_CONTENTS, PAGE_WIDTH.MD)} />
          <Route path='admin/new_user' element={CenterdPages(<NewUser />, PAGE_TITLE.NEW_USER, PAGE_WIDTH.SM)} />
          <Route path='admin/user' element={CenterdPages(<User />, PAGE_TITLE.ADMIN_USER, PAGE_WIDTH.SM)} />
          <Route path='admin/tag' element={CenterdPages(<Tag />, PAGE_TITLE.TAG, PAGE_WIDTH.SM)} />
          <Route path='admin/survey' element={CenterdPages(<Survey />, PAGE_TITLE.SURVEY, PAGE_WIDTH.SM)} />
          <Route path='admin/send_url' element={FullScreenPages(<SendUrl />)} />
          <Route path='*' element={CenterdPages(<FailedToLoad />)} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Apps;