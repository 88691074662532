import { 
    CONTENTS_TYPE, 
    CONTENTS_URL
} from '../const/general'

export const setSelectContentImgUrl = (content) => {

    if (content.is_copyright) {
      // コピーライトあり
      switch (content.contents_type) {
        case CONTENTS_TYPE.IMAGE:
          // 画像
          return CONTENTS_URL.IMAGE_COPYRIGHT + content.file_name
        case CONTENTS_TYPE.ZIP:
          // Zip
          return CONTENTS_URL.ZIP_COPYRIGHT + content.contents_uuid + '.png'
        default:
          break
      }
    } else {
      // コピーライトなし
      switch (content.contents_type) {
        case CONTENTS_TYPE.IMAGE:
          // 画像
          return CONTENTS_URL.IMAGE_ORIGINAL + content.file_name
        case CONTENTS_TYPE.ZIP:
          // 画像群の場合は代表画像を表示するため拡張子にUUID.pngを指定
          return CONTENTS_URL.ZIP_THUMBNAIL + content.contents_uuid + '.png'
        default:
          break
      }
    }
  }
  
