import { PAGE_TITLE } from '../../const/general'
import { TypographyTitleLeft } from '../../style/typography'
// import ButtonCancel from '../../atoms/button_close'
// import { GetAllContents } from '../../functions/get_data_swr';
// import cliAuth from '../../functions/axios_cli';
// import getElevation from '../../functions/get_elevation';

function FailedToLoad() {
    // const { allContents, isAllContentsLoading, isAllContentsError } = GetAllContents();
    // if (isAllContentsError) return <></>
    // if (isAllContentsLoading) return <></>
    // const onCancel = async (allContents) => {
    //     console.log('標高登録')
    //     // 標高を取得
    //     allContents.map(async (content) => {
    //         const elevation = await getElevation(content.latitude, content.longitude)
    //         content['elevation'] = elevation
    //         await cliAuth.patch('contents_movie/' + content.id + '/', content)
    //         .then(response => {
    //           console.log('contents_movie/' + content.id + '/' + ' patch success', response)
    //         })
    //         .catch(err => {
    //           console.log(err)
    //         });
    //     })
    // }
    return (
        <>
            <TypographyTitleLeft >
                {PAGE_TITLE.FAILED_TO_LOAD}
            </TypographyTitleLeft>
            {/* <ButtonCancel onCancel={onCancel(allContents)} text="標高" /> */}
        </>)
}

export default FailedToLoad;
