exports.formatDateTime = function (date, format) {
  format = format.replace(/YYYY/, date.getFullYear());
  format = format.replace(/MM/, date.getMonth() + 1);
  format = format.replace(/DD/, date.getDate());
  format = format.replace(/HH/, date.getHours());
  format = format.replace(/mm/, date.getMinutes());
  format = format.replace(/dd/, date.getSeconds());
  return format;
}
exports.formatDate = function (date, format) {
  format = format.replace(/YYYY/, date.getFullYear());
  format = format.replace(/MM/, date.getMonth() + 1);
  format = format.replace(/DD/, date.getDate());
  return format;
}
exports.getStrFileSize = function (fileSize) {
  if (fileSize > 1 * 1024 * 1024 * 256)
    return (fileSize / (1024 * 1024 * 1024)).toFixed(2).toString() + 'GB'
  if (fileSize > 1 * 1024 * 1024)
    return (fileSize / (1024 * 1024)).toFixed(2).toString() + 'MB'
  if (fileSize > 1 * 1024)
    return (fileSize / (1024)).toFixed(2).toString() + 'KB'
  return fileSize.toFixed(2).toString() + 'B'
}

exports.compareDate = function (date1, date2) {

  // date1 が date2 よりも小さい（過去である）
  // 場合に true を返す
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth() + 1;
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth() + 1;
  const day2 = date2.getDate();

  if (year1 == year2) {
    if (month1 == month2) {
      if(day1 == day2){
        return true
      }
      return day1 < day2;
    }
    else {
      return month1 < month2;
    }
  } else {
    return year1 < year2;
  }
}
exports.strConvertHalfWidth =  function (str) {
  // 全角英数字を半角に変換
  str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  return str;
}