import React from 'react'
import ButtonSubmit from '../../atoms/button_submit'
import ButtonCancel from '../../atoms/button_close'
import {BoxSpaceAroundMarginTB30} from '../../style/box'

export default function CancelSubmit(props) {
  return (
    <BoxSpaceAroundMarginTB30>
      <ButtonCancel onCancel={props.onCancel} text="キャンセル" />
      <ButtonSubmit text={props.text} />
    </BoxSpaceAroundMarginTB30>
  )
}