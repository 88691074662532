import React, { useState, useEffect } from 'react';
import {
  MapContainer,
  Polyline,
} from 'react-leaflet'
import { useMap, useMapEvents } from 'react-leaflet/hooks'
import Leaflet from "leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster'
import { DEFAULT_POSITION } from '../../const/general'
import MarkerPopup from '../../items/marker_popup'
import MapControl from '../../items/map_control'
import MapMarkers from '../../items/map_markers'
import * as ReactDOMServer from 'react-dom/server';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import getPolyLines from '../../functions/get_polylines';

const UpdateMapCentre = (props) => {

  const map = useMap();
  const mapEvents = useMapEvents({
    zoomend: () => {
      props.setZoom(mapEvents.getZoom());
    },
  })

  useEffect(() => {
    console.log(props.selectContent)

    // ルート座標を取得
    props.setRouteLines(getPolyLines(props.selectContent.routes))

    // ポップアップ設定
    map.openPopup(Leaflet.popup({
      offset: [0, -34],
      closeButton: false
    })
      .setLatLng(props.mapCentre)
      .setContent(ReactDOMServer.renderToString(<MarkerPopup content={props.selectContent} />))
      .openOn(map)
    )
    // 地図の中心を移動
    map.flyTo(props.mapCentre, props.zoom)

  }, [props.selectContent])

  return null
}

const ViewMap = (props) => {

  const [nomalMap, setNomalMap] = useState(false);
  const [photoMap, setPhotoMap] = useState(true);
  const [shadowMap, setShadowMap] = useState(false);
  const [zoom, setZoom] = useState(10);
  const [flg, setFlg] = useState(false);
  const [routeLines, setRouteLines] = useState([]);

  // 緯度軽度
  const lat = props.selectContent.latitude ? props.selectContent.latitude : DEFAULT_POSITION.LAT
  const lon = props.selectContent.longitude ? props.selectContent.longitude : DEFAULT_POSITION.LNG
  const position = [lat, lon];

  return (
    <MapContainer
      fullscreenControl={props.fullscreen}
      fullscreenControlOptions={{
        title: {
          'false': '地図を全画面表示',
          'true': '地図表示を元に戻す'
        }
      }}
      center={position}
      zoom={zoom}
      scrollWheelZoom={true}
      style={{
        height: props.mapHeight,
        zIndex: 2,
        backgroundColor: 'rgb(133 202 248 / 87%)',
      }}
    >
      <UpdateMapCentre
        mapCentre={position}
        selectContent={props.selectContent}
        handleClick={props.handleClick}
        zoom={zoom}
        setZoom={setZoom}
        flg={flg}
        setFlg={setFlg}
        setRouteLines={setRouteLines}
      />
      <MapControl
        nomalMap={nomalMap}
        setNomalMap={setNomalMap}
        photoMap={photoMap}
        setPhotoMap={setPhotoMap}
        shadowMap={shadowMap}
        setShadowMap={setShadowMap}
      />
      {/* 飛行ルート表示 */}
      {props.selectContent.routes &&
        routeLines.map((line, i) => (
          <Polyline key={i}
            positions={[line.start_position,
            line.end_position]}
            pathOptions={line.risk_level_style}
          />))
      }

      <MarkerClusterGroup chunkedLoading zoomToBoundsOnClick>
        {props.contents.map((content, i) => (
          <MapMarkers key={i}
            content={content}
            selectContent={props.selectContent}
            handleClick={props.handleClick}
          />
        ))}
      </MarkerClusterGroup>
      {/* <Legend map={map} /> */}
    </MapContainer>
  );
}

export default ViewMap;
