import { ICON_INDEX, HAZARD, RISK} from '../const/risk_style'

export default function hazardIconIndex (i, risk) {

  if(risk < RISK.LOW){
    return null
  }
  if(i === HAZARD.LAND_SLID && risk === RISK.LOW){
    return ICON_INDEX.LAND_SLID_RISK_LOW
  }
  if(i === HAZARD.LAND_SLID && risk === RISK.MIDDLE){
    return ICON_INDEX.LAND_SLID_RISK_MIDDLE
  }
  if(i === HAZARD.LAND_SLID && risk === RISK.HIGHT){
    return ICON_INDEX.LAND_SLID_RISK_HIGHT
  }
  if(i === HAZARD.FLOOD && risk === RISK.LOW){
    return ICON_INDEX.FLOOD_RISK_LOW
  }
  if(i === HAZARD.FLOOD && risk === RISK.MIDDLE){
    return ICON_INDEX.FLOOD_RISK_MIDDLE
  }
  if(i === HAZARD.FLOOD && risk === RISK.HIGHT){
    return ICON_INDEX.FLOOD_RISK_HIGHT
  }
  if(i === HAZARD.HOUSE_FLOOD && risk === RISK.LOW){
    return ICON_INDEX.HOUSE_FLOOD_LOW
  }
  if(i === HAZARD.HOUSE_FLOOD && risk === RISK.MIDDLE){
    return ICON_INDEX.HOUSE_FLOOD_MIDDLE
  }
  if(i === HAZARD.HOUSE_FLOOD && risk === RISK.HIGHT){
    return ICON_INDEX.HOUSE_FLOOD_HIGHT
  }
  return null
}