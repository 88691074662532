export const TITLE = {
  required: 'タイトルを入力してください',
  maxLength: { value: 40, message: 'タイトルは40文字以内で入力してください' },
  // defaultValueで半角を入れることで
  // setValue時に「タイトル」の文字がvalueと被って表示される事象を回避してます
  // requiredだけではdefaultValueの半角一文字がエラーとして検出できないので
  // 以下のバリデーションを追加してます
  validate: value =>
    value.trim() === '' ? "タイトルを入力してください" : null
}
export const E_MAIL = {
  required: 'メールアドレスを入力してください',
  pattern: {
    value:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    message: 'メールアドレスの形式が不正です',
  },
}
export const OVERVIEW = {
  // 2024/1/19 最低限の情報でアップロードを可能とするために概要は任意項目としました。
  // required: `概要を入力してください`,
  maxLength: { value: 1000, message: '概要は1000文字以内で入力してください' },
  // defaultValueで半角を入れることで
  // setValue時に「概要」の文字がvalueと被って表示される事象を回避してます
  // requiredだけではdefaultValueの半角一文字がエラーとして検出できないので
  // 以下のバリデーションを追加してます
  // validate: value =>
  //   value.trim() === '' ? "概要を入力してください" : null
}

export const OVERVIEW_SURVEY = {
  required: `概要を入力してください`,
  maxLength: { value: 1000, message: '概要は1000文字以内で入力してください' },
  // defaultValueで半角を入れることで
  // setValue時に「概要」の文字がvalueと被って表示される事象を回避してます
  // requiredだけではdefaultValueの半角一文字がエラーとして検出できないので
  // 以下のバリデーションを追加してます
  validate: value =>
    value.trim() === '' ? "概要を入力してください" : null
}

export const OVERVIEW_SEARCH = {
  maxLength: { value: 50, message: '概要は50文字以内のキーワードで検索してください' },
}

export const ADDRESS_SEARCH = {
  maxLength: { value: 50, message: '住所は50文字以内のキーワードで検索してください' },
}

export const TAG_NAME = {
  required: 'タグ名を入力してください',
  maxLength: { value: 40, message: 'タグ名は40文字以内で入力してください' }
}


export const AFFILIATION = {
  required: '組織名を入力してください',
  maxLength: { value: 50, message: '組織名は50文字以内で入力してください' }
}

export const PASSWORD = (email) => ({
  pattern: {
    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*_\-+=`|(){}\[\]:;\"\'<>,.?/]).{8,20}$/i,
    message: 'パスワードは英字、数字、記号を含む8文字以上20文字以下の文字列を指定してください'
  },
  validate: value =>
    value !== email.current.substring(0, email.current.indexOf('@'))
    || "メールアドレスと一致するパスワードは設定できません"
})

export const PASSWORD_REQUIRED = (email) => ({
  required: 'パスワードを入力してください',
  pattern: {
    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*_\-+=`|(){}\[\]:;\"\'<>,.?/]).{8,20}$/i,
    message: 'パスワードは英字、数字、記号を含む8文字以上20文字以下の文字列を指定してください'
  },
  validate: value =>
    value !== email.current.substring(0, email.current.indexOf('@'))
    || "メールアドレスと一致するパスワードは設定できません"
})

export const PASSWORD_REPEAT = (password) => ({
  validate: value =>
    value === password.current || "確認用パスワードが一致しません"
})

export const REGISTER_ID = (routeLog) => ({
  pattern: {
    value:/JU[A-Z0-9]{10}/,
    message: '機体番号の入力形式が不正です',
  },
  maxLength: { value: 12, message: '機体番号は12文字で入力してください' },
  minLength: { value: 12, message: '機体番号は12文字で入力してください' },
  validate: value =>
      // 機体番号入力なし、フライトログ添付ありの場合
      ((value === '' || value === undefined) && routeLog !== '' && routeLog !== undefined) ? 
      'フライトログファイルを添付する場合は機体番号も入力してください' : null
      // (value === '' && routeLog !== undefined) ? 
      // 'リモートログファイルを添付する場合は機体番号も入力してください2' + 'registerId:' + value + ':registerId' + 'routeLog:' + routeLog + ':routeLog'  : null
})
export const FLIGHT_LOG = (registerId) => ({
  pattern: {
    value: /\.txt/,
    message: 'テキストファイル「.txt」を指定してください',
  },
  validate: value =>
    // 機体番号入力あり、フライトログ添付なしの場合
    (registerId !== '' && registerId !== undefined && (value === '' || value === undefined)) ? 
    '機体番号を指定する場合はフライトログファイルも添付してください'  : null
})