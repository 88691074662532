import cliAuth from './axios_cli';
import useSWR, { mutate } from 'swr'
import { URL } from '../const/general'

const fetcher = url => cliAuth.get(url).then(res => res.data)

export const GetMapContents = (url) => {
  const { data, error } = useSWR(url, fetcher)
  return {
    mapContentsData: data,
    isMapContentsLoading: !error && !data,
    isMapContentsError: error
  }
}
export const GetAllContents = () => {
  const { data, error } = useSWR(URL.ALL_CONTENTS_LIST, fetcher)
  return {
    allContents: data,
    isAllContentsLoading: !error && !data,
    isAllContentsError: error
  }
}
export const GetUserContents = () => {
  const { data, error } = useSWR('user_contents/', fetcher)
  return {
    userContents: data,
    isUserContentsLoading: !error && !data,
    isUserContentsError: error
  }
}
export const GetUsers = () => {
  const { data, error } = useSWR(URL.USER, fetcher)
  return {
    usersList: data,
    isUsersLoading: !error && !data,
    isUsersError: error
  }
}
export const GetUser = () => {
    const { data, error } = useSWR(URL.USER_INFO, fetcher)
  return {
    user: data,
    isUserLoading: !error && !data,
    isUserError: error
  }
}

export const GetTags = () => {
  const { data, error } = useSWR(URL.TAGS, fetcher)
  return {
      tags: data,
      isTagsLoading: !error && !data,
      isTagsError: error
  }
}
export const GetActiveTags = () => {
  const { data, error } = useSWR(URL.ACTIVE_TAGS, fetcher)
  return {
      activeTags: data,
      isActiveTagsLoading: !error && !data,
      isActiveTagsError: error
  }
}
export const GetSurveys = () => {
  const { data, error } = useSWR(URL.SURVEY, fetcher)
  return {
    surveyList: data,
    isSurveyLoading: !error && !data,
    isSurveyError: error
  }
}
export const GetMailHistory = () => {
  const { data, error } = useSWR(URL.MAIL_HISTORY, fetcher)
  return {
    mailHistory: data,
    isMailHistoryLoading: !error && !data,
    isMailHistoryError: error
  }
}
export const GetActiveSurveys = () => {
  const { data, error } = useSWR(URL.ACTIVE_SURVEY, fetcher)
  return {
    activeSurvey: data,
    isActiveSurveyLoading: !error && !data,
    isActiveSurveyError: error
  }
}

export const GetDataSWRInterval = (url, interval) => {
  const { data, error } = useSWR(url, fetcher,
    { refreshInterval: interval })
  return {
    resposeData: data,
    isLoading: !error && !data,
    isError: error
  }
}

export const LoadMutate = (url) => {
  mutate(url)
}
