import React from 'react'
import ContentsList from '../../../contents/contents_list'
import Proggress from '../../../items/progress'
import FailToLoad from '../../failed_to_load'
import { URL } from '../../../const/general'
import { GetActiveTags, GetAllContents } from '../../../functions/get_data_swr';

export default function Contents() {

  const { activeTags, isActiveTagsLoading, isActiveTagsError } = GetActiveTags();
  const { allContents, isAllContentsLoading, isAllContentsError} = GetAllContents();
  if (isActiveTagsError) return <FailToLoad />
  if (isActiveTagsLoading) return <Proggress />
  if (isAllContentsError) return <FailToLoad />
  if (isAllContentsLoading) return <Proggress />

  return (
    <ContentsList
      reloadUrl={URL.ALL_CONTENTS_LIST}
      activeTags={activeTags}
      contentList={allContents}
      hasDownload = {true}
    />
  );
}
