import { Checkbox, FormControlLabel, Radio} from "@mui/material";
import styled from '@emotion/styled';

// チェックボックスのラベルの共通スタイル
export const CheckBoxLabel = styled(FormControlLabel)(() => (
    {
        color: '#6C6C6C',
    }));

export const CheckBoxSearch = styled(Checkbox)(() => (
    {
        padding: '2px 9px',
    }));
    export const RadioSearch = styled(Radio)(() => (
        {
            padding: '2px 9px',
        }));
    


