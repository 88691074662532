import Header from '../../contents/login_header'
import LoginBody from '../../contents/login'
import Footer from '../../contents/footer'

function Login() {

  return (
    <>
      <Header />
      <LoginBody />
      <Footer />
    </>
  )
}

export default Login;