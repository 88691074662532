import cliAuth from './axios_cli';

export default async function PatchData(
  data,
  procName,
  url,
  setSuccessMessage,
  setSuccessStatus,
  setOpenSuccessBar,
  setErrorMessage,
  setErrorStatus,
  setOpenErrorBar,
  onSuccess,
  onError
) {
  await cliAuth.patch(url, data)
    .then(response => {
      onSuccess(response, '更新', setSuccessMessage)
      setSuccessStatus('success')
      setOpenSuccessBar(true)
      console.log(url + ' patch success', response)
    })
    .catch(err => {
      if (err.response === undefined) {
        setErrorMessage(`${procName}の更新に失敗しました`)
      } else {
        onError(err, '更新', setErrorMessage)
      }
      setErrorStatus('error')
      setOpenErrorBar(true)
      console.log(err)
    });
};
