import * as React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from '@mui/material/IconButton';
import { ThumbImageListItemBar } from '../../style/image'
import { BG_COLOR } from '../../const/risk_style';

export default function MovieThumbnail(props) {

  return (
    <ThumbImageListItemBar
      title={props.item.label}
      onClick={() => {props.jumpToChapter(props.item.start_time)}}

      actionIcon={
        <IconButton
          sx={{
            color: color(props.item.level),
            backgroundColor: color(props.item.level),
            height: '8px', width: '8px',
            margin: '2px 8px',
            padding: 0,
          }}
          aria-label={`star ${props.item.label}`}
          onClick={() => {props.jumpToChapter(props.item.start_time)}}
            >
          <CircleIcon sx={{ fontSize: '1.0rem' }} />
        </IconButton>
      }
      actionPosition="left"
    />
  );
}

const color = (level) => {
  let color = 'blue'
  // console.log(level)
  switch (level) {
    case 1:
      color = BG_COLOR.LOW
      break
    case 2:
      color = BG_COLOR.MIDDLE
      break
    case 3:
      color = BG_COLOR.HIGHT
      break
    default:
      color = BG_COLOR.LOW
      break
  }
  return color
}

