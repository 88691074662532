import React from 'react'
import { TextField } from "@mui/material";

function Email(props) {
  return (
    <>
      <TextField
        sx={{ display: 'none' }}
        {...props.register_id}
        value={props.id}
      />
      <TextField
        {...props.register_email}
        label="ユーザーメールアドレス"
        value={props.email}
        variant="standard"
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
      />
    </>
  )
}

export default Email;
