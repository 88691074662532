import React, { useState } from 'react'

import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import FailedToLoad from '../../failed_to_load'
import TagInfoForm from '../../../contents/admin_tag_form'
import Proggress from '../../../items/progress'
import MessageBar from '../../../items/snackbar'
import DeleteDialog from '../../../items/delete_dialog'
import TableRowsHeader from '../../../items/table_row_header'
import { GetTags, LoadMutate } from '../../../functions/get_data_swr';
import { URL } from '../../../const/general'


function Tag() {
  const [deleteTagId, setDeleteTagId] = useState('');
  const [deleteTagName, setDeleteTagName] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const reload = () => {
    LoadMutate(URL.TAGS)
  }

  function Row(props) {
    const [open, setOpen] = useState(false);
    return (
      <React.Fragment>
        <TableRowsHeader
        id = {props.row.id}
        name = {props.row.tag_names}
        open={open}
        setOpen ={setOpen}
        setDeleteConfirmOpen={setDeleteConfirmOpen}
        setDeleteId={setDeleteTagId}
        setDeleteName={setDeleteTagName}
        />
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                  <TagInfoForm
                    proc_type='update'
                    id={props.row.id}
                    tagNames={props.row.tag_names}
                    tagStartingDate={props.row.tag_starting_date}
                    tagEndingDate={props.row.tag_ending_date}
                    setOpen={setOpen}
                    setSnackBarMessage={setSnackBarMessage}
                    setSnackBarStatus={setSnackBarStatus}
                    setOpenSnackBar={setOpenSnackBar}
                    reload={reload}
                    submitButtonName="タグ更新" />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const { tags, isTagsLoading, isTagsError } = GetTags();
  if (isTagsError) return <FailedToLoad />
  if (isTagsLoading) return <Proggress />
  console.log(tags);

  return (
    <>
      <TagInfoForm
        proc_type='create'
        id=''
        tagNames=''
        tagStartingDate=''
        tagEndingDate=''
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar}
        reload={reload}
        submitButtonName="タグ登録" />
      <TableContainer >
        <Table>
          <TableBody>
            {tags.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MessageBar
        setOpen={setOpenSnackBar}
        open={openSnackBar}
        status={snackBarStatus}
        message={snackBarMessage}
      />
      <DeleteDialog
        confirmOpen={deleteConfirmOpen}
        url={URL.DELETE_TAG + deleteTagId + '/'}
        targetName={deleteTagName}
        dialogTitle={'タグ'}
        additionalMessage={'関連付けられた動画からも、このタグは削除されます。'}
        setConfirmOpen={setDeleteConfirmOpen}
        setSnackBarMessage={setSnackBarMessage}
        setSnackBarStatus={setSnackBarStatus}
        setOpenSnackBar={setOpenSnackBar}
        reload={reload}
        setTargetFileId={setDeleteTagId}
        setTargetFileTitle={setDeleteTagName}
        isSendForHana={true}
      />
    </>
  )
}

export default Tag;
