import React from 'react'
import FormDatePicker from '../date_picker'
import Common from '../../functions/common';

export default function FormDatePickerStartEnd(props) {
  const onStartingDateChange = (newValue) => {
    console.log(newValue)
    const endValue = props.getValues(props.endingDateName)
    if (newValue === null || newValue === undefined || newValue === '') {
      props.setStartDateError('開始日を入力してください')
      props.setValue(props.startingDateName, null)
      return
    }
    if (Number.isNaN(newValue.getDate())) {
      props.setValue(props.startingDateName, newValue)
      props.setStartDateError('開始日を日付形式で入力してください')
      return
    }
    if (endValue !== null && endValue !== undefined && endValue !== ''
      && !Common.compareDate(new Date(newValue), new Date(endValue))) {
      props.setStartDateError('開始日は終了日よりも前の日付を指定してください')
      props.setValue(props.startingDateName, newValue)
      return
    }else{
      props.setEndDateError('')
    }
    props.setStartDateError('')
    props.setValue(props.startingDateName, newValue)
    return
  }

  const onEndingDateChange = (newValue) => {
    const startValue = props.getValues(props.startingDateName)
    if (newValue === null || newValue === undefined || newValue === '') {
      props.setEndDateError('終了日を入力してください')
      props.setValue(props.endingDateName, null)
      return
    }
    if (Number.isNaN(newValue.getDate())) {
      props.setEndDateError('終了日を日付形式で入力してください')
      props.setValue(props.endingDateName, newValue)
      return
    }
    if (startValue !== null && startValue !== undefined && startValue !== ''
      && !Common.compareDate(new Date(startValue), new Date(newValue))) {
      props.setEndDateError('終了日は開始日よりも後の日付を指定してください')
      props.setValue(props.endingDateName, newValue)
      return
    }else{
      props.setStartDateError('')
    }
    props.setEndDateError('')
    props.setValue(props.endingDateName, newValue)
    return
  }

  return (
    <>
      <FormDatePicker
        name={props.startingDateName}
        control={props.control}
        hintText="開始日"
        defaultValue={props.startingDate}
        onChange={onStartingDateChange}
        dateError={props.startDateError}
      />
      <FormDatePicker
        name={props.endingDateName}
        control={props.control}
        hintText="終了日"
        defaultValue={props.endingDate}
        onChange={onEndingDateChange}
        dateError={props.endDateError}
      />
    </>
  )
}