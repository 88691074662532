import * as React from 'react';
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export default function SelectUsersTable(props) {

  const handleToggle = (value) => () => {
    const currentIndex = props.checkedId.indexOf(value);
    const newChecked = [...props.checkedId];

    if (currentIndex === -1) {
      //選択したIDがなければ追加
      newChecked.push(value);
    } else {
      //選択したIDが既にあれば削除
      newChecked.splice(currentIndex, 1);
    }
    props.setCheckedId(newChecked);
  };

  function Row(props) {

    // 組織名がnullの場合があるので補正
    if (!props.row.affiliation) {
      props.row.affiliation = ''
    }

    return (
      <>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem
            key={props.key}
            disablePadding >
            <ListItemButton role={undefined} onClick={handleToggle(props.row.id)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={props.checkedId.indexOf(props.row.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                // inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                primary={props.row.affiliation}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {props.row.email}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
      </>
    );
  }


  return (
    <>
      {props.usersList.map((row, id) => (
        <Row
          key={id}
          row={row}
          checkedId={props.checkedId}/>
      ))}
    </>
  );
}
