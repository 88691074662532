import React from "react";
import { SmallChip, SmallNoneTagChip } from '../../style/chip';
import { BoxFlexStartWrap } from '../../style/box';

export default function TagList(props) {
    return (
        <BoxFlexStartWrap>
            {props.tags.length > 0 ?
                props.tags.map((tag, i) => {
                    const tag_name = tag.tag_names
                    return (
                        <SmallChip
                            // variant="outlined"
                            key={i}
                            label={tag_name}
                            title={tag_name}
                        />)
                })
                :
                <SmallNoneTagChip
                    label='タグ未指定'
                    title='タグが指定されていません'
                />
            }
        </BoxFlexStartWrap>
    )
}
